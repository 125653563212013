import React, { useState, useEffect } from "react";
import axios from "axios";
import FinancialDetails from "./FinancialDetails";
import SalesAdviceFormReview from "./SalesAdviceFormReview";
import { Collapse, Modal, Select } from "antd";
import Button from "../Button";
import DocumentContainer from "./DocumentContainer";
import * as Util from "../../functions/common/util"
import { isLoginUserAdmin } from "../../functions/common/manage-login-user";
import { printSalesAdvice } from "../../functions/common/sales-advice";


export default function WorkflowCompleted({ workflow }) {

    const isAdmin = isLoginUserAdmin();
    const [idFilesUploaded, setIdFilesUploaded] = useState([]);
    const [depositReceiptUploaded, setDepositReceiptUploaded] = useState([]);
    const [rebateAgreementUploaded, setRebateAgreementUploaded] = useState([]);

    useEffect(() => {
        const fetchWorkflowDocs = (id) => {
            if (!id) {
                return;
            }

            axios.get(`${process.env.REACT_APP_API_URL}/api/workflow/otp/fetch-docs`, {
                params: {
                    workflowId: id,
                }, // Pass the search data as query parameters
            }).then(response => {
                if (response.status === 200) {
                    const files = response.data;
                    files.forEach((file) => {
                        if (file.fileType === "id") {
                            setIdFilesUploaded(prevFiles => ([
                                ...prevFiles,
                                {
                                    name: file.filename,
                                    s3Key: file.s3Key
                                }
                            ]))
                        } else if (file.fileType === "deposit receipt") {
                            setDepositReceiptUploaded(prevFiles => ([
                                ...prevFiles,
                                {
                                    name: file.filename,
                                    s3Key: file.s3Key
                                }
                            ]))
                        } else if (file.fileType === "rebate agreement") {
                            setRebateAgreementUploaded(prevFiles => ([
                                ...prevFiles,
                                {
                                    name: file.filename,
                                    s3Key: file.s3Key
                                }
                            ]))
                        }
                    })
                }
            }).catch(error => {
                console.log(error)
            });
        }

        fetchWorkflowDocs(workflow.id);

    }, [workflow])


    const [generating, setGenerating] = useState(false);
    const selectCompany = () => {
        if (isAdmin) {
            //only admin can choose company
            showCompanyModal();
        } else {
            handlePrint();
        }
    }

    const [isCompanyModalOpen, setIsCompanyModalOpen] = useState(false);
    const showCompanyModal = () => {
        setIsCompanyModalOpen(true);
    };

    const handleOk = () => {
        setIsCompanyModalOpen(false);
    };

    const handleCancel = () => {
        setIsCompanyModalOpen(false);
    };


    const [companySelected, setCompanySelected] = useState("pw");

    const handlePrint = async () => {
        setGenerating(true);
        await printSalesAdvice(workflow.id, companySelected);
        setGenerating(false);
    }

    return <>
        <Modal title="Choose company"
            open={isCompanyModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={() => (
                <>
                    <Button onClick={handlePrint} buttonText={"Confirm"} disabled={generating} />
                </>
            )}>
            <Select
                value={companySelected}
                className="w-full mb-5 mt-5"
                onChange={(value) => { setCompanySelected(value) }}
                options={[
                    { value: 'pw', label: 'PW Star Realty Pty Ltd' },
                    { value: 'jarrah', label: 'Jarrah Capital Australia Pty Ltd' },
                ]}
            />
        </Modal>

        <div className="static card w-fill bg-base-100 shadow-md">
            <div className="card-body">

                <Collapse items={[{
                    key: '1',
                    label: 'Sales Advice',
                    children:
                        <div>
                            <div className="flex justify-between mb-4">
                                <button className="link-pw" onClick={selectCompany}>
                                    Print Sales Advice
                                </button>
                            </div>

                            <SalesAdviceFormReview workflow={workflow} />
                        </div>,
                }]} />



                <Collapse
                    // defaultActiveKey={['1']}
                    items={[{
                        key: '1',
                        label: 'Financial Details',
                        children: <FinancialDetails workflow={workflow} />,
                    }]}
                />

                <Collapse
                    // defaultActiveKey={['1']}
                    items={[{
                        key: '1',
                        label: 'Documents',
                        children: <>
                            <DocumentContainer title={"Identity (Passport or Driver License)"} files={idFilesUploaded} />
                            <div className="m-5"></div>
                            <DocumentContainer title={"Deposit Receipt"} files={depositReceiptUploaded} />
                            <div className="m-5"></div>
                            <DocumentContainer title={"Rebate Agreement"} files={rebateAgreementUploaded} />
                        </>,
                    }]}
                />



                <div className="mt-5">
                    <p className="text-gray-500">Approved By: {workflow.approvedByUserName}</p>
                    <p className="text-gray-500">Exchange Date: {Util.formatDateValue(workflow.exchangeDate)}</p>
                    <p className="text-gray-500">Estimate Settlement Date: {Util.formatDateValue(workflow.estSettlementDate)}</p>
                    <p className="text-gray-500">Commission calculated based on: {Util.formatDateValue(workflow.salesAdviceDate)}</p>
                </div>

            </div>
        </div>
    </>
}