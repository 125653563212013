import React from "react";

export default function Footer() {
    return (
        <footer className="items-center p-4 bg-neutral text-neutral-content">
            <div className="flex justify-between ">
                <div className="text-xs">
                    PW Realty - All rights reserved
                </div>
                <div className="text-xs">
                    <a href="https://jcwebhub.com.au" target="_blank" rel="noopener noreferrer">Powered by JC WEB HUB</a>
                </div>
            </div>
        </footer>
        
    );
}