import React, { useEffect, useState } from "react";
import SalesAdviceFormReview from "../components/workflow/SalesAdviceFormReview";
import { useParams } from "react-router-dom";
import axios from "axios";

export default function PrintSalesAdvice() {

    const { workflowId, companyName } = useParams();

    const [workflow, setWorkflow] = useState({});

    // const [logoLoaded, setLogoLoaded] = useState(false);

    useEffect(() => {
        document.title = "Sales Advice";
        document.body.style.background = "white"; // Set the background color to white with !important

        const fetchWorkflowDetail = (id) => {

            if (!id) {
                return;
            }

            axios.get(`${process.env.REACT_APP_API_URL}/api/workflow/otp/fetch/${id}`)
                .then(response => {
                    if (response.status === 200) {
                        console.log(response.data)
                        setWorkflow(response.data);
                    }
                }).catch(error => {
                    console.log(error)
                });
        }

        fetchWorkflowDetail(workflowId);

    }, [workflowId])

    // useEffect(() => {
    //     if (workflow?.id > 0 && logoLoaded) {
    //         window.print();
    //     }
    // }, [workflow, logoLoaded])



    const headerStyle = {
        position: "abosolute",
        top: 0,
        left: 0,
        right: 0,
        paddingBottom: "10px",
        backgroundColor: "#fff",
        textAlign: "center",
    };

    //   const footerStyle = {
    //     position: "fixed",
    //     bottom: 0,
    //     left: 0,
    //     right: 0,
    //     backgroundColor: "#fff",
    //     padding: "10px",
    //     textAlign: "center",
    //     borderTop: "1px solid #ccc",
    //   };

    return (
        <>
            {workflow?.id > 0 && (
                <div>
                    <style>
                        {`
                        @page {
                            size: A4; /* Set the page size, e.g., A4, letter, etc. */
                        }
                        `}
                    </style>

                    <div id="header" style={headerStyle}>
                        <div className="flex justify-between items-center">
                            {companyName === "pw" && (
                                <>
                                <img alt="" src="/images/logo-dark.png" style={{ height: "60px" }} />
                                <img alt="" src="/images/pw-logo-text.png" style={{ height: "30px" }} />
                                </>
                            )}
                            {companyName === "jarrah" && (
                                <>
                                <img alt="" src="/images/jarrah-logo.png" style={{ height: "60px" }} />
                                </>
                            )}
                        </div>
                    </div>
                    <div className="main-container bg-white" style={{ marginTop: "30px", marginBottom: "30px" }}>
                        <div className="text-2xl text-center font-medium" style={{ marginBottom: "30px" }}>Sales Advice</div>
                        <SalesAdviceFormReview workflow={workflow} company={companyName} />

                    </div>
                </div>
            )}
        </>


    )
}