import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import Header from "../components/Header";
import Footer from "../components/Footer";
import Button from "../components/Button";
import { ConfigProvider, Timeline, Space } from 'antd';
import Toast from "../components/Toast";
import { getPresignedURL } from "../functions/common/file-upload"
import { validateForm } from "../functions/form/form-validation"
import { isLoginUserAdmin } from "../functions/common/manage-login-user";
import { formatPrice } from "../functions/common/util";


export default function CreatePortfolio( {property} ) {    

    useEffect(() => {
        document.title = "Create Portfolio"

    }, []);

    const navigate = useNavigate();
    useEffect(() => {
        const hasAccess = isLoginUserAdmin();
        if (!hasAccess) {
            navigate("/login")
            return;
        }

    }, [navigate]);

    const [timelineItems, setTimelineItems] = useState([])

    const [newCommissionSettings, setNewCommissionSettings] = useState([])

    const [deletedCommSettingIds, setDeletedCommSettingIds] = useState([])

    const deleteCommissionSetting = (settingId) => {

        setTimelineItems(prevItems => {
            const updatedItems = prevItems.filter(item => item.id !== settingId);
            console.log(updatedItems)
            return updatedItems; // Return the updated array to setTimelineItems
        });

        if(!(settingId+"").startsWith("new")) {
            setDeletedCommSettingIds(prevIds => [
                settingId,
                ...prevIds
            ])  
        }

        setNewCommissionSettings(prevItems => {
            const updatedItems = prevItems.filter(item => item.settingId !== settingId);
            return updatedItems; // Return the updated array to setTimelineItems
        })
    }

    useEffect(() => {
        // checkLogin();     
        document.title = "Create Portfolio"

        if ( property && property.commissionSettings ) {
            let commissionItems = [];
            property.commissionSettings.forEach((setting) => {
                commissionItems.push({
                    children:
                        <p className="flex flex-wrap">
                            <span className="mx-2 flex-shrink-0"><b>Start Date</b> {setting.commissionStartDate}</span>
                            <span className="mx-2 flex-shrink-0"><b>Rate</b> {setting.commissionRate}%</span>
                            <span className="mx-2 flex-shrink-0"><b>Amount</b> ${formatPrice(setting.commissionCash)}</span>
                            <span className="mx-2 flex-shrink-0"><b>Agent Bonus</b> ${formatPrice(setting.bonus)}</span>
                            <span className="mx-2 flex-shrink-0"><b>Cap Rate</b> {setting.commissionCapRate}%</span>
                            <span className="mx-2 flex-shrink-0"><b>Cap Amount</b> ${formatPrice(setting.commissionCapAmount)}</span>
                            <span className="mx-2 flex-shrink-0"><button type="button" className="link-pw" onClick={() => deleteCommissionSetting(setting.id)}>[Delete]</button></span>
                        </p>,
                    id: setting.id,
                })
            })
            // console.log(commissionItems)
            setTimelineItems(commissionItems)
        }

    }, [property]);

    const [formData, setFormData] = useState(
        {
            id: property && property.id ? property.id : 0,
            title: property && property.title ? property.title : "",
            keySellingPoint: property && property.keySellingPoint ? property.keySellingPoint : "",
            promotion: property && property.promotion ? property.promotion : "",
            displayInfo: property && property.displayInfo ? property.displayInfo : "",
            displayPrice: property && property.displayPrice ? property.displayPrice : "",
            propertyType: property && property.propertyType ? property.propertyType : "",
            completionDate: property && property.completionDate ? property.completionDate : "",
            constructionStartDate: property && property.constructionStartDate ? property.constructionStartDate : "",
            developer: property && property.developer ? property.developer : "",
            builder: property && property.builder ? property.builder : "",
            priority: property && property.priority ? property.priority : "",
            projectInfoLink: property && property.projectInfoLink ? property.projectInfoLink : "",
            brochureLink: property && property.brochureLink ? property.brochureLink : "",
            floorPlanLink: property && property.floorPlanLink ? property.floorPlanLink : "",
            siteMapLink: property && property.siteMapLink ? property.siteMapLink : "",
            priceListLink: property && property.priceListLink ? property.priceListLink : "",
            salesAdviceLink: property && property.salesAdviceLink ? property.salesAdviceLink : "",
            address: property && property.address ? property.address : "",
            suburb: property && property.suburb ? property.suburb : "",
            state: property && property.state ? property.state : "",
            postcode: property && property.postcode ? property.postcode : "",
            minSearchPrice: property && property.minSearchPrice ? property.minSearchPrice : "",
            maxSearchPrice: property && property.maxSearchPrice ? property.maxSearchPrice : "",
            paymentStage1: property && property.paymentStage1>=0 ? property.paymentStage1 : "",
            paymentStage2: property && property.paymentStage2>=0 ? property.paymentStage2 : "",
            paymentStage3: property && property.paymentStage3>=0 ? property.paymentStage3 : "",
            paymentStage4: property && property.paymentStage4>=0 ? property.paymentStage4 : "",
            paymentStage5: property && property.paymentStage5>=0 ? property.paymentStage5 : "",
            coverImage: property && property.coverImage ? property.coverImage : "",
        }
    )

    const handleSaveNewCommissionSetting = (e) => {
        const commissionStartDate = document.getElementById("commissionStartDate").value;
        const commissionRate = document.getElementById("commissionRate").value;
        const commissionCash = document.getElementById("commissionCash").value;
        const bonus = document.getElementById("bonus").value;
        const commissionCapRate = document.getElementById("commissionCapRate").value;
        const commissionCapAmount = document.getElementById("commissionCapAmount").value;

        if (commissionStartDate === ""
            || commissionRate === ""
            || commissionCash === ""
            || bonus === ""
            || commissionCapRate === ""
            || commissionCapAmount === "") {
            showToast("error", "Please enter all fields for this commission setting.")
            return;
        }

        const d = new Date();
        let ms = d.getMilliseconds();
        const newComm = {
            settingId: `new-${ms}`,
            commissionStartDate: commissionStartDate,
            commissionRate: commissionRate,
            commissionCash: commissionCash,
            bonus: bonus,
            commissionCapRate: commissionCapRate,
            commissionCapAmount: commissionCapAmount,
        }

        setNewCommissionSettings(
            [
                ...newCommissionSettings,
                newComm
            ]
        )

        setTimelineItems([
            ...timelineItems,
            {
                children:
                    <p className="flex flex-wrap">
                        <span className="mx-2 flex-shrink-0"><b>Start Date</b> {commissionStartDate}</span>
                        <span className="mx-2 flex-shrink-0"><b>Rate</b> {commissionRate}%</span>
                        <span className="mx-2 flex-shrink-0"><b>Amount</b> ${formatPrice(commissionCash)}</span>
                        <span className="mx-2 flex-shrink-0"><b>Agent Bonus</b> ${formatPrice(bonus)}</span>
                        <span className="mx-2 flex-shrink-0"><b>Cap Rate</b> {commissionCapRate}%</span>
                        <span className="mx-2 flex-shrink-0"><b>Cap Amount</b> ${formatPrice(commissionCapAmount)}</span>
                        <span className="mx-2 flex-shrink-0"><button type="button" className="link-pw" onClick={() => deleteCommissionSetting("new-"+ms)}>[Delete]</button></span>
                    </p>,
                id: newComm.settingId
            }
        ])
        setAddNewSetting(false);
    }

    const handleCancelNewCommissionSetting = (e) => {
        setAddNewSetting(false);
    }

    const handleFormDataChange = (e) => {
        const { id, value } = e.target;
        setFormData({
            ...formData,
            [id]: value,
        });
    }


    const [isSubmitting, setIsSubmitting] = useState(false);
    const handleSubmit = async () => {

        const data = {
            ...formData,
            commissionSettings: newCommissionSettings,
            deletedCommSettingIds: deletedCommSettingIds
        }

        // console.log(data)

        const valid = validateForm("create-property-form");

        // const valid = true;

        if (!valid) {
            showToast("error", "Please fill in all mandatory fields.");
            return;
        }

        if (formData["coverImage"] === "") {
            showToast("error", "Please upload a cover image.");
            return;
        }

        const paymentStageSum = calculateTotalPaymentStage();
        if (paymentStageSum !== 100) {
            showToast("error", "Payment stages add up must equal to 100%. Your inputs total is " + paymentStageSum + "%.");
            return;
        }


        setIsSubmitting(true);

        //remove comma from the price fileds
        // console.log(formData.displayPrice)
        data.displayPrice = (formData.displayPrice+"").replace(/[^0-9.]/g, "");
        data.minSearchPrice = (formData.minSearchPrice+"").replace(/[^0-9.]/g, "");
        data.maxSearchPrice = (formData.maxSearchPrice+"").replace(/[^0-9.]/g, "");

        await axios.post(`${process.env.REACT_APP_API_URL}/api/property/otp/create`, data, {
            headers: {
                'Content-Type': 'application/json', // Specify JSON content type
            },
        }).then(response => {
            if (response.status === 200) {
                navigate("/portfolio/otp/detail/" + response.data);
            }
        }).catch(error => {
            showToast("error", "Error.");
        });

        setIsSubmitting(false);
    }


    const calculateTotalPaymentStage = () => {
        const {
            paymentStage1,
            paymentStage2,
            paymentStage3,
            paymentStage4,
            paymentStage5,
        } = formData;

        // Convert the payment stages to numbers and calculate the sum
        const totalPayment =
            parseFloat(paymentStage1) +
            parseFloat(paymentStage2) +
            parseFloat(paymentStage3) +
            parseFloat(paymentStage4) +
            parseFloat(paymentStage5);

        return totalPayment
    };


    const [addNewSetting, setAddNewSetting] = useState(false);
    const handleAddNewCommissionSetting = () => {
        setAddNewSetting(true);
    }

    const [isToastVisible, setIsToastVisible] = useState(false);
    const [toastType, setToastType] = useState("normal");
    const [toastMessage, setToastMessage] = useState("");

    const showToast = (type, message) => {
        setIsToastVisible(true);
        setToastType(type);
        setToastMessage(message);

        // Optionally, you can set a timeout to hide the toast after a certain duration
        setTimeout(() => {
            setIsToastVisible(false);
        }, 2000); // Hide the toast after 3 seconds (adjust as needed)
    };

    const handleCoverImageUpload = (e) => {
        const coverImageOrignalFile = e.target.files[0];

        //rename the file to a unique name with timestamp
        const timestamp = Date.now();
        const newFilename = `CoverImage_${timestamp}_${coverImageOrignalFile.name}`;

        if (coverImageOrignalFile) {
            coverImageOrignalFile.s3Key = `property/original/${newFilename}`;

            //handle concurrent state changes, use functional update
            setFormData((prevData) => ({
                ...prevData,
                coverImage: newFilename
            }))

            uploadSingleFile(coverImageOrignalFile);

        }

        // Usage example:
        createThumbnail(coverImageOrignalFile)
            .then((thumbnailFile) => {

                thumbnailFile.s3Key = `property/thumbnail/${newFilename}`;

                uploadSingleFile(thumbnailFile);

            })
            .catch((error) => {
                console.error("Error creating thumbnail:", error);
            });
    }

    const createThumbnail = (originalFile) => {
        return new Promise((resolve, reject) => {
            const originalImage = new Image();

            originalImage.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                const thumbnailWidth = 400; // Set the desired width for the thumbnail
                const thumbnailHeight = (originalImage.height / originalImage.width) * thumbnailWidth; // Maintain aspect ratio

                canvas.width = thumbnailWidth;
                canvas.height = thumbnailHeight;

                // Draw the original image on the canvas (resized)
                ctx.drawImage(originalImage, 0, 0, thumbnailWidth, thumbnailHeight);

                // Convert the canvas to a Blob
                canvas.toBlob((blob) => {
                    if (!blob) {
                        reject(new Error("Failed to create thumbnail Blob"));
                        return;
                    }

                    // Create a File from the Blob
                    const thumbnailFile = new File([blob], 'thumbnail.jpg', { type: 'image/jpeg' }); // Change the filename and 'type' as needed

                    // Resolve the Promise with the thumbnail file
                    resolve(thumbnailFile);
                }, 'image/jpeg');
            };

            originalImage.onerror = (error) => {
                reject(error);
            };

            originalImage.src = URL.createObjectURL(originalFile);
        });
    };



    const uploadSingleFile = async (file) => {
        const presignedUrl = await getPresignedURL(file, false, false);

        const xhr = new XMLHttpRequest();
        const success = await new Promise((resolve) => {
            xhr.upload.addEventListener("progress", (event) => {
                if (event.lengthComputable) {
                    // $("#uploading-file-name").html(file.name);
                    // $("#upload-progress").html(((event.loaded / event.total) * 100).toFixed(0) + "%");
                    console.log("Uploading " + file.name + ", progress: " + ((event.loaded / event.total) * 100).toFixed(0) + "%")
                }
            });
            xhr.addEventListener("progress", (event) => {
                if (event.lengthComputable) {
                    // console.log("download progress:", event.loaded / event.total);
                    // $("#download-progress").html(((event.loaded / event.total) * 100).toFixed(0) + "%");
                }
            });
            xhr.addEventListener("loadend", () => {
                resolve(xhr.readyState === 4 && xhr.status === 200);
            });
            xhr.open("PUT", presignedUrl, true);
            // xhr.setRequestHeader("Content-Type", "multipart/form-data");
            xhr.setRequestHeader("Content-Type", file.type);
            // xhr.setRequestHeader("Content-Disposition", "inline; filename=\""+file.name+"\"");
            xhr.send(file);
        });

        return success;
    }    

    return (
        <div>
            <Toast isVisible={isToastVisible} type={toastType} message={toastMessage} />

            <Header currentPage="portfolio" />

            <div className="main-container">

                <div className="mx-auto px-10 mt-10">

                    <div className={`static card w-fill bg-base-100 shadow-md mb-10`}>
                        <div className="card-body">
                            <form id="create-property-form">
                                <div className="flex justify-between ">
                                    <div className="text-pw text-lg font-semibold">
                                        <h3>Property Info</h3>
                                    </div>
                                </div>
                                <hr className="mb-4" />

                                <div className="grid gap-4 grid-cols-1">
                                    <div className="mb-4">
                                        <label className="font-medium ltr:text-left" htmlFor="title">Title <span className="text-error">*</span></label>
                                        <input type="text" id="title" className="py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500 mandatory" onChange={handleFormDataChange} value={formData.title} />
                                    </div>
                                </div>
                                <div className="grid gap-4 grid-cols-1">
                                    <label className="font-medium ltr:text-left" htmlFor="address">Address <span className="text-error">*</span></label>
                                </div>
                                <div className="grid gap-4 grid-cols-1 md:grid-cols-5">
                                    <div className="md:col-span-2 mb-4">
                                        <input id="address" type="text" className={`py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500 mandatory`} autoComplete="on" onChange={handleFormDataChange} value={formData.address} />
                                        <p className="text-sm text-gray-400">Street</p>
                                    </div>
                                    <div className="mb-4">
                                        <input id="suburb" name="suburb" label="suburb" type="text" autoComplete="on" className={`py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500 mandatory`} onChange={handleFormDataChange} value={formData.suburb} />
                                        <p className="text-sm text-gray-400">Suburb</p>
                                    </div>
                                    <div className="mb-4">
                                        <select id="state" className={`py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500 mandatory`} autoComplete="on" onChange={handleFormDataChange} value={formData.state} >
                                            <option value="" disabled>-- Select -- </option>
                                            <option value={"NSW"}>NSW</option>
                                            <option value={"VIC"}>VIC</option>
                                            <option value={"QLD"}>QLD</option>
                                            <option value={"ACT"}>ACT</option>
                                            <option value={"WA"}>WA</option>
                                            <option value={"SA"}>SA</option>
                                            <option value={"NT"}>NT</option>
                                            <option value={"TAS"}>TAS</option>
                                        </select>
                                        <p className="text-sm text-gray-400">State</p>
                                    </div>
                                    <div className="mb-4">
                                        <input id="postcode" type="text" className={`py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500 mandatory`} autoComplete="on" onChange={handleFormDataChange} value={formData.postcode}/>
                                        <p className="text-sm text-gray-400">Postcode</p>
                                    </div>
                                </div>
                                <div className="grid gap-4 grid-cols-1">
                                    <div className="mb-4">
                                        <label className="font-medium ltr:text-left" htmlFor="keySellingPoint">Key Selling Point</label>
                                        <textarea id="keySellingPoint" className={`py-3 px-4 block w-full border border-solid border-gray-300 rounded-md text-sm focus:border-orange-500`} onChange={handleFormDataChange} value={formData.keySellingPoint}></textarea>
                                    </div>
                                    <div className="mb-4">
                                        <label className="font-medium ltr:text-left" htmlFor="promotion">Promotion</label>
                                        <textarea id="promotion" className={`py-3 px-4 block w-full border border-solid border-gray-300 rounded-md text-sm focus:border-orange-500`} onChange={handleFormDataChange} value={formData.promotion}></textarea>
                                    </div>
                                    <div className="mb-4">
                                        <label className="font-medium ltr:text-left" htmlFor="displayInfo">Display Info</label>
                                        <textarea id="displayInfo" className={`py-3 px-4 block w-full border border-solid border-gray-300 rounded-md text-sm focus:border-orange-500`} onChange={handleFormDataChange} value={formData.displayInfo}></textarea>
                                    </div>
                                </div>

                                <div className="grid gap-4 grid-cols-1 md:grid-cols-4">
                                    <div className="mb-4">
                                        <label className="font-medium ltr:text-left" htmlFor="displayPrice">Display Price <span className="text-error">*</span></label>
                                        <input type="text" id="displayPrice" className="py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500 mandatory" onChange={handleFormDataChange} value={formatPrice(formData.displayPrice)} />
                                    </div>

                                    <div className="mb-4">
                                        <label className="font-medium ltr:text-left" htmlFor="propertyType">Property Type <span className="text-error">*</span></label>
                                        <select id="propertyType" onChange={handleFormDataChange} className='py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500 mandatory' value={formData.propertyType}>
                                            <option value="">-- Select --</option>
                                            <option value="House">House</option>
                                            <option value="Apartment">Apartment</option>
                                            <option value="Townhouse">Townhouse</option>
                                            <option value="Land">Land</option>
                                            <option value="Rural">Rural</option>
                                            <option value="Commercial">Commercial</option>
                                            <option value="Duplex">Duplex</option>
                                        </select>
                                    </div>
                                </div>



                                <div className="grid gap-4 grid-cols-1">
                                    <div>
                                        <label className="font-medium ltr:text-left">Priority (The higher the number, the higher the project ranks)</label>
                                    </div>
                                </div>
                                <div className="grid gap-4 grid-cols-1 md:grid-cols-5">
                                    <div className="mb-4">
                                        <input type="number" id="priority" className="py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500" placeholder="0" onChange={handleFormDataChange} value={formData.priority} />
                                    </div>
                                </div>

                                <div className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
                                    <div className="mb-4">
                                        <label className="font-medium ltr:text-left">Cover Image <span className="text-error">*</span></label>
                                        <label htmlFor="file-input" className="sr-only">Choose file</label>
                                        <input type="file" name="file-input" id="file-input" className="block w-full border border-gray-200 shadow-sm rounded-md text-sm focus:z-10 focus:border-orange-500 focus:ring-orange-500
                                        file:bg-transparent file:border-0
                                        file:bg-gray-100 file:mr-4
                                        file:py-3 file:px-4
                                        "
                                            onChange={handleCoverImageUpload} />
                                    </div>
                                </div>

                                <div className="flex justify-between mt-5 ">
                                    <div className="text-pw text-lg font-semibold">
                                        <h3>Development Info</h3>
                                    </div>
                                </div>
                                <hr className="mb-4" />
                                <div className="grid gap-4 grid-cols-1 md:grid-cols-4">
                                    <div className="mb-4">
                                        <label className="font-medium ltr:text-left" htmlFor="completionDate">Completion Date <span className="text-error">*</span></label>
                                        <input type="date" id="completionDate" className="py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500 mandatory" onChange={handleFormDataChange} value={formData.completionDate} />
                                    </div>

                                    <div className="mb-4">
                                        <label className="font-medium ltr:text-left" htmlFor="constructionStartDate">Construction Start From</label>
                                        <input type="date" id="constructionStartDate" className="py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500" onChange={handleFormDataChange} value={formData.constructionStartDate} />
                                    </div>
                                </div>

                                <div className="grid gap-4 grid-cols-1 md:grid-cols-2">
                                    <div className="mb-4">
                                        <label className="font-medium ltr:text-left" htmlFor="developer">Developer</label>
                                        <input type="text" id="developer" className="py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500" onChange={handleFormDataChange} value={formData.developer}/>
                                    </div>

                                    <div className="mb-4">
                                        <label className="font-medium ltr:text-left" htmlFor="builder">Builder</label>
                                        <input type="text" id="builder" className="py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500" onChange={handleFormDataChange} value={formData.builder}/>
                                    </div>
                                </div>

                                <div className="flex justify-between mt-5">
                                    <div className="text-pw text-lg font-semibold">
                                        <h3>Resources</h3>
                                    </div>
                                </div>
                                <hr className="mb-4" />

                                <div className="grid gap-4 grid-cols-1">
                                    <div className="mb-4">
                                        <label className="font-medium ltr:text-left" htmlFor="projectInfoLink">Project Info</label>
                                        <input type="text" id="projectInfoLink" className="py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500" placeholder="OneDrive Link" onChange={handleFormDataChange} value={formData.projectInfoLink}/>
                                    </div>

                                    <div className="mb-4">
                                        <label className="font-medium ltr:text-left" htmlFor="brochureLink">Brochure</label>
                                        <input type="text" id="brochureLink" className="py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500" placeholder="OneDrive Link" onChange={handleFormDataChange} value={formData.brochureLink}/>
                                    </div>

                                    <div className="mb-4">
                                        <label className="font-medium ltr:text-left" htmlFor="floorPlanLink">Floor Plans / Lots</label>
                                        <input type="text" id="floorPlanLink" className="py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500" placeholder="OneDrive Link" onChange={handleFormDataChange} value={formData.floorPlanLink}/>
                                    </div>

                                    <div className="mb-4">
                                        <label className="font-medium ltr:text-left" htmlFor="siteMapLink">Floor Plates / Site Map</label>
                                        <input type="text" id="siteMapLink" className="py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500" placeholder="OneDrive Link" onChange={handleFormDataChange} value={formData.siteMapLink} />
                                    </div>

                                    <div className="mb-4">
                                        <label className="font-medium ltr:text-left" htmlFor="priceListLink">Price List</label>
                                        <input type="text" id="priceListLink" className="py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500" placeholder="OneDrive Link" onChange={handleFormDataChange} value={formData.priceListLink}/>
                                    </div>

                                    <div className="mb-4">
                                        <label className="font-medium ltr:text-left" htmlFor="salesAdviceLink">Sales Advice</label>
                                        <input type="text" id="salesAdviceLink" className="py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500" placeholder="OneDrive Link" onChange={handleFormDataChange} value={formData.salesAdviceLink}/>
                                    </div>
                                </div>


                                <div className="flex justify-between mt-5">
                                    <div className="text-pw text-lg font-semibold">
                                        <h3>Search Criteria</h3>
                                    </div>
                                </div>
                                <hr className="mb-4" />
                                <div className="grid gap-4 grid-cols-1 md:grid-cols-4">
                                    <div className="mb-4">
                                        <label className="font-medium ltr:text-left" htmlFor="minSearchPrice">Min. Price $ <span className="text-error">*</span></label>
                                        <input type="text" id="minSearchPrice" className="py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500 mandatory" placeholder="" onChange={handleFormDataChange} value={formatPrice(formData.minSearchPrice)} />
                                    </div>
                                    <div className="mb-4">
                                        <label className="font-medium ltr:text-left" htmlFor="maxSearchPrice">Max. Price $ <span className="text-error">*</span></label>
                                        <input type="text" id="maxSearchPrice" className="py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500 mandatory" placeholder="" onChange={handleFormDataChange} value={formatPrice(formData.maxSearchPrice)} />
                                    </div>
                                </div>

                                <div className="flex justify-between mt-5">
                                    <div className="text-pw text-lg font-semibold">
                                        <h3>Commission (Incl. GST)</h3>
                                    </div>
                                </div>
                                <hr className="mb-4" />
                                <div className="grid gap-4 grid-cols-1">
                                    <div>
                                        <label className="font-medium ltr:text-left">Payment Schedule % <span className="text-error">*</span></label>
                                    </div>
                                </div>
                                <div className="grid gap-4 grid-cols-1 md:grid-cols-5">
                                    <div className="mb-4">
                                        <input type="number" id="paymentStage1" className="py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500 mandatory" placeholder="Stage 1" onChange={handleFormDataChange}  value={formData.paymentStage1}/>
                                    </div>
                                    <div className="mb-4">
                                        <input type="number" id="paymentStage2" className="py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500 mandatory" placeholder="Stage 2" onChange={handleFormDataChange}  value={formData.paymentStage2}/>
                                    </div>
                                    <div className="mb-4">
                                        <input type="number" id="paymentStage3" className="py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500 mandatory" placeholder="Stage 3" onChange={handleFormDataChange}  value={formData.paymentStage3}/>
                                    </div>
                                    <div className="mb-4">
                                        <input type="number" id="paymentStage4" className="py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500 mandatory" placeholder="Stage 4" onChange={handleFormDataChange}  value={formData.paymentStage4}/>
                                    </div>
                                    <div className="mb-4">
                                        <input type="number" id="paymentStage5" className="py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500 mandatory" placeholder="Stage 5" onChange={handleFormDataChange}  value={formData.paymentStage5}/>
                                    </div>
                                </div>
                                <div className="grid gap-4 grid-cols-1">
                                    <ConfigProvider
                                        theme={{
                                            components: {
                                                Timeline: {
                                                    colorPrimary: '#f15b22',
                                                }
                                            },
                                        }}
                                    >
                                        <Timeline
                                            items={timelineItems}
                                        >
                                        </Timeline>
                                    </ConfigProvider>

                                    {addNewSetting && (
                                        <div className="flex flex-wrap w-50" style={{ marginTop: "-20px", zIndex: 2 }}>
                                            <Space size={[20, 10]} wrap>
                                            <div>
                                                <label className="font-medium">Start Date</label>
                                                <input id="commissionStartDate" type="date" className={`mx-2 text-sm input-sm py-1 px-2 border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500`} />
                                            </div>
                                            <div>
                                                <label className="font-medium">Rate (%)</label>
                                                <input id="commissionRate" type="number" className={`w-20 mx-2 text-sm py-1 px-2 border border-solid border-gray-200 rounded-md focus:border-orange-500 focus-visible:border-orange-500`} />
                                            </div>
                                            <div>
                                                <label className="font-medium">Amount ($)</label>
                                                <input id="commissionCash" type="number" className={`w-20 mx-2 py-1 px-2 border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500`} />
                                            </div>
                                            <div>
                                                <label className="font-medium">Agent Bonus ($)</label>
                                                <input id="bonus" type="number" className={`w-20 mx-2 py-1 px-2 border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500`} />
                                            </div>

                                            <div>
                                                <label className="font-medium">Cap Rate (%)</label>
                                                <input id="commissionCapRate" type="number" className={`w-20 mx-2 py-1 px-2 border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500`} />
                                            </div>

                                            <div>
                                                <label className="font-medium">Cap Amount ($)</label>
                                                <input id="commissionCapAmount" type="number" className={`w-20 mx-2 py-1 px-2 border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500`} />
                                            </div>

                                            <div>
                                                <button type="button" className="py-1 px-4 mx-2 text-sm inline-flex justify-center items-center gap-2 rounded-md bg-green-100 border border-transparent font-semibold text-green-500 hover:text-white hover:bg-green-500 focus:outline-none " onClick={handleSaveNewCommissionSetting}>
                                                    < i className="ri-check-line"></i>Save
                                                </button>
                                                <button type="button" className="py-1 px-4 inline-flex justify-center items-center gap-2 rounded-md border-2 border-gray-200 font-semibold text-gray-500 hover:text-white hover:bg-gray-500 hover:border-gray-500 transition-all text-sm focus:outline-none" onClick={handleCancelNewCommissionSetting}>
                                                    < i className="ri-close-line"></i>Cancel
                                                </button>
                                            </div>
                                            </Space>
                                            
                                        </div>
                                    )}

                                    {
                                        !addNewSetting && (
                                            <div className="flex flex-wrap w-50" style={{ marginTop: "-20px", zIndex: 2 }} >
                                                <div>

                                                    <button type="button" className="text-sm py-2 px-4 inline-flex justify-center items-center gap-2 rounded-md border-2 border-orange-500 font-semibold text-orange-500 hover:text-white hover:bg-pw focus:outline-none" onClick={handleAddNewCommissionSetting}>
                                                        < i className="ri-add-line"></i>Add Commission Setting
                                                    </button>
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            </form>


                        </div>
                    </div>

                    <div className="mt-5">
                        <Button buttonText="Submit" onClick={handleSubmit} disabled={isSubmitting} />
                    </div>



                </div>


            </div>




            <Footer />

        </div>
    );
}