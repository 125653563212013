import React, { useEffect, useState } from "react"
import { Descriptions, Checkbox } from 'antd';
import * as Util from "../../functions/common/util";
import { getPresignedURL } from "../../functions/common/file-upload"


export default function SalesAdviceFormReview( {workflow, company} ) {

    const [agentSignatureImgSrc, setAgentSignatureImgSrc] = useState(null);
    const [purchaserSignatureImgSrc, setPurchaserSignatureImgSrc] = useState(null);

    useEffect( () => {

        // console.log(workflow);
        
        const fetchAgentSignatureImg = async (partialS3Key) => {
            if (typeof partialS3Key != 'undefined' && partialS3Key ) {
                const signedUrl = await getPresignedURL({
                    name: `${partialS3Key}`,
                    s3Key: `workflow/otp/${partialS3Key}`,
                }, true, true);
                setAgentSignatureImgSrc(signedUrl);
            }
        }

        const fetchPurchaserSignatureImg = async (partialS3Key) => {
            if (typeof partialS3Key != 'undefined' && partialS3Key ) {
                const signedUrl = await getPresignedURL({
                    name: `${partialS3Key}`,
                    s3Key: `workflow/otp/${partialS3Key}`,
                }, true, true);
                setPurchaserSignatureImgSrc(signedUrl);
            }
        }

        fetchAgentSignatureImg(workflow?.agentSignature);
        fetchPurchaserSignatureImg(workflow?.purchaserSignature)

    }, [workflow])


    useEffect(() => {
        if(company === "pw") {

        } else if(company === "jarrah") {
            
        }

    }, [company])


    const companyDetail_pw = [
        {
            label: <b>Agency Details</b>,
            children: "",
            span: 2
        },
        {
            label: 'Name',
            children: 'PW Star Realty Pty Ltd',
            span: 2,
        },
        {
            label: 'Address',
            children: '320-322 Pacific Hwy, Lindfield NSW 2070',
            span: 2,
        },
        {
            label: 'Principal',
            children: 'Yating Wang',
            span: 2,
        },
        {
            label: 'Phone',
            children: '02 8278 7090',
        },
        {
            label: 'Email',
            children: 'ask@pwrealty.com.au',
        },
    ];

    const companyDetail_jarrah = [
        {
            label: <b>Agency Details</b>,
            children: "",
            span: 2
        },
        {
            label: 'Name',
            children: 'Jarrah Capital Australia Pty Ltd',
            span: 2,
        },
        {
            label: 'Address',
            children: 'Suite 8.01, 570 George St, Sydney NSW 2000',
            span: 2,
        },
        {
            label: 'Principal',
            children: 'Hai Tao Pan',
            span: 2,
        },
        {
            label: 'Phone',
            children: '02 8970 0675',
        },
        {
            label: 'Email',
            children: 'admin@jarrah-capital.com',
        },
    ];

    const items = [
        {
            label: <b>Property Details</b>,
            children: "",
            span: 2
        },
        {
            label: 'Project Name',
            children: workflow.propertyOtp.title,
            span: 2,
        },
        {
            label: 'Address',
            children: workflow.propertyOtp.address,
            span: 2,
        },
        {
            label: 'Lot No.',
            children: workflow.lotNumber,
        },
        {
            label: 'Unit No.',
            children: workflow.unitNumber,
        },
        {
            label: 'Configuration',
            children: workflow.configuration,
        },
        {
            label: 'Level',
            children: workflow.level,
        },
        {
            label: 'Car Parking',
            children: workflow.carParking,
        },
        {
            label: 'Storage',
            children: workflow.storage,
        },
        {
            label: <b>Sale Details</b>,
            children: '',
            span: 2,
        },
        {
            label: 'Price',
            children: `$${Util.formatPrice(workflow.price)}`,
        },
        {
            label: 'Date of Sales',
            children: Util.formatDateValue(workflow.salesDate),
        },
        {
            label: "Purchasing Purpose",
            span: 2,
            children: (
            <div className="flex flex-row flex-wrap justify-between">
                <Checkbox className="pointer-events-none" checked={workflow.purchasePurpose === "First Home Buyer"} >First Home Buyer</Checkbox>
                <Checkbox className="pointer-events-none" checked={workflow.purchasePurpose === "Owner Occupy"} >Owner Occupy</Checkbox>
                <Checkbox className="pointer-events-none" checked={workflow.purchasePurpose === "Investor"} >Investor</Checkbox>
            </div>
            )
        },
        {
            label: "Financial Status",
            span: 2,
            children: (
                <div className="flex flex-wrap space-x-5">
                    <div>
                        Is home loan approved?
                    </div>
                    <div className="flex flex-row flex-wrap">
                        <Checkbox className="pointer-events-none" checked={workflow.homeLoanApproved===true} >Yes</Checkbox>
                        <Checkbox className="pointer-events-none" checked={workflow.homeLoanApproved===false} >No</Checkbox>
                    </div>
                </div>
            
            )
        },
        {
            label: <b>Purchaser 1</b>,
            span: 2,
            children: "",
        },
        {
            label: "Purchaser Name",
            span: 2,
            children: `${workflow.purchaserName}`,
        },
        {
            label: "Purchaser Address",
            span: 2,
            children: (
                <div className="flex flex-row flex-wrap justify-between">
                    <div>{workflow.purchaserAddress}</div>
                    <div><span className="font-semibold">Postcode</span>:  <span className="w-10 inline-block">{workflow.purchaserPostcode}</span></div>
                </div>
            ),
        },
        {
            label: "Mobile",
            children: `${workflow.purchaserMobile}`,
        },
        {
            label: "Email",
            children: `${workflow.purchaserEmail}`,
        },
        {
            label: "FIRB Status",
            span: 2,
            children: (
                <div className="flex flex-row flex-wrap justify-between">
                    <Checkbox className="pointer-events-none" checked={workflow.purchaserFirbStatus==="Australian Citizen"} >Australian Citizen</Checkbox>
                    <Checkbox className="pointer-events-none" checked={workflow.purchaserFirbStatus==="Permanent Resident"} >Permanent Resident</Checkbox>
                    <Checkbox className="pointer-events-none" checked={workflow.purchaserFirbStatus==="Oversea Buyer"} >Oversea Buyer</Checkbox>
                </div>
            ),
        },
        {
            label: <b>Purchaser 2</b>,
            span: 2,
            children: "",
        },
        {
            label: "Purchaser Name",
            span: 2,
            children: `${workflow.purchaser2Name}`,
        },
        {
            label: "Purchaser Address",
            span: 2,
            children: (
                <div className="flex flex-row flex-wrap justify-between">
                    <div>{workflow.purchaser2Address}</div>
                    <div><span className="font-semibold">Postcode</span>: <span className="w-10 inline-block">{workflow.purchaser2Postcode}</span></div>
                </div>
            ),
        },
        {
            label: "Mobile",
            children: `${workflow.purchaser2Mobile}`,
        },
        {
            label: "Email",
            children: `${workflow.purchaser2Email}`,
        },
        {
            label: "FIRB Status",
            span: 2,
            children: (
                <div className="flex flex-row flex-wrap justify-between">
                    <Checkbox className="pointer-events-none" checked={workflow.purchaser2FirbStatus === "Australian Citizen"} >Australian Citizen</Checkbox> 
                    <Checkbox className="pointer-events-none" checked={workflow.purchaser2FirbStatus === "Permanent Resident"} >Permanent Resident</Checkbox> 
                    <Checkbox className="pointer-events-none" checked={workflow.purchaser2FirbStatus === "Oversea Buyer"} >Oversea Buyer</Checkbox>
                </div>
            ),
        },
        {
            label: <b>Purchaser Solicitor Details</b>,
            span: 2,
            children: "",
        },
        {
            label: "Company Name",
            children: `${workflow.solicitorCompany}`,
        },
        {
            label: "Contact Name",
            children: `${workflow.solicitorName}`,
        },
        {
            label: "Purchaser Address",
            span: 2,
            children: (
                <div className="flex flex-row flex-wrap justify-between">
                    <div>{workflow.solicitorAddress}</div>
                    <div><span className="font-semibold">Postcode</span>: <span className="w-10 inline-block">{workflow.solicitorPostcode}</span></div>
                </div>
            ),
        },
        {
            label: "PO Box",
            span: 2,
            children: `${workflow.solicitorPoBox}`,
        },
        {
            label: "Phone",
            children: `${workflow.solicitorPhone}`,
        },
        {
            label: "Fax",
            children: `${workflow.solicitorFax}`,
        },
        {
            label: "Mobile",
            children: `${workflow.solicitorMobile}`,
        },
        {
            label: "Email",
            children: `${workflow.solicitorEmail}`,
        },
        {
            label: <b>Deposit Details</b>,
            span: 2,
            children: "",
        },
        {
            label: "Type of Deposit",
            children: `${workflow.depositType}`,
        },
        {
            label: "Amount Taken",
            children: `$${Util.formatPrice(workflow.depositAmount)}`,
        },
        {
            label: "Account Holding",
            span: 2,
            children: (
                <div className="flex flex-row flex-wrap justify-between">
                    <Checkbox className="pointer-events-none" checked={workflow.accountHolding==="Developer Trust Account"} >Developer Trust Account</Checkbox> 
                    <Checkbox className="pointer-events-none" checked={workflow.accountHolding==="PW Realty Trust Account"} >PW Realty Trust Account</Checkbox> 
                </div>
            ),
        },
        {
            label: <b>Special Conditions/<br />Terms of Purchase</b>,
            span: 2,
            children: <div dangerouslySetInnerHTML={{ __html: workflow.specialCondition.replace(/\n/g, '<br />') }}></div>,
        },
        {
            label: <b>Signature</b>,
            span: 2,
            children: "",
        },
        {
            label: "Agent Signature",
            span: 2,
            children: (
                <div className="flex flex-row flex-wrap justify-between items-end">
                    <div>
                        {agentSignatureImgSrc && (
                            <img alt="" src={agentSignatureImgSrc} className="max-w-xs max-h-10"/>
                        )}
                    </div>
                    <div><span className="font-semibold">Date</span>: <span className="w-24 inline-block">{Util.formatDateValue(workflow.agentSignatureDate)}</span></div>
                </div>
            ),
        },
        {
            label: "Purchaser Signature",
            span: 2,
            children: (
                <div className="flex flex-row flex-wrap justify-between items-end">
                    <div>
                        {purchaserSignatureImgSrc && (
                            <img alt="" src={purchaserSignatureImgSrc} className="max-w-xs max-h-10"/>
                        )}
                    </div>
                    <div><span className="font-semibold">Date</span>: <span className="w-24 inline-block">{Util.formatDateValue(workflow.purchaserSignatureDate)}</span></div>
                </div>
            ),
        },
        
    ];


    return <>
        <Descriptions
            bordered
            column={{ xs: 1, sm: 2, md: 2, lg: 2, xl: 2, xxl: 2 }}
            items={[
                ...(company === "pw" ? companyDetail_pw : (company === "jarrah" ? companyDetail_jarrah : [])),
                ...items,
            ]}
            size="small"
        />
    </>
}