import React, { useState } from "react";
import { useDropzone } from "react-dropzone";

function Dropzone({ dropzoneId, onFileUpload }) {

    const [files, setFiles] = useState([]);

    const onDrop = (acceptedFiles) => {
        // Do something with the accepted files (e.g., upload to a server)
        const allFiles = [...files, ...acceptedFiles];

        setFiles(allFiles);
        
        onFileUpload(dropzoneId, allFiles);
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: "image/*", // Specify the accepted file types
        maxFiles: 5,
        maxSize: 1024 * 1024 * 10, // Set the maximum file size limit per file - 10MB
    });

    const formatFileSize = (sizeInBytes) => {
        if (sizeInBytes >= 1e6) {
            // Convert to megabytes (MB) with one decimal point
            return (sizeInBytes / 1e6).toFixed(1) + ' MB';
        } else if (sizeInBytes >= 1e3) {
            // Convert to kilobytes (KB) with no decimal points
            return Math.round(sizeInBytes / 1e3) + ' KB';
        } else {
            // Display in bytes
            return sizeInBytes + ' b';
        }
    };

    const removeFile = (fileToRemove) => {
        const updatedFiles = files.filter((file) => file !== fileToRemove);
        setFiles(updatedFiles);

        //update parent state
        onFileUpload(dropzoneId, updatedFiles);
    };

    return (
        <div>
            <div className="dropzone-container bg-base-200 ">
                <div
                    {...getRootProps()}
                    className={`dropzone ${isDragActive ? "active" : ""}`}
                >
                    <input {...getInputProps()} />
                    {isDragActive ? (
                        <p>Drop the files here...</p>
                    ) : (
                        <p>Drag and drop files here, or click to select files</p>
                    )}
                </div>

            </div>
            <aside className="uploaded-files-container">
                <ul>
                    {files && (
                        files.map((file, ndx) => (
                            <li key={ndx} id={dropzoneId+"-"+ndx} className="p-2">
                                <div className="flex justify-between">
                                    <div className="text-sm">{file.path} - {formatFileSize(file.size)}</div>
                                    <div>< i className="ri-delete-bin-line text-red-500 cursor-pointer" onClick={() => {removeFile(file)}}></i></div>
                                </div>
                            </li>
                        ))
                    )}
                </ul>
            </aside>
        </div>
    );
}

export default Dropzone;
