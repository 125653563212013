import React, { useState, useEffect } from "react"
import SalesAdviceFormReview from "./SalesAdviceFormReview";
import { Collapse, Modal, Select, Tag, Alert } from 'antd';
import FinancialDetails from "./FinancialDetails";
import Button from "../Button";
import { isLoginUserAdmin, isLoginUserPartner } from "../../functions/common/manage-login-user";
import axios from "axios";
import Toast from "../Toast";
import DocumentContainer from "./DocumentContainer";
import { formatPrice } from "../../functions/common/util";
import { printSalesAdvice } from "../../functions/common/sales-advice";


export default function ContractProcessingForm({ workflow, updateWorkflow }) {

    const isAdmin = isLoginUserAdmin();
    const isPartner = isLoginUserPartner();

    const [partnerReviewed, setPartnerReviewed] = useState(workflow.approvedBy > 0 ? true : false);


    const [idFilesUploaded, setIdFilesUploaded] = useState([]);
    const [depositReceiptUploaded, setDepositReceiptUploaded] = useState([]);

    useEffect(() => {
        const fetchWorkflowDocs = (id) => {
            if (!id) {
                return;
            }

            axios.get(`${process.env.REACT_APP_API_URL}/api/workflow/otp/fetch-docs`, {
                params: {
                    workflowId: id,
                }, // Pass the search data as query parameters
            }).then(response => {
                if (response.status === 200) {
                    const files = response.data;
                    files.forEach((file) => {
                        if (file.fileType === "id") {
                            setIdFilesUploaded(prevFiles => ([
                                ...prevFiles,
                                {
                                    name: file.filename,
                                    s3Key: file.s3Key
                                }
                            ]))
                        } else if (file.fileType === "deposit receipt") {
                            setDepositReceiptUploaded(prevFiles => ([
                                ...prevFiles,
                                {
                                    name: file.filename,
                                    s3Key: file.s3Key
                                }
                            ]))
                        }
                    })
                }
            }).catch(error => {
                console.log(error)
            });
        }

        fetchWorkflowDocs(workflow.id);

    }, [workflow])


    const editSalesAdvice = () => {
        workflow.status = 0;
        updateWorkflow(workflow);
    }


    const [changesDetected, setChangesDetected] = useState(false);

    const handlePartnerApproval = () => {
        if (changesDetected) {
            showToast("error", "Please submit your changes first.")
            return;
        }

        axios.post(`${process.env.REACT_APP_API_URL}/api/workflow/otp/partner-approval/${workflow.id}`)
            .then(response => {
                if (response.status === 200) {
                    setPartnerReviewed(true);
                    showToast("success", "Approved successfully.")
                }
            }).catch(error => {
                console.log(error);
                showToast("error", "Error.")
            });
    }

    const onChangeHandler = (e) => {
        const { id, value } = e.target;
        workflow[`${id}`] = value;
    }

    const submitExchange = () => {
        // console.log(workflow.exchangeDate);
        if (typeof workflow.exchangeDate === 'undefined' || workflow.exchangeDate === null || workflow.exchangeDate === "") {
            showToast("error", "Exchange date required.");
            return;
        }

        if (typeof workflow.salesAdviceDate === 'undefined' || workflow.salesAdviceDate === null || workflow.salesAdviceDate === "") {
            showToast("error", "Sales advice date required.");
            return;
        }

        // if (typeof workflow.estSettlementDate === 'undefined' || workflow.estSettlementDate === null || workflow.estSettlementDate === "") {
        //     showToast("error", "Estimate settlement date required.");
        //     return;
        // }

        const data = {
            workflowId: workflow.id,
            exchangeDate: workflow.exchangeDate,
            estSettlementDate: workflow.estSettlementDate,
            salesAdviceDate: workflow.salesAdviceDate,
        }

        // console.log(data);

        axios.put(`${process.env.REACT_APP_API_URL}/api/workflow/otp/exchange`, data, {
            headers: {
                'Content-Type': 'application/json', // Specify JSON content type
            },
        })
            .then(response => {
                console.log(response)
                if(response.data.length !== 2) {
                    showToast("error", "Error.");
                    return;
                }

                if (response.data[0].status === 1) {
                    showToast("error", "Invalid commission setting.")
                    //display the details of the commission setting
                    setViewLogButton(true);
                    setCommissionLog(response.data[1]);
                    return;
                }

                showToast("success", "Success!");
                showModal(false);
                workflow.status = 2;
                updateWorkflow(workflow);
            }).catch(error => {
                console.log(error)
                showToast("error", "Error.")
            });
    }

    const [viewLogButton, setViewLogButton] = useState(false);
    const [showLogModal, setShowLogModal] = useState(false);
    const [commissionLog, setCommissionLog] = useState({});

    const [isToastVisible, setIsToastVisible] = useState(false);
    const [toastType, setToastType] = useState("normal");
    const [toastMessage, setToastMessage] = useState("");

    const showToast = (type, message) => {
        setIsToastVisible(true);
        setToastType(type);
        setToastMessage(message);

        // Optionally, you can set a timeout to hide the toast after a certain duration
        setTimeout(() => {
            setIsToastVisible(false);
        }, 2000); // Hide the toast after 3 seconds (adjust as needed)
    };



    const exchangeContract = () => {
        if (changesDetected) {
            showToast("error", "Please submit your changes first.")
            return;
        }

        showModal(true);
    }
    const [open, setOpen] = useState(false);
    const showModal = (show) => {
        setOpen(show);
    };

    const selectCompany = () => {
        if (isAdmin) {
            //only admin can choose company
            showCompanyModal();
        } else {
            handlePrint();
        }
    }

    const [isCompanyModalOpen, setIsCompanyModalOpen] = useState(false);
    const showCompanyModal = () => {
        setIsCompanyModalOpen(true);
    };

    const handleOk = () => {
        setIsCompanyModalOpen(false);
    };

    const handleCancel = () => {
        setIsCompanyModalOpen(false);
    };


    const [companySelected, setCompanySelected] = useState("pw");

    const handlePrint = () => {
        printSalesAdvice(workflow.id, companySelected);
    }

    return <>

        <Toast isVisible={isToastVisible} type={toastType} message={toastMessage} />

        <Modal title="Choose company"
            open={isCompanyModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={() => (
                <>
                    <Button onClick={handlePrint} buttonText={"Confirm"} />
                </>
            )}>
            <Select
                value={companySelected}
                className="w-full mb-5 mt-5"
                onChange={(value) => { setCompanySelected(value) }}
                options={[
                    { value: 'pw', label: 'PW Star Realty Pty Ltd' },
                    { value: 'jarrah', label: 'Jarrah Capital Australia Pty Ltd' },
                ]}
            />
        </Modal>

        <div className="static card w-fill bg-base-100 shadow-md">
            <div className="card-body">

                <Collapse items={[{
                    key: '1',
                    label: 'Sales Advice',
                    children:
                        <div>
                            <div className="flex justify-between mb-4">
                                <button className="link-pw" onClick={selectCompany}>
                                    Print Sales Advice
                                </button>
                                {isAdmin && (
                                    <div title="Click to edit sales advice." className={`cursor-pointer link-pw`} onClick={editSalesAdvice}>
                                        <i className="ri-edit-line"></i>
                                    </div>
                                )}
                            </div>

                            <SalesAdviceFormReview workflow={workflow} />
                        </div>,
                }]} />


                <Collapse
                    // defaultActiveKey={['1']}
                    items={[{
                        key: '1',
                        label: 'Documents',
                        children: <>
                            <DocumentContainer title={"Identity (Passport or Driver License)"} files={idFilesUploaded} />
                            <div className="m-5"></div>
                            <DocumentContainer title={"Deposit Receipt"} files={depositReceiptUploaded} />
                        </>,
                    }]}
                />


                <Collapse
                    activeKey="1"
                    items={[{
                        key: '1',
                        label: 'Financial Details',
                        children: <FinancialDetails workflow={workflow} setChangesDetected={setChangesDetected} partnerReviewed={partnerReviewed} />,
                    }]}
                />


                <div className="space-x-5 mt-10">
                    {/* financial details are submitted, current user role is partner, and has not been approved */}
                    { workflow.financialDetailSubmitDate !== null && isPartner && !partnerReviewed ? (
                        <Button buttonText={"Partner Approve"} onClick={handlePartnerApproval} />
                    ) : (
                        <>
                            {/* <button type="button" className="pointer-events-none py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border-2 border-red-200 font-medium bg-white text-red-300 shadow-sm align-middle focus:outline-none transition-all cursor-not-allowed text-sm" title="Only admin can exchange">
                            Exchange Contract
                        </button> */}
                        </>
                    )}

                    {/* only admin can click exchange button, and the workflow must not be canceled */}
                    {isAdmin && workflow.enabled && (
                        partnerReviewed ?
                            <Button buttonText={"Exchange"} onClick={exchangeContract} />
                            :
                            <button type="button" className="pointer-events-none py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border-2 border-red-200 font-medium bg-white text-red-300 shadow-sm align-middle focus:outline-none transition-all cursor-not-allowed text-sm">
                                Waiting for Partner Approval
                            </button>
                    )}

                </div>


                <Modal
                    onCancel={() => { showModal(false) }}
                    open={open}
                    title=""
                    footer={() => (
                        <>
                            <Button onClick={submitExchange} buttonText={"Submit"} />
                        </>
                    )}
                >
                    <div className="mt-10 mb-10 grid gap-4 grid-cols-1 sm:grid-cols-2">
                        <div>
                            <label className="font-medium" htmlFor="exchangeDate">Exchange Date <span className="text-error">*</span></label>
                            <input type="date" id="exchangeDate" onChange={onChangeHandler} placeholder="" className="mt-2 py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500 mandatory" />
                        </div>
                        <div>
                            <label className="font-medium" htmlFor="estSettlementDate">Est. Settlement Date</label>
                            <input type="date" id="estSettlementDate" onChange={onChangeHandler} placeholder="" className="mt-2 py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500" />
                        </div>
                    </div>

                    <div className="grid gap-4 grid-cols-1 mb-5">
                        <div>
                            <label className="font-medium" htmlFor="salesAdviceDate">Commission Calculation Date <span className="text-error">*</span></label>
                            <input type="date" id="salesAdviceDate" onChange={onChangeHandler} placeholder="" className="mt-2 py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500 mandatory" />
                            <p className="text-gray-500">Commission will be calculated based on this date.</p>
                        </div>
                    </div>

                    {viewLogButton && (
                        <div className="grid gap-4 grid-cols-1 mb-5">
                            <span className="link-pw cursor-pointer" onClick={() => setShowLogModal(true)}>View Error Log</span>
                        </div>
                    )}
                </Modal>

                <Modal
                    onCancel={() => { setShowLogModal(false) }}
                    open={showLogModal}
                    title=""
                    footer={() => (<></>)}
                >
                    <div className="mt-10 mb-10 grid grid-cols-1">
                        <Alert
                            className="mb-5"
                            description={<div dangerouslySetInnerHTML={{__html: "<p>Please review the commission setting and calculation results.</p>&#8226; All numbers must be bigger than 0<br/>&#8226; Allocation add up must be equal to net commission.<br/>&#8226; Remember that the comm. amount of selling agent incl. bonus."}}></div>}
                            type="error" 
                        />
                        <label>Settings (Incl. GST):</label>
                        <Tag className="mb-5">
                        <p>
                                Contract Price: ${formatPrice(commissionLog.contractPrice)}
                            </p>
                            <p>
                                Commission Rate: {commissionLog.commissionRate}%
                            </p>
                            <p>
                                Commission Amount: ${formatPrice(commissionLog.commissionAmount)}
                            </p>
                            <p>
                                Bonus: ${formatPrice(commissionLog.bonus)}
                            </p>
                            <p>
                                Cap Rate: {commissionLog.commissionCapRate}%
                            </p>
                            {commissionLog.commissionCapAmount > 0 && (
                                <p>
                                    Cap Amount: ${formatPrice(commissionLog.commissionCapAmount)}
                                </p>
                            )}
                            <p className="mt-2">
                                Customer Rebate (Vendor): ${formatPrice(commissionLog.customerRebateVendor)}
                            </p>
                            <p>
                                Customer Rebate (PW): ${formatPrice(commissionLog.customerRebateAgent)}
                            </p>
                            <p>
                                Referral Fee: ${formatPrice(commissionLog.referralFee)}
                            </p>
                            
                        </Tag>

                        <label>Calculation Results (Incl. GST):</label>
                        <Tag className="mb-2">
                            <p>
                                Total Commission: <span className="underline decoration-solid underline-offset-2">{commissionLog.totalCommissionInclGst >= 0 ? ("$" + formatPrice(commissionLog.totalCommissionInclGst)) : ("-$" + formatPrice(-commissionLog.totalCommissionInclGst))}</span>
                            </p>
                            <p>
                                Net Commission: <span className="underline decoration-solid underline-offset-2">{commissionLog.netCommissionInclGst >= 0 ? ("$" + formatPrice(commissionLog.netCommissionInclGst)) : ("-$" + formatPrice(-commissionLog.netCommissionInclGst))}</span>
                            </p>
                        </Tag>
                        <Tag>
                            {commissionLog.commissionAllocationOtps?.map((comm, ndx) => {
                                return (
                                    <p key={ndx}>
                                        {comm.user==null ? "PW Retainment" : (
                                            comm.user.firstName+" "+comm.user.lastName
                                        )}: <span className="underline decoration-solid underline-offset-2">{comm.commissionInclGst >= 0 ? ("$"+formatPrice(comm.commissionInclGst)) : ("-$"+formatPrice(-comm.commissionInclGst))}</span>
                                    </p>
                                )
                            })}
                        </Tag>
                    </div>

                </Modal>
            </div>
        </div>

    </>
}