import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { ConfigProvider, Steps, Popconfirm, Alert } from "antd";
import { useParams } from "react-router-dom";
import axios from "axios";
import ResaleSellingInfo from "../components/workflow/ResaleSellingInfo";
import ResaleOnsale from "../components/workflow/ResaleOnsale";
import ResaleCompleted from "../components/workflow/ResaleCompleted";
import { isLoginUserAdmin } from "../functions/common/manage-login-user";


export default function WorkflowResaleDetail() {

    const { workflowId } = useParams();

    const [workflow, setWorkflow] = useState(null);

    const [permissionDenied, setPermissionDenied] = useState(false);

    useEffect(() => {
        // checkLogin();     
        document.title = "Workflow"
    }, []);

    useEffect(() => {
        const fetchWorkflowDetail = (id) => {
            if (!id) {
                setWorkflow({ status: 0 });
                return;
            }

            axios.get(`${process.env.REACT_APP_API_URL}/api/workflow/resale/fetch/${id}`)
                .then(response => {
                    if (response.status === 200) {
                        console.log(response.data)
                        setWorkflow(response.data);
                        // setCurrentStep(response.data.status);
                    }
                }).catch(error => {
                    console.log(error)
                    setPermissionDenied(true);
                });
        }

        fetchWorkflowDetail(workflowId);

    }, [workflowId]);


    const handleWorkflowUpdate = (updatedWorkflow) => {
        setWorkflow({
            ...updatedWorkflow
        });
    }


    const deleteWorkflow = () => {
        axios.post(`${process.env.REACT_APP_API_URL}/api/workflow/resale/delete`, {
            id: workflowId
        })
        .then((response) => {
            if (response.status === 200) {
                // console.log(response.data)
                setWorkflow(response.data);
                // setCurrentStep(response.data.status);
            }
        }).catch((error) => {

        })
    }

    if (workflow === null && !permissionDenied) {
        return <></>;
    }

    if (permissionDenied) {
        return (
            <>
                <Header currentPage="workflow" />

                <div className="main-container">
                    <div className=" grid grid-cols-1 gap-4 p-5">
                        <div className="static card w-fill bg-base-100 shadow-md">
                            <div className="card-body">
                                <div className="grid gap-4 grid-cols-1">
                                    <Alert
                                        className="mb-5"
                                        description={<div dangerouslySetInnerHTML={{ __html: "<p>This workflow does not exist or you don't have the permission to view this workflow.</p>" }}></div>}
                                        type="error"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />
            </>
        )
    }


    //[update: 2024-01-21] show canceled workflows
    // if ( workflowId>0 && !workflow.enabled) {
    //     return (
    //     <>
    //         <Header currentPage="workflow" />

    //         <div className="main-container">
    //             <div className=" grid grid-cols-1 gap-4 p-5">
    //                 <div className="static card w-fill bg-base-100 shadow-md">
    //                     <div className="card-body">
    //                         <div className="grid gap-4 grid-cols-1">
    //                             <Alert
    //                                 className="mb-5"
    //                                 description={<div dangerouslySetInnerHTML={{ __html: "<p>This workflow has been deleted or you don't have the permission to view this workflow.</p>" }}></div>}
    //                                 type="error"
    //                             />
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </div>

    //         <Footer />
    //     </>
    //     )
    // }


    return (
        <div>
            <Header currentPage="workflow" />

            <div className="main-container">
                <div className=" grid grid-cols-1 gap-4 p-5">
                    <div className="static card w-fill bg-base-100 shadow-md">
                        <div className="card-body">
                            <div className="grid gap-4 grid-cols-1">
                                <div>
                                    <ConfigProvider
                                        theme={{
                                            components: {
                                                Steps: {
                                                    colorPrimary: '#f15b22',
                                                    dotSize: 10, // Enable algorithm
                                                }
                                            },
                                        }}
                                    >
                                        <Steps
                                            progressDot
                                            responsive
                                            current={workflow?.status}
                                            status="error"
                                            items={[
                                                {
                                                    title: 'Draft',
                                                    description: "",
                                                },
                                                {
                                                    title: 'On Sale',
                                                    description: "",
                                                },
                                                {
                                                    title: 'Settlement',
                                                    description: "",
                                                },
                                            ]}
                                        />
                                    </ConfigProvider>
                                </div>
                            </div>

                            {workflowId>0 && !workflow.enabled ? <Alert
                                className="mt-4"
                                description={<div dangerouslySetInnerHTML={{ __html: "<p>This workflow has been canceled.</p>" }}></div>}
                                type="error"
                            /> : ""}
                        </div>
                    </div>

                    {(workflow?.status === 0) && (
                        <ResaleSellingInfo workflow={workflow} updateWorkflow={handleWorkflowUpdate} />
                    )}


                    {workflow?.status === 1 && (
                        <ResaleOnsale workflow={workflow} updateWorkflow={handleWorkflowUpdate} />
                    )}


                    {workflow?.status === 2 && (
                        <ResaleCompleted workflow={workflow} />
                    )}

                    {workflow?.status !== 2 && workflow?.enabled && isLoginUserAdmin() && (
                        <div>
                            <Popconfirm
                                className="mr-4 my-5"
                                title="Delete workflow"
                                description="Are you sure?"
                                onConfirm={deleteWorkflow}
                                onCancel={() => { }}
                                okText="Yes"
                                cancelText="No"
                                okButtonProps={{ className: "bg-pw hover:bg-pw-hover" }}
                            >
                                <button type="button" className="float-right mx-2 text-xs py-1 px-4 inline-flex justify-center items-center gap-2 rounded-md border-2 border-orange-500 font-semibold text-orange-500 hover:text-white hover:bg-pw focus:outline-none">
                                    Delete Workflow
                                </button>
                            </Popconfirm>
                        </div>
                    )}


                </div>
            </div>

            <Footer />

        </div>
    );
}