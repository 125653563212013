import axios from "axios";

export const isLoginUserSystemAdmin = () => {
    return localStorage.getItem("role") === "Super Admin";
}

export const isLoginUserAdmin = () => {
    return localStorage.getItem("role") === "Admin" 
    || localStorage.getItem("role") === "Super Admin" 
    || localStorage.getItem("role") === "CEO";
};


export const isLoginUserPartner = () => {
    return localStorage.getItem("role") === "Partner";
};


export const isLoginUserManager = () => {
    return localStorage.getItem("role").includes("CEO")
    || localStorage.getItem("role").includes("Partner")
    || localStorage.getItem("role").includes("Manager");
}

export const fetchUserLevelAndRate = async () => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/user/level-and-rate`)
    
    if (response.status === 200) {
        // console.log(response.data)
        return response.data;
    } 
    
    return null;
}
