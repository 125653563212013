import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import UserList from "../components/user/UserList";
import UserDetail from "../components/user/UserDetail";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { isLoginUserAdmin } from "../functions/common/manage-login-user";

export default function User() {

    // const {userId} = useParams();


    const navigate = useNavigate();

    useEffect(() => {
        // checkLogin();     
        document.title = "User"

        const hasAccess = isLoginUserAdmin();
        if (!hasAccess) {
            navigate("/login")
            return;
        }

        const fetchUsers = () => {
            axios.get(`${process.env.REACT_APP_API_URL}/api/user/all-users`)
                .then(response => {
                    if (response.status === 200) {
                        //login success redirect to dashboard
                        if (response.data != null) {
                            // console.log(response)
                            // if(userId && userId > 0) {
                            //     //if in the URL has a userId param, then directly display a user's info 
                            //     response.data.forEach( user => {
                            //         if (user.id == userId ) {
                            //             setSelectedUser(user);
                            //         }
                            //     } )

                            //     return;
                            // }

                            setUsers(response.data);
                            setListUsers(response.data);
                        }

                    }
                })
                .catch(error => { });
        }

        fetchUsers();
    }, [navigate]);

    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [listUsers, setListUsers] = useState([]);

    const selectUserHandler = (user) => {
        // console.log(user);
        setSelectedUser(user);
    }

    const handleReturnClick = () => {
        setSelectedUser(null);
    }

    const strIncludes = (str) => {
        let searchItem = document.getElementById("searchItem").value.toLowerCase();

        if (str != null && str.toLowerCase().includes(searchItem)) {
            return true;
        }
        return false;
    }

    const searchUser = () => {
        let searchResult = [];
        users.forEach((user) => {
            if (strIncludes(user.emailAddress)
                || strIncludes(user.contactPhone)
                || strIncludes(user.firstName)
                || strIncludes(user.lastName)
                || strIncludes(user.firstName + " " + user.lastName)) {
                searchResult.push(user);
            }
        });

        setListUsers(searchResult);

    }

    const updateUserData = (userId, newUserData) => {
        // Update the user status on the server (make the AJAX request)
        // After a successful update on the server, update the users state.
        // You can also update the listUsers state here if needed.

        // Example of updating the user status in the client-side state:
        const updatedUsers = users.map(user => {
            if (user.id === userId) {
                return newUserData;
            }
            return user;
        });

        setUsers(updatedUsers);
        setListUsers(updatedUsers);
    }

    return (
        <div>
            <Header currentPage="user" />

            <div className="main-container">
                {selectedUser ? (
                    <UserDetail user={selectedUser} onReturnClick={handleReturnClick} updateUserData={updateUserData} />
                ) : (
                    <div>
                        <div className="grid grid-cols-1 gap-4 p-5">
                            <div className="flex flex-wrap justify-between items-center">
                                <div className="form-control">
                                    <div className="input-group">
                                        <input type="text" id="searchItem" onChange={searchUser} placeholder="Search name / email / mobile" className="input input-sm input-bordered w-64" style={{ position: "static", zIndex: 1 }} />
                                        <button className="btn btn-sm btn-square btn-pw" onClick={searchUser}>
                                            <i className="ri-search-line"></i>
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </div>

                        {/* <span className="loading loading-spinner text-error"></span> */}


                        <UserList users={listUsers} selectUserHandler={selectUserHandler} />
                    </div>
                )}

            </div>
            <Footer />

        </div>
    );
}