import React from "react";
import axios from "axios";
import { Tag } from "antd";
import { isLoginUserSystemAdmin } from "../../functions/common/manage-login-user";

export default function UserList( {users, selectUserHandler} ) {

    const managerIdNameMap = users.reduce((acc, user) => {
        const fullName = `${user.firstName}${user.middleName===""?"":" " + user.middleName} ${user.lastName}`;
        acc[user.id] = fullName;
        return acc;
    }, {});

    // console.log(managerIdNameMap)

    const loginAs = (id) => {
        axios.post(`${process.env.REACT_APP_API_URL}/api/user/loginAs`, {id: id}, {
            headers: {
                'Content-Type': 'application/json', // Specify JSON content type
            },
        }).then(response => {
            if (response.status === 200) {
                //login success redirect to dashboard
                if (response.data != null) {
                    localStorage.setItem('token', response.data.token);
                    localStorage.setItem('userId', response.data.userId);
                    localStorage.setItem('userName', response.data.userName);
                    localStorage.setItem('userNameInit', response.data.userNameInit);
                    localStorage.setItem('role', response.data.role);

                    // console.log("init localStorage... token = " + response.data.token);
                    alert("success");
                }
            }
        }).catch(error => {
            console.log(error);
        });
    }

    return (
        <div className="grid grid-cols-1 gap-4 px-5">
            <div className="static card w-fill bg-base-100 shadow-md">
                <div className="card-body">
                    {users.map((user, index) => (
                        <div key={index} className="flex flex-wrap items-center justify-between border-b-2 mt-2 mb-2">
                            <div>
                                <b>
                                    {isLoginUserSystemAdmin() && (<i className="ri-login-box-line text-gray-400 cursor-pointer me-2" onClick={() => loginAs(user.id)}></i>)}
                                    {user.firstName + (user.middleName ? (" "+user.middleName):"") + " " + user.lastName}
                                </b>
                                <br />
                                <div className="flex flex-wrap">
                                    <span className="text-sm text-gray-400 mr-5">
                                        <i className="ri-home-office-line"></i> {user.branchOffice}
                                    </span>
                                    <span className="text-sm text-gray-400 mr-5">
                                        <i className="ri-user-received-line"></i> {managerIdNameMap[user.reportingManager]}
                                    </span>
                                    
                                    <span className="text-sm text-gray-400 mr-5">
                                        <i className="ri-smartphone-line"></i> {user.contactPhone}
                                    </span>
                                    <span className="text-sm text-gray-400 mr-5">
                                        <i className="ri-mail-line"></i> {user.emailAddress}
                                    </span>

                                    <span className="text-sm text-gray-400 mr-5">
                                    <i className="ri-file-list-3-line"></i> GST ({user.gstStatus ? "Y" :"N"})
                                    </span>

                                    {/* <span className="text-sm text-gray-400 mr-5">
                                        <i className="ri-user-shared-line"></i> {user.role ? user.role : user.agentType}
                                    </span> */}

                                </div>
                            </div>
                            <div className="flex items-center">
                                <Tag color={user.status===0?"geekblue":(user.status===1?"green":"volcano")}>
                                    {user.status===0?"Pending":(user.status===1?"Active":"Inactive")}
                                </Tag>
                                <button className="ml-5 link-pw cursor-pointer text-sm" onClick={() => selectUserHandler(user)}>View Detail</button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
} 