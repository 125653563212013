import axios from "axios";

function formatPropertyJson(p) {
    return {
        id: p.id,
        imgSrc: p.coverImage,
        description: `${p.sellingAgent ? ("Asking " + p.sellingAgent) : ""}`,
        title: p.address,
        address: p.suburb + " " + p.state + " " + p.postcode,
    };
}

const fetchProperties = async () => {
    let properties = [];

    await axios.get(`${process.env.REACT_APP_API_URL}/api/property/resale/fetch-all`)
    .then(response => {
        if (response.status === 200) {
            // console.log(response.data)
            response.data.forEach(p => {
                properties.push(formatPropertyJson(p));
            })                
        }
    }).catch(error => {
        console.log(error)
    });

    
    return properties;
}


const searchProperty = async (searchData) => {
    let properties = [];

    await axios.get(`${process.env.REACT_APP_API_URL}/api/property/resale/search`, {
        params: searchData, // Pass the search data as query parameters
      })
    .then(response =>  {
        if (response.status === 200) {
            // console.log(response.data)
            response.data.forEach(p => {
                properties.push(formatPropertyJson(p));
            })

        }
    }).catch(error => {
        console.log(error)
    });

    return properties;
}


export {fetchProperties, searchProperty};