import React, { useEffect, useState } from "react";
import axios from 'axios';
import css from './loginpage.module.css';
import { Link } from "react-router-dom";
import { isStrongPassword } from "../functions/common/util";
import { useNavigate } from "react-router-dom";

export default function ResetPassword() {

    useEffect(() => {
        // Set the title for this page
        document.title = 'Reset Password - PW Realty';
    }, []);

    const navigate = useNavigate();

    const [errorMessage, setErrorMessage] = useState("");    

    const [formData, setFormData] = useState({
        emailAddress: "",
        verificationCodeEntered: "",
        newPassword: "",
        newPasswordConfirm: "",
        token: "",
    });

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData({
            ...formData,
            [id]: value,
        });

    };


    function validateEmail(email) {
        var regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    }    

    const [step, setStep] = useState(1);

    const handleOnSubmit = async (e) => {
        e.preventDefault(); // Prevent the default form submission

        let valid = validateEmail(formData.emailAddress)
        // valid = true;

        if (!valid) {
            return;
        }

        // console.log(formData)

        if (step===1) {
            runStep1();
        } else if (step===2) {
            runStep2();
        } else if (step===3) {
            runStep3();
        }

        //submit login form
        return;
    }

    const [tokenExpiryTime, setTokenExpiryTime] = useState(null);

    const [verificationCode, setVerificationCode] = useState("");
    function runStep1() {
        //check if email exist, if exist get verification code, otherwise throws an error
        axios.post(`${process.env.REACT_APP_API_URL}/api/user/verification`, formData, {
            headers: {
                'Content-Type': 'application/json', // Specify JSON content type
            },
        }).then(response => {
            if (response.status === 200) {
                formData.token = response.data.tokenValue;
                const dataSplit = response.data.tokenValue.split("-");

                if (dataSplit.length >= 1) {
                    setVerificationCode(dataSplit[0]);
                    setTokenExpiryTime(new Date(response.data.expiryTimestamp));
                    //show step2 -> verify code

                    setErrorMessage("");
                    setStep(2);
                    
                }                
            }
        }).catch(error => {
            setErrorMessage("Invalid email address.");
        });
    }
  
    function runStep2() {
        //check if code matches
        let currentTime = new Date();

        if(verificationCode === "" || tokenExpiryTime <= currentTime ) {
            setErrorMessage("Verification code expired, please resend a new one.");
            return;
        }

        if (formData.verificationCodeEntered.length !== 6 || formData.verificationCodeEntered !== verificationCode) {
            setErrorMessage("Invalid verification code.");
            return;
        }

        //pass validation, go to step3
        setErrorMessage("")
        setStep(3);
    }


    function runStep3() {
        
        let strongPW = isStrongPassword(formData.newPassword);

        if (!strongPW) {
            setErrorMessage("Password minimum length is 8 characters and must contain at least one uppercase letter, one lowercase letter and one digit.");
            return;
        }

        if (formData.newPassword !== formData.newPasswordConfirm) {
            setErrorMessage("Passwords do not match.");
            return;
        }

        //send request to server to update password
        //server will require the token again to validate this request.
        axios.post(`${process.env.REACT_APP_API_URL}/api/user/reset-password`, formData, {
            headers: {
                'Content-Type': 'application/json', // Specify JSON content type
            },
        }).then(response => {
            
            if (response.status === 200) {
                setErrorMessage("");
                setStep(4);
                redirectCountDown(3)
            }

        }).catch(error => {
            setErrorMessage("Error, please try again.");
        });
    }

    const [redirectInSec, setRedirectInSec] = useState(3);
    function redirectCountDown(seconds) {
        if (seconds > 0) {
            setRedirectInSec(seconds);
            setTimeout(function() {
                redirectCountDown(seconds - 1); // Call the function recursively with decreased seconds
            }, 1000); // 1000 milliseconds = 1 second
        } else {
            navigate("/login")
        }
    }

    return (
        <section className={`${css["md:h-screen"]} ${css["py-36"]} flex items-center relative overflow-hidden ${css["zoom-image"]}`} >
            <div
                style={{ backgroundImage: "url('/images/bg/01.jpg')" }}
                className={`${css.absolute} ${css["inset-0"]} ${css["image-wrap"]} ${css["z-1"]} ${css["bg-no-repeat"]} ${css["bg-center"]} ${css["bg-cover"]}`} >
            </div>
            <div className={`${css.absolute} ${css["inset-0"]} ${css["bg-gradient-to-b"]} ${css["from-transparent"]} ${css["to-black"]} ${css["z-2"]} `}></div>

            <div className={`${css.container} ${css["z-3"]}`} >
                <div className="flex justify-center">
                    <div className="max-w-[400px] w-full m-auto p-6 bg-white rounded-md">
                        <img src="/images/logo-dark.png" className="mx-auto" alt="" width={64} height={64} />
                        <h5 className="my-6 text-xl font-semibold text-center">Reset Password</h5>
                        <form id="log-in-form" className="text-left" onSubmit={handleOnSubmit}>
                            <div className="grid grid-cols-1">
                                {step===1 && (<>
                                    <div className="mb-4">
                                        <label className="font-medium ltr:text-left" htmlFor="emailAddress">Email Address:</label>
                                        <input id="emailAddress" type="email" className={`${css["form-input"]} ${css["mt-3"]} mandatory`} placeholder="Your Email" onChange={handleChange} />
                                    </div>

                                    <div className="mb-4">
                                        <button type="submit" className={`${css.btn} btn-pw ${css["text-white"]} ${css["rounded-md"]} ${css["w-full"]}`}>Send Verification Code</button>
                                    </div>
                                </>
                                )}

                                {step===2 && (<>
                                    <div className="mb-4">
                                        <label className="font-medium ltr:text-left" htmlFor="verificationCodeEntered">Verification Code:</label>
                                        <input id="verificationCodeEntered" type="text" className={`${css["form-input"]} ${css["mt-3"]} mandatory`} placeholder="Verification Code" onChange={handleChange} />
                                    </div>

                                    <div className="mb-4">
                                        <button type="submit" className={`${css.btn} btn-pw ${css["text-white"]} ${css["rounded-md"]} ${css["w-full"]}`}>Verify</button>
                                    </div>
                                </>
                                )}

                                {step===3 && (<>
                                    <div className="mb-4">
                                        <label className="font-medium ltr:text-left" htmlFor="newPassword">New Password:</label>
                                        <input id="newPassword" type="password" className={`${css["form-input"]} ${css["mt-3"]} mandatory`} placeholder="New password" onChange={handleChange} />
                                    </div>

                                    <div className="mb-4">
                                        <label className="font-medium ltr:text-left" htmlFor="newPasswordConfirm">Confirm Password:</label>
                                        <input id="newPasswordConfirm" type="password" className={`${css["form-input"]} ${css["mt-3"]} mandatory`} placeholder="Confirm password" onChange={handleChange} />
                                    </div>

                                    <div className="mb-4">
                                        <button type="submit" className={`${css.btn} btn-pw ${css["text-white"]} ${css["rounded-md"]} ${css["w-full"]}`}>Confirm</button>
                                    </div>
                                </>
                                )}

                                {step===4 && (
                                    <div className="grid grid-cols-1 text-center text-success">
                                        <div className="mb-4">
                                            Password reset successfully. 
                                            <br/>
                                            Redirecting to login page in {redirectInSec} seconds ... 
                                        </div>
                                    </div>
                                )}
                                
                                <div className="grid grid-cols-1 text-center text-error">
                                    <div className="mb-4">
                                        <p id="error-message">{errorMessage}</p>
                                    </div>
                                </div>
                                

                                <div className="text-center">
                                    <span className="text-slate-400">Back to</span> <Link to="/login" className="link-pw font-bold">Login</Link>
                                </div>

                                <div className="text-center mt-2">
                                    <span className="text-slate-400 me-2">Dont have an account ?</span> <Link to="/signup" className="link-pw font-bold">Sign Up</Link>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );

}
