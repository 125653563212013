import React from "react";
import { downloadFile } from "../../functions/common/file-upload"

export default function DocumentContainer ( {title, files} ) {

    return <>
    {files.length>0 && (
    <div>
        <p>{title}</p>
        <ul className="space-y-3 text-sm">
            {files.map((thisfile, ndx) => (
                <li key={"file-" + ndx} onClick={() => {downloadFile(thisfile)}}  className="flex space-x-3 items-center cursor-pointer">
                    <i className="ri-attachment-2 text-lg text-orange-500"></i>
                    <span className="text-gray-800 link-pw">
                        {thisfile.name}
                    </span>
                </li>
            ))}
        </ul>
    </div>
    )}
    </>
}