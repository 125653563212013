import axios from "axios";

export async function getPresignedURL(file, download, previewOnly, jwtToken) {

    const data = {
        filename: file.name,
        s3Key: file.s3Key,
        previewOnly: previewOnly
    }

    if (jwtToken) {
        //this is used in the sign up process, we need a temp token to allow user to upload files
        axios.defaults.headers.common['Authorization'] = `Bearer ${jwtToken}`;
    }

    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/aws/s3/presigned-url/${download ? "download" : "upload"}`, data, {
            headers: {
                'Content-Type': 'application/json', // Specify JSON content type
            },
        })

        // Process the response data here
        // console.log(response.data);
        return response.data
    } catch (error) {
        // Handle any errors here
        console.error(error);
        return "";
    }
    // axios.post(`${process.env.REACT_APP_API_URL}/api/aws/s3/presigned-url/${download ? "download" : "upload"}`, data, {
    //     headers: {
    //         'Content-Type': 'application/json', // Specify JSON content type
    //     },
    // }).then(response => {
    //     console.log(response.data);
    //     return response.data; //signed url
    // }).catch(error => {
    //     return "";
    // })

}


export async function downloadFile(file){  
    // console.log(file)
    const previewOnly = true;
    let dataurl = await getPresignedURL(file, true, previewOnly);
    window.open(dataurl, '_blank');
}

export async function downloadFileToLocal(file){  
    // console.log(file)
    const previewOnly = false;
    let dataurl = await getPresignedURL(file, true, previewOnly);
    window.open(dataurl, '_blank');
}


export const uploadSingleFile = async (file) => {
    const presignedUrl = await getPresignedURL(file, false, false);

    const xhr = new XMLHttpRequest();
    const success = await new Promise((resolve) => {
        xhr.upload.addEventListener("progress", (event) => {
            if (event.lengthComputable) {
                // $("#uploading-file-name").html(file.name);
                // $("#upload-progress").html(((event.loaded / event.total) * 100).toFixed(0) + "%");
                // console.log("Uploading " + file.name + ", progress: " + ((event.loaded / event.total) * 100).toFixed(0) + "%")
            }
        });
        xhr.addEventListener("progress", (event) => {
            if (event.lengthComputable) {
                // console.log("download progress:", event.loaded / event.total);
                // $("#download-progress").html(((event.loaded / event.total) * 100).toFixed(0) + "%");
            }
        });
        xhr.addEventListener("loadend", () => {
            resolve(xhr.readyState === 4 && xhr.status === 200);
        });
        xhr.open("PUT", presignedUrl, true);
        // xhr.setRequestHeader("Content-Type", "multipart/form-data");
        xhr.setRequestHeader("Content-Type", file.type);
        // xhr.setRequestHeader("Content-Disposition", "inline; filename=\""+file.name+"\"");
        xhr.send(file);
    });

    return success;
}


export function isImage(file) {
    // Get the file's MIME type
    const mimeType = file.type;
  
    // Check if the MIME type starts with "image/"
    return mimeType.startsWith("image/");
  }