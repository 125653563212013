import React, { useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";

import css from "./Signature.module.css";

export default function SignaturePad( {onSignatureChange } ) {
  const signatureRef = useRef(null);
  const [isSignatureEmpty, setIsSignatureEmpty] = useState(true);

  // useEffect( () => {
  //   const canvas = signatureRef.current;
  // })

  const handleSignatureEnd = () => {
    const signatureData = signatureRef.current.toDataURL();
    if (!isSignatureEmpty) {
      onSignatureChange(signatureData);
    }
  };

  const clearSignature = () => {
    signatureRef.current.clear();
    setIsSignatureEmpty(true);
    onSignatureChange(null);
  };

  // const getSignatureData = () => {
  //   // Get the signature data in base64 format
  //   const signatureData = signatureRef.current.toDataURL();

  //   console.log(signatureRef.current);
  //   // You can now use the signatureData as needed (e.g., send it to a server)
  //   console.log(signatureData);

  //   if (!isSignatureEmpty) {
  //     onSignatureChange(signatureData);
  //   }
  // };

  const handleSignatureStart = () => {
    setIsSignatureEmpty(false);
  };

  return (
    <div className={ `${css["signature-pad-container"]}` }>
      <div className={ `${css["signature-pad"]}` }>
        <SignatureCanvas
          ref={signatureRef}
          penColor="black" // Customize the pen color
          canvasProps={{ className: "w-full", height: 300, style: {"cursor":"crosshair"} }} // Set the canvas size
          onBegin={handleSignatureStart}
          onEnd={handleSignatureEnd}
        />
      </div>
      <button type="button" className="link link-neutral text-sm" onClick={clearSignature}>Clear</button>
      {/* <button type="button" onClick={clearSignature}></button> */}
    </div>
  );
}
