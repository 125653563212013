import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "../components/Header";
import Footer from "../components/Footer";
import UserDetail from "../components/user/UserDetail";
import { useLocation } from "react-router-dom";

export default function Profile() {

    const emp = useLocation()

    // console.log(emp)

    const [user, setUser] = useState(null);

    useEffect(() => {
        // checkLogin();     
        document.title = "Profile"

        const fetchUserDetail = () => {

            axios.get(`${process.env.REACT_APP_API_URL}/api/user/profile`, {
                params: {
                    userId : emp?.state?.userId || 0,
                }
            }).then(response => {
                if (response.status === 200) {
                    setUser(response.data);
                }
            }).catch(error => {

            });
        }

        fetchUserDetail();

    }, [emp]);

    return (
        <div>

            <Header currentPage="home" />

            <div className="main-container">
                {user && (
                    <UserDetail user={user} readOnly={true}/>
                )}
                
            </div>

            <Footer />

        </div>
    )
}