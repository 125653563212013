import React, { useState, useEffect } from "react";
import axios from "axios";

import CreateUserAccount from "./CreateUserAccount";
import { downloadFile } from "../../functions/common/file-upload";
import { isLoginUserAdmin } from "../../functions/common/manage-login-user";
import Button from "../Button";
import Toast from "../Toast";

export default function UserDetail({ user, onReturnClick, readOnly }) {
  // console.log(user)

  const [idFiles, setIdFiles] = useState([]);

  const [realEstateLicFiles, setRealEstateLicFiles] = useState([]);

  useEffect(() => {
    const loadDocs = (folderName) => {
      const data = {
        folderName: folderName,
      };

      axios
        .get(`${process.env.REACT_APP_API_URL}/api/aws/s3/list-docs`, {
          params: data,
        })
        .then((response) => {
          if (response.status === 200) {
            const files = response.data;
            if (
              folderName === `service_provider_registry/user${user.id}/ids/`
            ) {
              //display ids
              // console.log("idfiles")
              setIdFiles(files);
            } else if (
              folderName ===
              `service_provider_registry/user${user.id}/real_estate_lic/`
            ) {
              setRealEstateLicFiles(files);
            }
          }
        })
        .catch((error) => {});
    };

    loadDocs(`service_provider_registry/user${user.id}/ids/`);
    loadDocs(`service_provider_registry/user${user.id}/real_estate_lic/`);
  }, [user]);

  const formatDateValue = (dateArr) => {
    if (dateArr == null || dateArr.length < 3) {
      return "";
    }

    return (
      dateArr[0] +
      "-" +
      (dateArr[1] < 10 ? "0" + dateArr[1] : dateArr[1]) +
      "-" +
      (dateArr[2] < 10 ? "0" + dateArr[2] : dateArr[2])
    );
  };

  // const handleChangeOffice = (e) => {
  //     //only admin can change this
  //     if (!isLoginUserAdmin()) {
  //         e.target.value = user.branchOffice
  //         return;
  //     }

  //     const confirmed = window.confirm('Are you sure to change the branch office for this user?');

  //     if (confirmed) {
  //         const { value } = e.target;

  //         axios.put(`${process.env.REACT_APP_API_URL}/api/user/change-office`, {
  //             id: user.id,
  //             branchOffice: value,
  //         })
  //             .then(response => {
  //                 if (response.status === 200) {
  //                     alert("success.")
  //                 }
  //             }).catch(error => {
  //                 alert("Error (code: 66).")
  //                 e.target.value = user.branchOffice
  //             });
  //     }
  // }

  const handleOnChange = (e) => {
    const { id, value } = e.target;
    // console.log(id + ", " + value);
    user[id] = value;
  };

  const handleUpdateUserDetail = () => {
    // console.log(user);

    axios
      .put(`${process.env.REACT_APP_API_URL}/api/user/update-details`, user)
      .then((response) => {
        if (response.status === 200) {
          showToast("success", "Update completed.");
        }
      })
      .catch((error) => {
        showToast("error", "Error (code: 66).");
      });
  };

  const [isToastVisible, setIsToastVisible] = useState(false);
  const [toastType, setToastType] = useState("normal");
  const [toastMessage, setToastMessage] = useState("");
  const showToast = (type, message) => {
    setIsToastVisible(true);
    setToastType(type);
    setToastMessage(message);

    let timer = 2000;
    if (type === "error") {
      timer = 4000;
    }

    // Optionally, you can set a timeout to hide the toast after a certain duration
    setTimeout(() => {
      setIsToastVisible(false);
    }, timer); // Hide the toast after x seconds (adjust as needed)
  };

  return (
    <div>
      <Toast
        isVisible={isToastVisible}
        type={toastType}
        message={toastMessage}
      />

      <div className="grid grid-cols-1 gap-4 p-5">
        {onReturnClick ? (
          <div className="flex justify-end">
            <button
              type="button"
              onClick={() => {
                onReturnClick();
              }}
              className="btn btn-sm btn-pw cursor-pointer font-medium px-5"
            >
              {" "}
              <i className="ri-arrow-left-s-line"></i> Return
            </button>
          </div>
        ) : (
          ""
        )}

        <div className={`static card w-fill bg-base-100 shadow-md mb-10`}>
          <div className="card-body">
            <div>
              <CreateUserAccount user={user} readOnly={readOnly} />
            </div>
          </div>
        </div>

        {/* <BasicInfo user={user} readOnly={readOnly} /> */}

        <div className="static card w-fill bg-base-100 shadow-md">
          <div className="card-body">
            <div className="text-pw text-lg font-semibold">
              <h3>PW Realty Group Service Provider Registry</h3>
            </div>
            <hr className="mb-4" />
            <div className="grid gap-4 grid-cols-1">
              <div>
                <label className="font-semibold	text-lg text-pw">
                  Personal Detail
                </label>
              </div>
            </div>
            <div className="grid gap-4 grid-cols-1 md:grid-cols-4">
              <div className="mb-4">
                <label
                  className="font-medium ltr:text-left"
                  htmlFor="firstName"
                >
                  First Name <span className="text-red-400">*</span>
                </label>
                <input
                  id="firstName"
                  type="text"
                  defaultValue={user.firstName}
                  className="py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500"
                  onChange={handleOnChange}
                />
              </div>

              <div className="mb-4">
                <label
                  className="font-medium ltr:text-left"
                  htmlFor="middleName"
                >
                  Middle Name{" "}
                </label>
                <input
                  id="middleName"
                  type="text"
                  defaultValue={user.middleName}
                  className="py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500"
                  onChange={handleOnChange}
                />
              </div>

              <div className="mb-4">
                <label className="font-medium ltr:text-left" htmlFor="lastName">
                  Last Name <span className="text-red-400">*</span>
                </label>
                <input
                  id="lastName"
                  type="text"
                  defaultValue={user.lastName}
                  className="py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500"
                  onChange={handleOnChange}
                />
              </div>

              <div className="mb-4">
                <label
                  className="font-medium ltr:text-left"
                  htmlFor="entityName"
                >
                  Entity Name
                </label>
                <input
                  id="entityName"
                  type="text"
                  defaultValue={user.entityName}
                  className="py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500"
                  onChange={handleOnChange}
                />
              </div>

              <div className="mb-4">
                <label
                  className="font-medium ltr:text-left"
                  htmlFor="contactEmail"
                >
                  Contact Email <span className="text-red-400">*</span>
                </label>
                <input
                  id="contactEmail"
                  type="text"
                  defaultValue={user.contactEmail}
                  className="py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500"
                  onChange={handleOnChange}
                />
              </div>

              <div className="mb-4">
                <label
                  className="font-medium ltr:text-left"
                  htmlFor="contactPhone"
                >
                  Contact Phone <span className="text-red-400">*</span>
                </label>
                <input
                  id="contactPhone"
                  type="text"
                  defaultValue={user.contactPhone}
                  className="py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500"
                  onChange={handleOnChange}
                />
              </div>
            </div>

            <div className="grid gap-4 grid-cols-1 md:grid-cols-4">
              <div className="mb-4">
                <label
                  className="font-medium ltr:text-left"
                  htmlFor="branchOffice"
                >
                  Branch Office <span className="text-red-400">*</span>
                </label>
                <select
                  id="branchOffice"
                  defaultValue={user.branchOffice}
                  onChange={handleOnChange}
                  className="py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500"
                >
                  <option value="" disabled>
                    -- Select --
                  </option>
                  <option value="PW Lindfield">PW Lindfield</option>
                  <option value="PW Norwest">PW Norwest</option>
                  <option value="PW Sydney">PW Sydney</option>
                </select>
              </div>

              <div className="mb-4">
                <label
                  className="font-medium ltr:text-left"
                  htmlFor="commenceDate"
                >
                  Commence Date <span className="text-red-400">*</span>
                </label>
                <input
                  id="commenceDate"
                  type="date"
                  defaultValue={formatDateValue(user.commenceDate)}
                  className="py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500"
                  onChange={handleOnChange}
                />
              </div>

              <div className="mb-4">
                <label
                  className="font-medium ltr:text-left"
                  htmlFor="agentType"
                >
                  Agent Type <span className="text-red-400">*</span>
                </label>
                <select
                  id="agentType"
                  className="py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500"
                  defaultValue={user.agentType}
                  onChange={handleOnChange}
                >
                  <option value="" disabled>
                    -- Select --{" "}
                  </option>
                  <option value="Introducer / Referral">
                    Introducer / Referral
                  </option>
                  <option value="Agent">Agent</option>
                </select>
              </div>

              <div className="mb-4">
                <label
                  className="font-medium ltr:text-left"
                  htmlFor="structure"
                >
                  Structure <span className="text-red-400">*</span>
                </label>
                <select
                  id="structure"
                  className="py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500"
                  defaultValue={user.structure}
                  onChange={handleOnChange}
                >
                  <option value="" disabled>
                    -- Select --{" "}
                  </option>
                  <option value="Sole Trader / Individual">
                    Sole Trader / Individual
                  </option>
                  <option value="Partnership">Partnership</option>
                  <option value="Company">Company</option>
                  <option value="Trust">Trust</option>
                </select>
              </div>

              <div className="mb-4">
                <label
                  className="font-medium ltr:text-left"
                  htmlFor="gstStatus"
                >
                  GST Status <span className="text-red-400">*</span>
                </label>
                <select
                  id="gstStatus"
                  className="py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500"
                  defaultValue={user.gstStatus}
                  onChange={handleOnChange}
                >
                  <option value="" disabled>
                    -- Select --{" "}
                  </option>
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </select>
                <p className="text-sm text-gray-400">
                  Whether have registered with GST
                </p>
              </div>

              <div className="mb-4">
                <label
                  className="font-medium ltr:text-left"
                  htmlFor="abnStatus"
                >
                  Active ABN <span className="text-red-400">*</span>
                </label>
                <select
                  id="abnStatus"
                  className="py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500"
                  defaultValue={user.abnStatus}
                  onChange={handleOnChange}
                >
                  <option value="" disabled>
                    -- Select --{" "}
                  </option>
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </select>
                <p className="text-sm text-gray-400">
                  Must answer "Yes" if you're Agent
                </p>
              </div>

              <div className="mb-4">
                <label className="font-medium ltr:text-left" htmlFor="abn">
                  ABN <span className="text-red-400">*</span>
                </label>
                <input
                  id="abn"
                  type="text"
                  maxLength={10}
                  defaultValue={user.abn}
                  className="py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500"
                  onChange={handleOnChange}
                />
              </div>

              <div className="mb-4">
                <label
                  className="font-medium ltr:text-left"
                  htmlFor="registeredName"
                >
                  Registered Name of ABN <span className="text-red-400">*</span>
                </label>
                <input
                  id="registeredName"
                  type="text"
                  maxLength={10}
                  defaultValue={user.registeredName}
                  className="py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500"
                  onChange={handleOnChange}
                />
              </div>
            </div>

            <div className="grid gap-4 grid-cols-1">
              <label className="font-medium ltr:text-left">
                Registered Address <span className="text-red-400">*</span>
              </label>
            </div>
            <div className="grid gap-4 grid-cols-1 md:grid-cols-5">
              <div className="md:col-span-2 mb-4">
                <input
                  id="address"
                  type="text"
                  className="py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500"
                  defaultValue={user.address}
                  onChange={handleOnChange}
                />
                <p className="text-sm text-gray-400">Unit & Street</p>
              </div>
              <div className="mb-4">
                <input
                  id="suburb"
                  type="text"
                  className="py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500"
                  defaultValue={user.suburb}
                  onChange={handleOnChange}
                />
                <p className="text-sm text-gray-400">Suburb</p>
              </div>
              <div className="mb-4">
                <select
                  id="state"
                  className="py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500"
                  defaultValue={user.state}
                  onChange={handleOnChange}
                >
                  <option value={"NSW"}>NSW</option>
                  <option value={"VIC"}>VIC</option>
                  <option value={"QLD"}>QLD</option>
                  <option value={"ACT"}>ACT</option>
                  <option value={"WA"}>WA</option>
                  <option value={"SA"}>SA</option>
                  <option value={"NT"}>NT</option>
                  <option value={"TAS"}>TAS</option>
                </select>
                <p className="text-sm text-gray-400">State</p>
              </div>
              <div className="mb-4">
                <input
                  id="postcode"
                  type="text"
                  className="py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500"
                  defaultValue={user.postcode}
                  onChange={handleOnChange}
                />
                <p className="text-sm text-gray-400">Postcode</p>
              </div>
            </div>

            <div className="grid gap-4 grid-cols-1">
              <div>
                Identification Document <span className="text-red-400">*</span>
              </div>
              {idFiles.length > 0 && (
                <div className="mb-4">
                  <ul className="space-y-3 text-sm">
                    {idFiles.map((thisfile, ndx) => (
                      <li
                        key={"id-file-" + ndx}
                        onClick={() => {
                          downloadFile(thisfile);
                        }}
                        className="flex space-x-3 items-center cursor-pointer"
                      >
                        <i className="ri-attachment-2 text-lg text-orange-500"></i>
                        <span className="text-gray-800 link-pw">
                          {thisfile.name}
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>

            <div className="grid gap-4 grid-cols-1">
              <div>
                <label className="font-semibold	text-lg text-pw">
                  Bank Account Detail
                </label>
              </div>
            </div>

            <div className="grid gap-4 grid-cols-1 md:grid-cols-4">
              <div className="mb-4">
                <label className="font-medium ltr:text-left" htmlFor="bankName">
                  Bank Name <span className="text-red-400">*</span>
                </label>
                <input
                  id="bankName"
                  defaultValue={user.bankName}
                  type="text"
                  className="py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500"
                  onChange={handleOnChange}
                />
              </div>

              <div className="mb-4">
                <label
                  className="font-medium ltr:text-left"
                  htmlFor="accountName"
                >
                  Account Name <span className="text-red-400">*</span>
                </label>
                <input
                  id="accountName"
                  defaultValue={user.accountName}
                  type="text"
                  className="py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500"
                  onChange={handleOnChange}
                />
              </div>

              <div className="mb-4">
                <label
                  className="font-medium ltr:text-left"
                  htmlFor="accountNumber"
                >
                  Account Number <span className="text-red-400">*</span>
                </label>
                <input
                  id="accountNumber"
                  defaultValue={user.accountNumber}
                  type="text"
                  className="py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500"
                  onChange={handleOnChange}
                />
              </div>

              <div className="mb-4">
                <label className="font-medium ltr:text-left" htmlFor="bsb">
                  BSB <span className="text-red-400">*</span>
                </label>
                <input
                  id="bsb"
                  type="text"
                  defaultValue={user.bsb}
                  className="py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500"
                  onChange={handleOnChange}
                />
              </div>
            </div>

            <div className="grid gap-4 grid-cols-1">
              <div>
                Real Estate License/Certificate of Registration on Fair Trading{" "}
                <span className="text-red-400">*</span>
              </div>
              {realEstateLicFiles.length > 0 && (
                <div className="mb-4">
                  <ul className="space-y-3 text-sm">
                    {realEstateLicFiles.map((thisfile, ndx) => (
                      <li
                        key={"real-estate-lic-file-" + ndx}
                        onClick={() => {
                          downloadFile(thisfile);
                        }}
                        className="flex space-x-3 items-center cursor-pointer"
                      >
                        <i className="ri-attachment-2 text-lg text-orange-500"></i>
                        <span className="text-gray-800 link-pw">
                          {thisfile.name}
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>

            <div className="grid gap-4 grid-cols-1">
              <div className="mb-4">
                Signature <span className="text-red-400">*</span>
                {user.signature && ( // Check if user.signature is not empty or null
                  <div className="bg-gray-100 p-5">
                    <img className="max-h-36" src={user.signature} alt="" />
                  </div>
                )}
              </div>
            </div>

            <div className="grid gap-4 grid-cols-1 md:grid-cols-4">
              <div className="mb-4">
                <label
                  className="font-medium ltr:text-left"
                  htmlFor="printName"
                >
                  Print Name <span className="text-red-400">*</span>
                </label>
                <input
                  id="printName"
                  defaultValue={user.printName}
                  type="text"
                  className="py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500"
                  onChange={handleOnChange}
                />
              </div>

              <div className="mb-4">
                <label
                  className="font-medium ltr:text-left"
                  htmlFor="registerDate"
                >
                  Date <span className="text-red-400">*</span>
                </label>
                <input
                  id="registerDate"
                  defaultValue={formatDateValue(user.registerDate)}
                  type="date"
                  className="py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500"
                  onChange={handleOnChange}
                />
              </div>
            </div>

            {isLoginUserAdmin() && (
              <div className="grid gap-4 grid-cols-1 md:grid-cols-4 mt-2">
                <div className="mb-4">
                  <Button
                    buttonText="Update"
                    onClick={handleUpdateUserDetail}
                    disabled={false}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
