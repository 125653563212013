import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Property from "../components/property/Property";
import PropertySearchFilter2 from "../components/property/PropertySearchFilter2";
import { fetchProperties } from "../functions/portfolio/resale";

export default function PortfolioResale() {

    const [properties, setProperties] = useState([]);

    useEffect(() => {
        // checkLogin();     
        document.title = "Portfolio"

        fetchProperties().then(fetchedData => {
            setProperties(fetchedData);
        }).catch(error => {
            console.log(error)
        })
        
    }, []);

    const itemsPerPage = 12; // Number of items to display per page
    const [currentPage, setCurrentPage] = useState(1);

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentProperties = properties.slice(startIndex, endIndex);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
        window.scrollTo(0, 0);
    };

    const updatePropertyResult = (searchedProperties) => {
        setProperties(searchedProperties)
    }

    return (
        <div>
            <Header currentPage="portfolio" />

            <div className="main-container">

                <div className="px-10 mt-10">
                    <PropertySearchFilter2 updatePropertyResult={updatePropertyResult} />

                    <div className="grid gap-10 grid-cols-1 mt-10 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">

                        {currentProperties.map((property, index) => (
                            <Property
                                key={index}
                                projectType={"resale"}
                                id={property.id}
                                imgSrc={property.imgSrc}
                                title={property.title}
                                address={property.address}
                                description={property.description}
                            />
                        ))}

                    </div>

                    {properties && properties.length > 0 && (
                        <div className="grid gap-10 grid-cols-1 mt-10">
                            <nav className="flex justify-center items-center space-x-2">
                                <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} className="text-gray-500 hover:text-blue-600 p-4 inline-flex items-center gap-2 rounded-md">
                                    <span aria-hidden="true">«</span>
                                    <span className="sr-only">Previous</span>
                                </button>
                                {Array.from({ length: Math.ceil(properties.length / itemsPerPage) }).map((_, index) => (
                                    <button
                                        key={index}
                                        className={`w-10 h-10 ${currentPage === index + 1
                                            ? "bg-orange-600 text-white"
                                            : "text-gray-500 hover:text-blue-600"
                                            } p-4 inline-flex items-center text-sm font-medium rounded-full`}
                                        onClick={() => handlePageChange(index + 1)}
                                    >
                                        {index + 1}
                                    </button>
                                ))}
                                <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === Math.ceil(properties.length / itemsPerPage)} className="text-gray-500 hover:text-blue-600 p-4 inline-flex items-center gap-2 rounded-md">
                                    <span className="sr-only">Next</span>
                                    <span aria-hidden="true">»</span>
                                </button>
                            </nav>
                        </div>
                    )}


                </div>


            </div>




            <Footer />

        </div>
    );
}