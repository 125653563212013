export const isStrongPassword = (password) => {
    // Minimum length requirement
    if (password.length < 8) {
      return false;
    }
  
    // Check for at least one uppercase letter
    if (!/[A-Z]/.test(password)) {
      return false;
    }
  
    // Check for at least one lowercase letter
    if (!/[a-z]/.test(password)) {
      return false;
    }
  
    // Check for at least one digit
    if (!/\d/.test(password)) {
      return false;
    }
  
    // Check for at least one special character
    // if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
    //   return false;
    // }
  
    // All checks passed, the password is strong
    return true;
}

export const formatPrice = (price) => {
    try {
        // Remove any existing commas or non-numeric characters
        price = price.toString().replace(/[^0-9.]/g, '').replace(/^0+/, '');

        // Split the price into integer and decimal parts
        const parts = price.split('.');
        let integerPart = parts[0] === '' ? '0' : parts[0];
        let decimalPart = parts[1] || '';

        // Add commas as thousands separators
        integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

        // Limit the decimal part to 2 decimal points
        decimalPart = decimalPart.slice(0, 2);

        // Join the integer and decimal parts with a dot and return the formatted price
        return price.includes(".") ? `${integerPart}.${decimalPart}` : `${integerPart}`;
    } catch (error) {
        // console.log(error);
        return price;
    }
}


export const formatDateValue = (dateArr) => {
    if (dateArr == null || dateArr.length !== 3) {
        return dateArr || "";
    }

    return dateArr[0] + "-" + (dateArr[1] < 10 ? ("0" + dateArr[1]) : dateArr[1]) + "-" + (dateArr[2] < 10 ? ("0" + dateArr[2]) : dateArr[2]);
}


export function isImage(file) {
    // Define a list of valid image MIME types
    const validImageTypes = ["image/jpeg", "image/png", "image/gif"];

    // Check if the file's MIME type is in the list of valid image types
    return validImageTypes.includes(file.type);
}