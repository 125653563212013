import React, { useEffect, useRef, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { isLoginUserSystemAdmin } from "../functions/common/manage-login-user";
import axios from "axios";
import Toast from "../components/Toast";
import Button from "../components/Button";


export default function SysConfig() {

    const isSystemAdmin = isLoginUserSystemAdmin();

    const [sysConfig, setSysConfig] = useState({
        testMode: '',
        canEmail: '',
        testEmailAddress: '',
        companyName: '',
    })


    useEffect(() => {
        // checkLogin();  

        document.title = "Config"

        const fetchSysConfig = () => {
            axios.get(`${process.env.REACT_APP_API_URL}/api/config/get`)
                .then(response => {
                    if (response.status === 200) {
                        // console.log(response.data)
                        setSysConfig(response.data);
                        textareaRef.current.value = response.data.emailTemplate;
                    }
                }).catch(error => {

                });
        }

        fetchSysConfig();

    }, []);

    const handleChange = (e) => {
        const {id, value} = e.target;

        setSysConfig({
            ...sysConfig,
            [id]: value,
        })
    }


    const [isToastVisible, setIsToastVisible] = useState(false);
    const [toastType, setToastType] = useState("normal");
    const [toastMessage, setToastMessage] = useState("");

    const showToast = (type, message) => {
        setIsToastVisible(true);
        setToastType(type);
        setToastMessage(message);

        // Optionally, you can set a timeout to hide the toast after a certain duration
        setTimeout(() => {
            setIsToastVisible(false);
        }, 2000); // Hide the toast after 3 seconds (adjust as needed)
    };

    const handleSaveConfig = () => {
        const data = {
            ...sysConfig,
            "emailTemplate": textareaRef.current.value,
        }

        // console.log(data);

        axios.post(`${process.env.REACT_APP_API_URL}/api/config/update`, data, {
            headers: {
                'Content-Type': 'application/json', // Specify JSON content type
            },
        }).then(response => {
            if (response.status === 200) {
                showToast("success", "Success")
            }
        }).catch(error => { });
    }


    const textareaRef = useRef(null);

    const [preview, setPreview] = useState(false);

    return (
        <div>
            <Header currentPage="home" />

            <Toast isVisible={isToastVisible} type={toastType} message={toastMessage} />

            {isSystemAdmin &&
                <div className="main-container">
                    <div className="grid grid-cols-1 gap-4 p-5">
                        <div className="static card w-fill bg-base-100 shadow-md mb-10">
                            <div className="card-body">
                                <div className="grid gap-4 grid-cols-4">
                                    <div className="mb-4">
                                        <label className="font-medium ltr:text-left" htmlFor="testMode">Test Mode</label>
                                        <select id="testMode" value={sysConfig.testMode+""} onChange={handleChange} className='py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500'  >
                                            <option value="true">Yes</option>
                                            <option value="false">No</option>
                                        </select>
                                    </div>
                                    <div className="mb-4">
                                        <label className="font-medium ltr:text-left" htmlFor="canEmail">Can Email</label>
                                        <select id="canEmail" value={sysConfig.canEmail+""} onChange={handleChange} className='py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500'  >
                                            <option value="true">Yes</option>
                                            <option value="false">No</option>
                                        </select>
                                    </div>
                                    <div className="mb-4">
                                        <label className="font-medium ltr:text-left" htmlFor="testEmailAddress">Test Email Address</label>
                                        <input id="testEmailAddress" type="text" value={sysConfig.testEmailAddress} className='py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500' onChange={handleChange} />
                                    </div>
                                    <div className="mb-4">
                                        <label className="font-medium ltr:text-left" htmlFor="companyName">Company Name</label>
                                        <input id="companyName" type="text" value={sysConfig.companyName} className='py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500' onChange={handleChange} />
                                    </div>
                                </div>
                                <div className="grid gap-4 grid-cols-1 mt-5">
                                    <div>
                                        <Button buttonText={"Save"} onClick={handleSaveConfig}></Button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="static card w-fill bg-base-100 shadow-md">
                            <div className="card-body">
                                <div className="grid gap-4 grid-cols-1">
                                    <div className="mb-4">
                                        <label className="font-medium ltr:text-left">Email Template</label> <span className="link-pw ml-5" onClick={() => { setPreview(true) }}>Preview</span>
                                        <textarea className={`py-3 px-4 block w-full h-96 border border-solid border-gray-300 rounded-md text-sm focus:border-orange-500`} ref={textareaRef}></textarea>
                                    </div>
                                </div>
                                <div className="grid gap-4 grid-cols-1 mt-5">
                                    <div>
                                        <Button buttonText={"Save"} onClick={handleSaveConfig}></Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }

            {preview && (
                <>
                    <div dangerouslySetInnerHTML={{ __html: textareaRef.current.value }}></div>
                </>
            )}
            <Footer />

        </div>
    );
}