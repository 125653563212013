import React from "react"
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

import { isLoginUserAdmin } from "../functions/common/manage-login-user";

export default function Header({ currentPage }) {

    const navigate = useNavigate();

    const isAdmin = isLoginUserAdmin();

    const logout = () => {
        // Clear the authentication token and other user-related data from localStorage
        localStorage.clear();

        // Redirect the user to the login page (you can use React Router for this)
        // Example: Replace '/login' with the actual login page URL or route
        navigate('/login');
    };

    return (
        <header className="fixed top-0 flex flex-wrap drop-shadow-md sm:justify-start sm:flex-nowrap z-50 w-full bg-white border-b border-gray-200 text-sm py-3 sm:py-0">
            <nav className="relative w-full px-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8" aria-label="Global">
                <div className="flex items-center justify-between">
                    <Link className="flex-none text-xl font-semibold" to="/home" >
                        <img src="/images/logo-dark.png" className="mx-auto" alt="" width={64} height={64} />
                    </Link>
                    <div className="sm:hidden">
                        <button type="button" className="hs-collapse-toggle p-2 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-offset-2 focus:ring-offset-white focus:ring-fff transition-all text-sm" data-hs-collapse="#navbar-collapse-with-animation" aria-controls="navbar-collapse-with-animation" aria-label="Toggle navigation">
                            <svg className="hs-collapse-open:hidden w-4 h-4" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
                            </svg>
                            <svg className="hs-collapse-open:block hidden w-4 h-4" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                            </svg>
                        </button>
                    </div>
                </div>
                <div id="navbar-collapse-with-animation" className="hs-collapse hidden overflow-hidden transition-all duration-300 basis-full grow sm:block">
                    <div className="flex flex-col mx-10 gap-y-4 gap-x-0 mt-5 sm:flex-row sm:items-center sm:gap-y-0 sm:gap-x-7 sm:mt-0 sm:pl-7">
                        <Link to="/home" className={`font-medium sm:py-6 ${currentPage === "home" ? "text-pw text-white" : "text-gray-500 hover:text-gray-400"}`}>Home</Link>

                        {isAdmin ? (
                            <Link to="/user" className={`font-medium sm:py-6 ${currentPage === "user" ? "text-pw text-white" : "text-gray-500 hover:text-gray-400"}`}>User</Link>
                        ) : ("")}

                        <div className="hs-dropdown [--strategy:static] md:[--strategy:fixed] [--adaptive:none] md:[--trigger:hover] md:py-4">
                            <button type="button" className={`flex items-center w-full hover:text-gray-400 font-medium ${currentPage === "portfolio" ? "text-pw text-white" : "text-gray-500 hover:text-gray-400"} `}>
                                Portfolio
                                <svg className={`ml-2 w-2.5 h-2.5 ${currentPage === "portfolio" ? "text-pw text-white" : "text-gray-500 hover:text-gray-400"} `} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5" stroke="currentColor" strokeWidth="2" strokeLinecap="round"></path>
                                </svg>
                            </button>

                            <div className="hs-dropdown-menu transition-[opacity,margin] duration-[0.1ms] md:duration-[150ms] hs-dropdown-open:opacity-100 opacity-0 md:w-48 hidden z-10 bg-white md:shadow-md rounded-lg p-2 before:absolute top-full md:border before:-top-5 before:left-0 before:w-full before:h-5">

                                <Link to="/portfolio/otp" className={`flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100`}>Off The Plan</Link>

                                <Link to="/portfolio/resale" className={`flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100`}>Resale</Link>
                            </div>
                        </div>

                        <div className="hs-dropdown [--strategy:static] md:[--strategy:fixed] [--adaptive:none] md:[--trigger:hover] md:py-4">
                            <button type="button" className={`flex items-center w-full hover:text-gray-400 font-medium  ${currentPage === "workflow" ? "text-pw text-white" : "text-gray-500 hover:text-gray-400"} `}>
                                Workflow
                                <svg className={`ml-2 w-2.5 h-2.5 ${currentPage === "workflow" ? "text-pw text-white" : "text-gray-500 hover:text-gray-400"} `} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5" stroke="currentColor" strokeWidth="2" strokeLinecap="round"></path>
                                </svg>
                            </button>

                            <div className="hs-dropdown-menu transition-[opacity,margin] duration-[0.1ms] md:duration-[150ms] hs-dropdown-open:opacity-100 opacity-0 md:w-48 hidden z-10 bg-white md:shadow-md rounded-lg p-2 before:absolute top-full md:border before:-top-5 before:left-0 before:w-full before:h-5">
                                <Link to="/workflow/otp" className={`flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100`}>
                                    Off The Plan
                                </Link>
                                <Link to="/workflow/resale" className={`flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100`}>
                                    Resale
                                </Link>
                            </div>
                        </div>

                        <div className="hs-dropdown [--strategy:static] md:[--strategy:fixed] [--adaptive:none] md:[--trigger:hover] md:py-4">
                            <button type="button" className={`flex items-center w-full hover:text-gray-400 font-medium  ${currentPage === "commission" ? "text-pw text-white" : "text-gray-500 hover:text-gray-400"} `}>
                                Commission
                                <svg className={`ml-2 w-2.5 h-2.5 ${currentPage === "commission" ? "text-pw text-white" : "text-gray-500 hover:text-gray-400"} `} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5" stroke="currentColor" strokeWidth="2" strokeLinecap="round"></path>
                                </svg>
                            </button>

                            <div className="hs-dropdown-menu transition-[opacity,margin] duration-[0.1ms] md:duration-[150ms] hs-dropdown-open:opacity-100 opacity-0 md:w-48 hidden z-10 bg-white md:shadow-md rounded-lg p-2 before:absolute top-full md:border before:-top-5 before:left-0 before:w-full before:h-5">
                                <Link to="/commission/otp" className={`flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100`}>
                                    Off The Plan
                                </Link>
                                <Link to="/commission/resale" className={`flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100`}>
                                    Resale
                                </Link>
                            </div>
                        </div>

                        {isAdmin && (
                            <div className="hs-dropdown [--strategy:static] md:[--strategy:fixed] [--adaptive:none] md:[--trigger:hover] md:py-4">
                                <button type="button" className={`flex items-center w-full hover:text-gray-400 font-medium  ${currentPage === "report" ? "text-pw text-white" : "text-gray-500 hover:text-gray-400"} `}>
                                    Report
                                    <svg className={`ml-2 w-2.5 h-2.5 ${currentPage === "report" ? "text-pw text-white" : "text-gray-500 hover:text-gray-400"} `} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5" stroke="currentColor" strokeWidth="2" strokeLinecap="round"></path>
                                    </svg>
                                </button>

                                <div className="hs-dropdown-menu transition-[opacity,margin] duration-[0.1ms] md:duration-[150ms] hs-dropdown-open:opacity-100 opacity-0 md:w-48 hidden z-10 bg-white md:shadow-md rounded-lg p-2 before:absolute top-full md:border before:-top-5 before:left-0 before:w-full before:h-5">
                                    <Link to="/report/workflow-export" className={`flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100`}>
                                        Workflow Data Export
                                    </Link>
                                </div>
                            </div>
                        )}

                    </div>
                </div>


                <div className="flex items-center gap-x-2 hidden sm:ml-auto sm:block">
                    <div className="hs-dropdown relative inline-flex [--placement:bottom-right]">
                        <button type="button" id="hs-dropdown" className="hs-dropdown-toggle flex items-center w-full text-gray-500 hover:text-gray-400 font-medium ">
                            <div className="relative inline-block">
                                <img className="bg-gray-200 inline-block h-[2rem] w-[2rem] rounded-full ring-2 ring-white" src="/images/user-line.svg" alt="" />
                                <span className="absolute bottom-0 right-0 block h-2 w-2 rounded-full ring-2 ring-white bg-green-400"></span>
                            </div>
                        </button>

                        <div className="hs-dropdown-menu transition-[opacity,margin] duration-[0.1ms] sm:duration-[150ms] hs-dropdown-open:opacity-100 opacity-0 sm:w-48 hidden z-10 bg-white sm:shadow-md rounded-lg p-2 before:absolute top-full sm:border before:-top-5 before:left-0 before:w-full before:h-
                        5">
                            <ul className="menu">
                                <li><Link to="/profile">Profile</Link></li>
                                <li><Link to="/update-password">Update Password</Link></li>
                                {isAdmin && (<li><Link to="/portfolio/create">Create Projects</Link></li>)}
                                <hr className="my-2" />
                                <li className="border-t-1"><button onClick={logout}>Log Out</button></li>
                            </ul>

                        </div>
                    </div>
                </div>

            </nav>

        </header>
    )
}