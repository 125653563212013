import axios from 'axios';


const validateToken = async () => {
    try {
        const jwtToken = localStorage.getItem('token'); // Adjust the storage location
        axios.defaults.headers.common['Authorization'] = `Bearer ${jwtToken}`;
        
        // console.log("jwtToken " + jwtToken)
        const apiUrl = process.env.REACT_APP_API_URL; //'http://localhost:8080';
        
        const response = await axios.post(`${apiUrl}/api/user/validate-token`);

        // console.log(response);

        if(response?.data) {
            localStorage.setItem('role', response.data);
            return true
        }
        
    } catch (error) {
        // console.log(error);
    }
    return false;
};

export {validateToken};