import React, { useState, useEffect } from "react";
import { Collapse, Descriptions } from "antd";
import { formatDateValue, formatPrice } from "../../functions/common/util";
import axios from "axios";
import { downloadFile } from "../../functions/common/file-upload";


export default function ResaleCompleted ( {workflow} ) {

    const [referralFilesUploaded, setReferralFilesUploaded] = useState([]);
    const [rebateFilesUploaded, setRebateFilesUploaded] = useState([]);

    useEffect(() => {
        const fetchWorkflowDocs = (id) => {
            if (!id) {
                return;
            }

            axios.get(`${process.env.REACT_APP_API_URL}/api/workflow/resale/fetch-docs`,
            {
                params: {
                    workflowId: id,
                }, // Pass the search data as query parameters
            }).then(response => {
                // console.log(response)
                if (response.status === 200) {
                    const files = response.data;
                    files.forEach((file) => {
                        if(file.fileType === "rebate agreement"){
                            setRebateFilesUploaded(prevData => [
                                ...prevData,
                                {
                                    name: file.filename,
                                    s3Key: file.s3Key
                                }
                            ])

                        } else if(file.fileType === "referral agreement"){
                            setReferralFilesUploaded(prevData => [
                                ...prevData,
                                {
                                    name: file.filename,
                                    s3Key: file.s3Key
                                }
                            ])
                        }
                        
                    })
                }
            }).catch(error => {
                console.log(error)
            });
        }

        fetchWorkflowDocs(workflow?.id);
    }, [workflow?.id])

    const sellingInfo = [
        {
            label: 'Vendor',
            children: workflow.vendor,
        },
        {
            label: 'Marketing Fee',
            children: `$${formatPrice(workflow.marketingFee)}`,
        },
        {
            label: 'Listing Referrer',
            children: workflow.listingReferrer,
        },
        {
            label: <div>Listing Referral<br/>Commission Rate</div>,
            children: workflow.listingReferrer===""?"":(workflow.listingReferrerCommRate+"%"),
        },
        {
            label: 'Selling Agent',
            children: workflow.sellingAgentName,
        },
        {
            label: 'Listing Agent',
            children: workflow.listingAgentName,
        },
        {
            label: 'Commission Rate',
            children: `${workflow.commissionRate}%`,
        },
        {
            label: 'Other Expense',
            children: `$${formatPrice(workflow.expense)}`,
        },
        {
            label: 'Address',
            children: `${workflow.property.address} ${workflow.property.suburb}, ${workflow.property.state} ${workflow.property.postcode}`,
        },
    ];


    const exchangeDetails = [
        {
            label: 'Buyer',
            children: `${workflow.buyerName}`,
        },
        {
            label: 'Contract Price',
            children: `$${formatPrice(workflow.contractPrice)}`,
        },
        {
            label: 'Exchange Date',
            children: `${formatDateValue(workflow.exchangeDate)}`,
        },
        {
            label: 'Est. Settlement Date',
            children: `${formatDateValue(workflow.estSettlementDate)}`,
        },
        {
            label: 'Customer Rebate',
            children: `$${formatPrice(workflow.customerRebate)}`,
        },
        {
            label: 'Approved By',
            children: `${workflow.approvedByUserName}`,
        },
    ];


    return <>
    <div className="static card w-fill bg-base-100 shadow-md">
            <div className="card-body">

                <Collapse activeKey={"1"} items={[{
                    key: '1',
                    label: 'Selling Information',
                    children:
                    <>
                    <Descriptions
                        bordered
                        column={{ xs: 1, sm: 2, md: 2, lg: 2, xl: 2, xxl: 2 }}
                        items={sellingInfo}
                        size="small"
                    />
                    {referralFilesUploaded.length > 0 && (
                        <div className="mt-4 ml-4">
                            <label>Referral Agreement:</label>
                            <ul className="space-y-3 text-sm">
                                {referralFilesUploaded.map((thisfile, ndx) => (
                                    <li key={"id-file-" + ndx} onClick={() => { downloadFile(thisfile) }} className="flex space-x-3 items-center cursor-pointer">
                                        <i className="ri-attachment-2 text-lg text-pw"></i>
                                        <span className="text-gray-800 link-pw">
                                            {thisfile.name}
                                        </span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                    </>
                }]} />


                <Collapse activeKey="1" items={[{
                    key: '1',
                    label: 'Exchange Details',
                    children:
                    <>
                    <Descriptions
                        bordered
                        column={{ xs: 1, sm: 2, md: 2, lg: 2, xl: 2, xxl: 2 }}
                        items={exchangeDetails}
                        size="small"
                    />
                    {rebateFilesUploaded.length > 0 && (
                        <div className="mt-4 ml-4">
                            <label>Customer Rebate Agreement:</label>
                            <ul className="space-y-3 text-sm">
                                {rebateFilesUploaded.map((thisfile, ndx) => (
                                    <li key={"id-file-" + ndx} onClick={() => { downloadFile(thisfile) }} className="flex space-x-3 items-center cursor-pointer">
                                        <i className="ri-attachment-2 text-lg text-pw"></i>
                                        <span className="text-gray-800 link-pw">
                                            {thisfile.name}
                                        </span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                    </>,
                }]} />


            </div>
        </div>
    </>
}