import React, { useEffect, useState } from "react";
import axios from 'axios';

import css from './loginpage.module.css';
import Button from "../components/Button";
import SignaturePad from "../components/SignaturePad";
import Dropzone from "../components/Dropzone";
import { validateForm } from "../functions/form/form-validation"
import { getPresignedURL } from "../functions/common/file-upload"
import { ConfigProvider, Checkbox } from 'antd';


export default function SignupPage() {

    useEffect(() => {
        // Set the title for this page
        document.title = 'Sign up - PW Realty';

        const fetchManagerAndPartners = () => {
            axios.get(`${process.env.REACT_APP_API_URL}/api/user/managers-and-partners`)
                .then(response => {
                    if (response.status === 200) {
                        //login success redirect to dashboard
                        if (response.data != null) {
                            // console.log(response)
                            setManagers(response.data);
                        }

                    }
                })
                .catch(error => { });
        }

        fetchManagerAndPartners();

    }, []);

    const [managers, setManagers] = useState([]);

    const [defaultDate] = useState(getTodayDate());

    // Function to get today's date in the format yyyy-mm-dd
    function getTodayDate() {
        const today = new Date();
        const year = today.getFullYear();
        let month = today.getMonth() + 1;
        let day = today.getDate();

        // Add leading zero if month or day is less than 10
        if (month < 10) {
        month = `0${month}`;
        }
        if (day < 10) {
        day = `0${day}`;
        }

        return `${year}-${month}-${day}`;
    }

    const [formData, setFormData] = useState({
        branchOffice: "",
        commenceDate: defaultDate,
        agentType: "",
        structure: "",
        abnStatus: "",
        abn: "",
        registeredName: "",
        address: "",
        suburb: "",
        state: "",
        postcode: "",
        firstName: "",
        middleName: "",
        lastName: "",
        entityName: "",
        gstStatus: "",
        contactEmail: "",
        contactPhone: "",
        bankName: "",
        accountName: "",
        accountNumber: "",
        bsb: "",
        printName: "",
        reportingManager: "",
        role: "",
        registerDate: defaultDate,
    });
    const [declarationAccept, setDeclarationAccept] = useState(false);
    const [declaration2Accept, setDeclaration2Accept] = useState(false);
    const [tcAccept, setTcAccept] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [uploadingMessage, setUploadingMessage] = useState("");
    const [isSuccess, setIsSuccess] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isAgent, setIsAgent] = useState(false);
    const [isPartner, setIsPartner] = useState(false);

    const checkboxHandler = (e) => {
        if (e.target.id === "declaration-checkbox") {
            setDeclarationAccept(e.target.checked);
        } else if (e.target.id === "tc-checkbox") {
            setTcAccept(e.target.checked);
        } else if (e.target.id === "declaration-2-checkbox") {
            setDeclaration2Accept(e.target.checked);
        }
    };

    const handleChange = (e) => {
        const { id, value } = e.target;

        if(id === "agentType") {
            setIsAgent(value === "Agent");
        } else if (id === "role") {
            setIsPartner(value === "Partner")
        }

        setFormData({
            ...formData,
            [id]: value,
        });
    };

    const [signature, setSignature] = useState(null);
    const handleSignatureChange = (signatureData) => {
        setSignature(signatureData);
    };

    const handleOnClick = async () => {
        // console.log(formData);
        // console.log(signature);
        let valid = validateForm("sign-up-form", formData);

        // valid = true;

        if (!valid) {
            setErrorMessage("Please fill in all mandatory fields.");
            return;
        }

        //check id files here
        if (idFiles.length === 0) {
            setErrorMessage("Please upload your identification document.")
            return;
        }

        if (!declarationAccept || !declaration2Accept) {
            setErrorMessage("Please accept all the declarations.")
            return;
        }

        //check real estate license file uploads here
        if (realEstateLicFiles.length === 0) {
            setErrorMessage("Please upload \"Real Estate License/Certificate of Registration on Fair Trading\" ")
            return;
        }


        if (!tcAccept) {
            setErrorMessage("Please accept the \"Recipient Created Tax Invoice (RCTI) Agreement\".")
            return;
        }

        if (signature == null) {
            setErrorMessage("Please sign your name on the signature pad.")
            return;
        } else {
            formData["signature"] = signature;
        }


        setErrorMessage(""); //parse validation. send ajax request

        setIsSubmitting(true);

        await registerUser(formData);

        setIsSubmitting(false);
    }


    const apiUrl = process.env.REACT_APP_API_URL; //'http://localhost:8080';
    const registerUser = async (data) => {
        try {
            const response = await axios.post(`${apiUrl}/api/user/signup`, data, {
                headers: {
                    'Content-Type': 'application/json', // Specify JSON content type
                },
            });
            // console.log(response);

            if (response.status === 200) {
                //user has been saved. now save the IDs and other files along with the userId
                const user = response.data.user;
                // console.log(response.data);
                // console.log("user", user);
                const jwtToken = response.data.token;
                if (user.id) {
                    //upload files
                    // setIsSuccess(true);
                    await saveDocs(user.id, jwtToken);
                }
                // setIsSuccess(true);
            }

            //return response.data;
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                console.log("HTTP Status Code:", error.response.status);
                console.log("Error Message:", error.response.data);
            } else if (error.request) {
                // The request was made but no response was received
                console.log("Request made, but no response received:", error.request);
            } else {
                // Something happened in setting up the request
                console.log("Error setting up the request:", error.message);
            }
        }
    };

    const [idFiles, setIdFiles] = useState([]);
    const [realEstateLicFiles, setRealEstateLicFiles] = useState([]);

    const handleFileUpload = (dropzoneId, files) => {
        if (dropzoneId === "id-uploader") {
            setIdFiles(files);
        } else if (dropzoneId === "real-estate-license-uploader") {
            setRealEstateLicFiles(files);
        }
    };


    let filesCnt = 0;
    let filesUploadedCnt = 0;
    const saveDocs = async (userId, jwtToken) => {
        let s3Files = [];

        idFiles.forEach(file => {
            file.s3Key = `service_provider_registry/user${userId}/ids/${file.name}`;
            s3Files.push(file);
        })

        realEstateLicFiles.forEach(file => {
            file.s3Key = `service_provider_registry/user${userId}/real_estate_lic/${file.name}`;
            s3Files.push(file);
        })


        filesCnt = s3Files.length;
        filesUploadedCnt = 0;

        if (filesCnt > 0) {
            for (var i = 0; i < s3Files.length; i++) {
                const uploadResult = await uploadSingleFile(s3Files[i], jwtToken);
                console.log("uploadResult = " + uploadResult);
                filesUploadedCnt++;
            }
        }

        if (filesUploadedCnt === filesCnt) {
            console.log("upload completed. " + filesCnt + " files uploaded.");
            setIsSuccess(true);
        }

    }

    const uploadSingleFile = async (file, jwtToken) => {
        const presignedUrl = await getPresignedURL(file, false, false, jwtToken);

        // console.log("presignedUrl " + presignedUrl)

        const xhr = new XMLHttpRequest();
        const success = await new Promise((resolve) => {
            xhr.upload.addEventListener("progress", (event) => {
                if (event.lengthComputable) {
                    console.log("upload progress:", event.loaded / event.total);
                    // $("#uploading-file-name").html(file.name);
                    // $("#upload-progress").html(((event.loaded / event.total) * 100).toFixed(0) + "%");
                    setUploadingMessage("Uploading " + file.name + ", progress: " + ((event.loaded / event.total) * 100).toFixed(0) + "%")
                }
            });
            xhr.addEventListener("progress", (event) => {
                if (event.lengthComputable) {
                    // console.log("download progress:", event.loaded / event.total);
                    // $("#download-progress").html(((event.loaded / event.total) * 100).toFixed(0) + "%");
                }
            });
            xhr.addEventListener("loadend", () => {
                resolve(xhr.readyState === 4 && xhr.status === 200);
            });
            xhr.open("PUT", presignedUrl, true);
            // xhr.setRequestHeader("Content-Type", "multipart/form-data");
            xhr.setRequestHeader("Content-Type", file.type);
            // xhr.setRequestHeader("Content-Disposition", "inline; filename=\""+file.name+"\"");
            xhr.send(file);
        });

        return success;
    }

    if (isSuccess) {
        return (
            <div>
                <section className="md:h-screen md:py-36 flex items-center relative overflow-hidden zoom-image">
                    <div
                        style={{ backgroundImage: "url('/images/bg/01.jpg')" }}
                        className={`${css.absolute} ${css["inset-0"]} ${css["image-wrap"]} ${css["z-1"]} ${css["bg-no-repeat"]} ${css["bg-center"]} ${css["bg-cover"]}`} >
                    </div>
                    <div className={`${css.absolute} ${css["inset-0"]} ${css["bg-gradient-to-b"]} ${css["from-transparent"]} ${css["to-black"]} ${css["z-1"]} `}></div>
                    <div className={`container ${css["z-3"]}`} style={{ marginLeft: "auto", marginRight: "auto" }}>
                        {/* ... (your existing code) */}
                        <div className="flex justify-center">
                            <div className="max-w-[1000px] m-auto pt-10 pb-10 pl-8 pr-8 md:pl-20 md:pr-20 bg-white shadow-md rounded-md" style={{ maxHeight: "100vh", overflowY: "auto" }}>
                                <img src="/images/logo-dark.png" className="mx-auto" alt="" width={100} />
                                <h5 className="my-6 text-xl font-semibold text-center">Thank you for joining us!</h5>
                                <p className="mt-10">You have successfully submitted the sign up form. </p>
                                <p>Your sign-up request will be reviewed by our admin team before your account is created.</p>

                                <p className="mt-10">You can close this window now.</p>
                            </div>
                        </div>

                        {/* <p>Please allow some time for the review process. You will receive an email notification once your account is approved.</p> */}
                    </div>
                    {/* ... (the rest of your form JSX) */}
                </section>
            </div>
        )
    }


    return (
        <section className="md:h-screen md:py-36 flex items-center relative overflow-hidden zoom-image">
            <div
                style={{ backgroundImage: "url('/images/bg/01.jpg')" }}
                className={`${css.absolute} ${css["inset-0"]} ${css["image-wrap"]} ${css["z-1"]} ${css["bg-no-repeat"]} ${css["bg-center"]} ${css["bg-cover"]}`} >
            </div>
            <div className={`${css.absolute} ${css["inset-0"]} ${css["bg-gradient-to-b"]} ${css["from-transparent"]} ${css["to-black"]} ${css["z-1"]} `}></div>
            <div className={`container ${css["z-3"]}`} style={{ marginLeft: "auto", marginRight: "auto" }}>
                <div className="flex justify-center">
                    <div className="max-w-[1000px] m-auto pt-10 pb-10 pl-8 pr-8 md:pl-20 md:pr-20 bg-white shadow-md rounded-md" style={{ maxHeight: "100vh", overflowY: "auto" }}>
                        <img src="/images/logo-dark.png" className="mx-auto" alt="" width={100} />
                        <h5 className="my-6 text-xl font-semibold text-center">PW Realty Group Service Provider Registry</h5>
                        <form className="text-left" id="sign-up-form">

                            <div className="text-pw text-lg font-semibold">
                                <h3>Personal Detail</h3>
                            </div>
                            <hr className="mb-4" />

                            <div className="grid gap-4 grid-cols-1 md:grid-cols-3">
                                <div className="mb-4">
                                    <label className="font-medium ltr:text-left" htmlFor="branchOffice">Branch Office <span className="text-red-400">*</span></label>
                                    <select id="branchOffice" defaultValue={""} className={`${css["form-input"]} mandatory`} onChange={handleChange}  >
                                        <option value="" disabled>-- Select --</option>
                                        <option value="PW Lindfield">PW Lindfield</option>
                                        <option value="PW Norwest">PW Norwest</option>
                                        <option value="PW Sydney">PW Sydney</option>
                                    </select>
                                </div>

                                <div className="mb-4">
                                    <label className="font-medium ltr:text-left" htmlFor="commenceDate">Commence Date <span className="text-red-400">*</span></label>
                                    <input id="commenceDate" type="date" className={`${css["form-input"]} mandatory`} onChange={handleChange} defaultValue={defaultDate}/>
                                </div>
                            </div>


                            <div className="grid gap-4 grid-cols-1 md:grid-cols-3">
                                <div className="mb-4">
                                    <label className="font-medium ltr:text-left" htmlFor="agentType">Agent Type <span className="text-red-400">*</span></label>
                                    <select id="agentType" className={`${css["form-input"]} mandatory`} defaultValue={""} onChange={handleChange} >
                                        <option value="" disabled>-- Select -- </option>
                                        <option value="Introducer / Referral">Introducer / Referral</option>
                                        <option value="Agent">Agent</option>
                                    </select>
                                </div>

                                {isAgent && (
                                    <div className="mb-4">
                                        <label className="font-medium ltr:text-left" htmlFor="role">Role <span className="text-red-400">*</span></label>
                                        <select id="role" className={`${css["form-input"]} mandatory`} defaultValue={""} onChange={handleChange} >
                                            <option value="" disabled>-- Select -- </option>
                                            <option value="Sales Consultant">Sales Consultant</option>
                                            <option value="Senior Consultant">Senior Consultant</option>
                                            <option value="Sales Manager">Sales Manager</option>
                                            <option value="Senior Manager">Senior Manager</option>
                                            <option value="Sales Director">Sales Director</option>
                                            <option value="Partner">Partner</option>
                                        </select>
                                    </div>
                                )}


                                { (isAgent && !isPartner) && (
                                    <div className="mb-4">
                                        <label className="font-medium ltr:text-left" htmlFor="reportingManager">Reporting Manager <span className="text-red-400">*</span></label>
                                        <select id="reportingManager" className={`${css["form-input"]} mandatory`} defaultValue={""} onChange={handleChange} >
                                            <option value="">-- Select --</option>
                                            {managers.map((manager) => (
                                                <option key={manager.id} value={manager.id}>
                                                    {manager.firstName} {manager.lastName}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                )}
                            </div>
                            

                            <div className="grid gap-4 grid-cols-1 md:grid-cols-3">
                                <div className="mb-4">
                                    <label className="font-medium ltr:text-left" htmlFor="structure">Structure <span className="text-red-400">*</span></label>
                                    <select id="structure" className={`${css["form-input"]} mandatory`} defaultValue={""} onChange={handleChange} >
                                        <option value="" disabled>-- Select -- </option>
                                        <option value="Sole Trader / Individual">Sole Trader / Individual</option>
                                        <option value="Partnership">Partnership</option>
                                        <option value="Company">Company</option>
                                        <option value="Trust">Trust</option>
                                    </select>
                                </div>

                                <div className="mb-4">
                                    <label className="font-medium ltr:text-left" htmlFor="abnStatus">Active ABN <span className="text-red-400">*</span></label>
                                    <select id="abnStatus" className={`${css["form-input"]} mandatory`} defaultValue={""} onChange={handleChange} >
                                        <option value="" disabled>-- Select -- </option>
                                        <option value="true">Yes</option>
                                        <option value="false">No</option>
                                    </select>
                                    <p className="text-sm text-gray-400">Must answer "Yes" if you're Agent</p>
                                </div>

                                <div className="mb-4">
                                    <label className="font-medium ltr:text-left" htmlFor="abn">ABN <span className="text-red-400">*</span></label>
                                    <input id="abn" type="text" maxLength={11} className={`${css["form-input"]} mandatory`} onChange={handleChange} />
                                </div>
                            </div>

                            <div className="grid gap-4 grid-cols-1">
                                <div >
                                    <label className="font-medium ltr:text-left" htmlFor="registeredName">Registered Name of ABN <span className="text-red-400">*</span></label>
                                    <input id="registeredName" type="text" className={`${css["form-input"]} mandatory`} onChange={handleChange} />
                                </div>
                            </div>
                            <div className="grid gap-4 grid-cols-1 mb-4">
                                <p className="text-sm text-gray-400">https://abr.business.gov.au/, the names and the ABN must match with the record on the ABR registry.</p>
                            </div>

                            <div className="grid gap-4 grid-cols-1">
                                <label className="font-medium ltr:text-left">Registered Address <span className="text-red-400">*</span></label>
                            </div>
                            <div className="grid gap-4 grid-cols-1 md:grid-cols-5">
                                <div className="md:col-span-2 mb-4">
                                    <input id="address" type="text" className={`${css["form-input"]} mandatory`} autocomplete="on" onChange={handleChange} />
                                    <p className="text-sm text-gray-400">Unit & Street</p>
                                </div>
                                <div className="mb-4">
                                    <input id="suburb" label="suburb" type="text" autocomplete="on" className={`${css["form-input"]} mandatory`} onChange={handleChange} />
                                    <p className="text-sm text-gray-400">Suburb</p>
                                </div>
                                <div className="mb-4">
                                    <select id="state" className={`${css["form-input"]} mandatory`} defaultValue="" autocomplete="on" onChange={handleChange} >
                                        <option value="" disabled>-- Select -- </option>
                                        <option value={"NSW"}>NSW</option>
                                        <option value={"VIC"}>VIC</option>
                                        <option value={"QLD"}>QLD</option>
                                        <option value={"ACT"}>ACT</option>
                                        <option value={"WA"}>WA</option>
                                        <option value={"SA"}>SA</option>
                                        <option value={"NT"}>NT</option>
                                        <option value={"TAS"}>TAS</option>
                                    </select>
                                    <p className="text-sm text-gray-400">State</p>
                                </div>
                                <div className="mb-4">
                                    <input id="postcode" type="text" className={`${css["form-input"]} mandatory`} autocomplete="on" onChange={handleChange} />
                                    <p className="text-sm text-gray-400">Postcode</p>
                                </div>
                            </div>

                            <div className="grid gap-4 grid-cols-1 md:grid-cols-3">
                                <div className="mb-4">
                                    <label className="font-medium ltr:text-left" htmlFor="firstName">First Name <span className="text-red-400">*</span></label>
                                    <input id="firstName" type="text" className={`${css["form-input"]} mandatory`} onChange={handleChange} />
                                </div>

                                <div className="mb-4">
                                    <label className="font-medium ltr:text-left" htmlFor="middleName">Middle Name</label>
                                    <input id="middleName" type="text" className={`${css["form-input"]}`} onChange={handleChange} />
                                </div>

                                <div className="mb-4">
                                    <label className="font-medium ltr:text-left" htmlFor="lastName">Last Name <span className="text-red-400">*</span></label>
                                    <input id="lastName" type="text" className={`${css["form-input"]} mandatory`} onChange={handleChange} />
                                </div>
                            </div>
                            <div className="grid gap-4 grid-cols-1 mb-4">
                                <p className="text-sm text-gray-400">If it is an individual, input your legal name; If it is a partnership, input the name of partner(s); If it is a company, input the name of the director; If it is a trust, input the name of the director of the trustee</p>
                            </div>

                            <div className="grid gap-4 grid-cols-1 md:grid-cols-3">
                                <div className="">
                                    <label className="font-medium ltr:text-left" htmlFor="entityName">Entity Name</label>
                                    <input id="entityName" type="text" className={`${css["form-input"]}`} onChange={handleChange} />
                                </div>
                            </div>
                            <div className="grid gap-4 grid-cols-1 mb-4">
                                <p className="text-sm text-gray-400">Put in the name of the trustee company if applicable.</p>
                            </div>

                            <div className="grid gap-4 grid-cols-1 md:grid-cols-3">
                                <div className="">
                                    <label className="font-medium ltr:text-left" htmlFor="gstStatus">GST Status <span className="text-red-400">*</span></label>
                                    <select id="gstStatus" className={`${css["form-input"]} mandatory`} defaultValue={""} onChange={handleChange}  >
                                        <option value="" disabled>-- Select -- </option>
                                        <option value="true">Yes</option>
                                        <option value="false">No</option>
                                    </select>
                                </div>
                            </div>
                            <div className="grid gap-4 grid-cols-1 mb-4">
                                <p className="text-sm text-gray-400">Select whether you have registered with GST?</p>
                            </div>

                            <div className="grid gap-4 grid-cols-1 md:grid-cols-3">
                                <div className="mb-4">
                                    <label className="font-medium ltr:text-left" htmlFor="contactEmail">Contact Email <span className="text-red-400">*</span></label>
                                    <input id="contactEmail" type="text" className={`${css["form-input"]} mandatory`} onChange={handleChange} />
                                </div>

                                <div className="mb-4">
                                    <label className="font-medium ltr:text-left" htmlFor="contactPhone">Contact Phone <span className="text-red-400">*</span></label>
                                    <input id="contactPhone" type="text" className={`${css["form-input"]} mandatory`} onChange={handleChange} />
                                </div>
                            </div>

                            <div className="grid grid-cols-1">
                                <div>Identification Document <span className="text-red-400">*</span></div>
                            </div>
                            <div className="grid grid-cols-1 mb-4">
                                <Dropzone dropzoneId={"id-uploader"} onFileUpload={handleFileUpload} />
                                <p className="text-sm text-gray-400">Upload your current valid ID - Australian Driver License, or - Passport</p>
                            </div>


                            <div className="text-pw text-lg font-semibold">
                                <h3>Bank Account Detail</h3>
                            </div>
                            <hr className="mb-4" />

                            <div className="grid gap-4 grid-cols-1 md:grid-cols-4">
                                <div className="mb-4">
                                    <label className="font-medium ltr:text-left" htmlFor="bankName">Bank Name <span className="text-red-400">*</span></label>
                                    <input id="bankName" type="text" className={`${css["form-input"]} mandatory`} onChange={handleChange} />
                                </div>

                                <div className="mb-4">
                                    <label className="font-medium ltr:text-left" htmlFor="accountName">Account Name <span className="text-red-400">*</span></label>
                                    <input id="accountName" type="text" className={`${css["form-input"]} mandatory`} onChange={handleChange} />
                                </div>

                                <div className="mb-4">
                                    <label className="font-medium ltr:text-left" htmlFor="accountNumber">Account Number <span className="text-red-400">*</span></label>
                                    <input id="accountNumber" type="text" className={`${css["form-input"]} mandatory`} onChange={handleChange} />
                                </div>

                                <div className="mb-4">
                                    <label className="font-medium ltr:text-left" htmlFor="bsb">BSB <span className="text-red-400">*</span></label>
                                    <input id="bsb" type="text" className={`${css["form-input"]} mandatory`} onChange={handleChange} />
                                </div>
                            </div>

                            <div className="text-pw text-lg font-semibold">
                                <h3>Declaration</h3>
                            </div>
                            <hr className="mb-4" />

                            <div className="grid gap-4 grid-cols-1">
                                <div>
                                    <ConfigProvider
                                        theme={{
                                            components: {
                                                Checkbox: {
                                                    colorPrimary: '#f15b22',
                                                    colorPrimaryBorder: '#f15b22',
                                                    colorPrimaryHover: '#f15b22',
                                                    fontSize: 16,
                                                }
                                            },
                                        }}
                                    >
                                        <Checkbox id={"declaration-checkbox"} onChange={checkboxHandler}>I declare I am properly insured, including but not limited to Worker's Compensation, Professional Indemnity, and public liability insurance.</Checkbox>
                                    </ConfigProvider>
                                </div>
                            </div>

                            <div className="grid gap-4 grid-cols-1 mt-4">
                                <div>
                                    <ConfigProvider
                                        theme={{
                                            components: {
                                                Checkbox: {
                                                    colorPrimary: '#f15b22',
                                                    colorPrimaryBorder: '#f15b22',
                                                    colorPrimaryHover: '#f15b22',
                                                    fontSize: 16,
                                                }
                                            },
                                        }}
                                    >
                                        <Checkbox id={"declaration-2-checkbox"} onChange={checkboxHandler}>I declare I hold a valid real estate license or a certificate of registration on Fair Trading in the relevant state all time with PW Realty Group.</Checkbox>
                                    </ConfigProvider>
                                </div>
                            </div>

                            <div className="grid grid-cols-1 mt-4">
                                <div>
                                    Real Estate License/Certificate of Registration on Fair Trading<span className="text-red-400">*</span>
                                </div>
                            </div>
                            <div className="grid grid-cols-1 mb-4">
                                <Dropzone dropzoneId={"real-estate-license-uploader"} onFileUpload={handleFileUpload} />
                                <p className="text-sm text-gray-400">Upload scan copy of your certificate or license.</p>
                            </div>

                            <div className="grid grid-cols-1">
                                Recipient Created Tax Invoice (RCTI) Agreement
                            </div>
                            <div className="grid grid-cols-1 text-sm bg-base-200 px-4 py-4">
                                <ol className="list-decimal ml-4">
                                    <li>
                                        PW Realty Group will issue RCTIs or recipient-created adjustment notes (RCANs) in accordance with the A New Tax System (Goods and Services Tax) Act 1999 (GST Act) for taxable supplies made by the Agents ("supplier") to PW Realty Group under the GST Act.
                                    </li>
                                    <li>
                                        The supplier will not issue tax invoices or adjustment notes in respect of the same supplies.
                                    </li>
                                    <li>
                                        The supplier is registered for GST and will notify PW Realty Group if it ceases to be registered.
                                    </li>
                                    <li>
                                        PW Realty Group is registered for GST and will notify the supplier if it ceases to be registered or if it ceases to satisfy the requirements of public ruling GSTR 2000/10 and, in either case, PW Realty Group will cease to issue documents that would otherwise be RCTIs or RCANs.
                                    </li>
                                    <li>
                                        PW Realty Group may issue RCTIs or RCANs in electronic form.
                                    </li>
                                    <li>
                                        This RCTI agreement shall continue for the term of the general agreement or until either party terminates this RCTI agreement by notice in writing, whichever is the latter.
                                    </li>
                                    <li>
                                        By signing below, the signatory warrants that s/he has the authority to enter into this RCTI agreement on behalf of the supplier.
                                    </li>
                                </ol>
                            </div>
                            <div className="grid gap-4 grid-cols-1 mb-4">
                                <div>
                                    <ConfigProvider
                                        theme={{
                                            components: {
                                                Checkbox: {
                                                    colorPrimary: '#f15b22',
                                                    colorPrimaryBorder: '#f15b22',
                                                    colorPrimaryHover: '#f15b22',
                                                    fontSize: 16,
                                                }
                                            },
                                        }}
                                    >
                                        <Checkbox id={"tc-checkbox"} onChange={checkboxHandler}>I accept the Terms and Conditions.</Checkbox>
                                    </ConfigProvider>
                                </div>
                            </div>

                            <div className="text-pw text-lg font-semibold">
                                <h3>Signature</h3>
                            </div>
                            <hr className="mb-4" />

                            <div className="grid grid-cols-1 mb-4">
                                <SignaturePad onSignatureChange={handleSignatureChange} />
                            </div>

                            <div className="grid gap-4 grid-cols-1 md:grid-cols-3">
                                <div className="mb-4">
                                    <label className="font-medium ltr:text-left" htmlFor="printName">Print Name <span className="text-red-400">*</span></label>
                                    <input id="printName" type="text" className={`${css["form-input"]} mandatory`} onChange={handleChange} />
                                </div>
                                <div className="mb-4">
                                    <label className="font-medium ltr:text-left" htmlFor="registerDate">Date <span className="text-red-400">*</span></label>
                                    <input id="registerDate" type="date" className={`${css["form-input"]} mandatory`} onChange={handleChange} defaultValue={defaultDate}/>
                                </div>
                            </div>

                            <div className="grid grid-cols-1 text-center text-error">
                                <div className="mb-4">
                                    <p id="error-message">{errorMessage}</p>
                                </div>
                            </div>

                            <div className="grid grid-cols-1">
                                <div className="mb-4 text-center mt-4">
                                    <Button buttonText="Submit" onClick={handleOnClick} disabled={isSubmitting} />
                                </div>
                            </div>

                            {uploadingMessage && (
                                <div className="grid grid-cols-1 text-center text-success">
                                    <div className="mb-4">
                                        <p id="uploading-message">{uploadingMessage}</p>
                                    </div>
                                </div>
                            )}


                        </form>
                    </div>
                </div>
            </div>
        </section>
    );

}

