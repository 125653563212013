import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Skeleton, Tag } from "antd";
import { getPresignedURL } from "../../functions/common/file-upload";
import { formatPrice } from "../../functions/common/util";

export default function Property({
  projectType,
  id,
  imgSrc,
  price,
  title,
  suburb,
  address,
  propertyType,
  description,
  unpublished,
}) {
  const [presignedImgUrl, setPresignedImgUrl] = useState("");

  useEffect(() => {
    const getCoverImage = async () => {
      const file = {
        name: "cover-image.jpg",
        s3Key: `property/thumbnail/${imgSrc}`,
      };
      const url = await getPresignedURL(file, true, true);
      // console.log("presignedUrl", url)
      setPresignedImgUrl(url);
    };

    if (imgSrc !== "") {
      getCoverImage();
    }
  }, [imgSrc]);

  if (price) {
    price = formatPrice(price);
  }

  const navigate = useNavigate();
  const viewPropertyDetail = () => {
    navigate(`/portfolio/${projectType}/detail/${id}`);
  };

  return (
    <div
      onClick={viewPropertyDetail}
      className="flex flex-col bg-white border shadow-sm rounded-2xl drop-shadow-md hover:drop-shadow-xl cursor-pointer"
    >
      {presignedImgUrl === "" ? (
        <Skeleton.Image active={true} className="!w-full !h-48" />
      ) : (
        <img
          className="w-full rounded-t-xl hidden sm:block h-48"
          src={presignedImgUrl}
          alt=""
        />
      )}
      <div className="p-4 md:p-5">
        {price && <h3 className="text-lg font-bold text-gray-800">${price}</h3>}

        {title && (
          <h3 className="font-bold text-gray-800">
            {suburb} - {title}
          </h3>
        )}

        {address && <p className="mt-1 text-gray-600">{address}</p>}

        {propertyType && (
          <p className="mt-1 text-gray-400">Property Type: {propertyType}</p>
        )}

        {description && <p className="mt-1 text-gray-600">{description}</p>}

        {unpublished && <Tag color={"volcano"}>Unpublished</Tag>}
      </div>
    </div>
  );
}
