import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Skeleton, Popconfirm, Space } from 'antd';
import { getPresignedURL } from "../functions/common/file-upload"
import { fetchUserLevelAndRate, isLoginUserAdmin } from "../functions/common/manage-login-user";
import Toast from "../components/Toast";
import * as Util from "../functions/common/util"


export default function PortfolioOtpDetail() {

    const { propertyId } = useParams();

    const [property, setProperty] = useState({});

    const [commissionToDisplay, setCommissionToDisplay] = useState("")

    const [coverImgSignedUrl, setCoverImgSignedUrl] = useState("")

    const isAdmin = isLoginUserAdmin();

    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Portfolio Detail"
    }, [])

    useEffect(() => {

        document.title = "Portfolio Detail"

        const getCoverImage = async (file) => {
            const presignedUrl = await getPresignedURL(file, true, true);
            return presignedUrl;
        }

        const fetchProperty = async (id) => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/property/otp/fetch/${id}`);
                if (response.status === 200) {
                    console.log(response.data)
                    setProperty(response.data)

                    const file = {
                        name: "cover-image.jpg",
                        s3Key: `property/original/${response.data.coverImage}`
                    }

                    getCoverImage(file).then((url) => {
                        setCoverImgSignedUrl(url)
                    })

                    const userRates = await fetchUserLevelAndRate();
                    console.log(userRates);
                    
                    const today = new Date();
                    response.data.commissionSettings.forEach((setting) => {
                        const startDate = new Date(setting.commissionStartDate);
                        if(today >= startDate ){

                            let commissionRate = setting.commissionRate;
                            let commissionAmount = setting.commissionCash;

                            if(userRates.otpCommissionLevel === "L1" || userRates.otpCommissionLevel === "L2") {
                                //if has cap, then apply cap
                                if (setting.commissionCapRate > 0 ) {
                                   commissionRate = setting.commissionCapRate;
                                }

                                if (setting.commissionCapAmount > 0) {
                                    commissionAmount = setting.commissionCapAmount;
                                }
                            }


                            //if user is admin or CEO, then should see the total commission
                            //so we set the otpCommissionRate to 100% to display the total comm.
                            if (isAdmin) {
                                userRates.otpCommissionRate = 100;
                            }

                            const commissionRateByLevel = Math.round(commissionRate * userRates.otpCommissionRate) / 100;
                            const commissionAmountByLevel = Math.round(commissionAmount * userRates.otpCommissionRate) / 100;

                            setCommissionToDisplay( 
                                <Space size={[30, 6]} wrap>
                                    <span>Rate (incl. GST): {Util.formatPrice(commissionRateByLevel)}%</span>
                                    {commissionAmount > 0 && 
                                    <span>Amount (incl. GST): ${Util.formatPrice(commissionAmountByLevel)}</span>
                                    }
                                    {setting.bonus > 0 &&
                                    <span>Agent Bonus (incl. GST): ${Util.formatPrice(setting.bonus)}</span>
                                    }
                                </Space>
                            )
                            
                        }
                    })
                }
            } catch (error) {
                console.log(error);
            }
        }

        fetchProperty(propertyId)

    }, [propertyId, isAdmin])

    const publishProject = () => {
        const data = {
            id: property.id,
        };

        axios.post(`${process.env.REACT_APP_API_URL}/api/property/otp/publish`, data)
            .then(response => {
                if (response.status === 200) {
                    showToast("success", "Published successfully!")
                    setProperty({
                        ...property,
                        status: 1,
                    })
                }
            }).catch(error => {
                console.log(error)
                showToast("error", "Error")
            });
    }


    const unpublishProject = () => {
        const data = {
            id: property.id,
        };

        axios.post(`${process.env.REACT_APP_API_URL}/api/property/otp/unpublish`, data)
            .then(response => {
                if (response.status === 200) {
                    showToast("success", "Unpublished successfully!")
                    setProperty({
                        ...property,
                        status: 0,
                    })
                }
            }).catch(error => {
                console.log(error)
                showToast("error", "Error")
            });
    }



    const [isToastVisible, setIsToastVisible] = useState(false);
    const [toastType, setToastType] = useState("normal");
    const [toastMessage, setToastMessage] = useState("");

    const showToast = (type, message) => {
        setIsToastVisible(true);
        setToastType(type);
        setToastMessage(message);

        // Optionally, you can set a timeout to hide the toast after a certain duration
        setTimeout(() => {
            setIsToastVisible(false);
        }, 2000); // Hide the toast after 3 seconds (adjust as needed)
    };




    if (!property.id > 0) {
        return (
            <div>
                <Header currentPage="portfolio" />

                <div className="main-container">
                    <div className="mx-auto px-5 mt-10 md:px-10">
                        <Skeleton active />
                    </div>
                </div>

                <Footer />
            </div>
        )
    }


    return (
        <div>
            <Header currentPage="portfolio" />

            <Toast isVisible={isToastVisible} type={toastType} message={toastMessage} />


            <div className="main-container">

                <div className="mx-auto px-5 mt-10 md:px-10">

                    <div className={`static card w-fill bg-base-100 shadow-md mb-10`}>


                        {coverImgSignedUrl ? (
                            <div className="image-container" style={{ maxHeight: '500px', overflow: 'hidden', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <img className="w-full h-auto rounded-t-xl sm:block" src={coverImgSignedUrl} alt="" />
                            </div>
                        ) : (
                            <Skeleton.Image active={true} className="!w-full !h-52" />
                        )}

                        <div className="card-body">

                            <div className="grid gap-4 grid-cols-1">
                                <div className="flex flex-wrap items-center">
                                    <div className="md: mr-10">
                                        <div className="text-sm">
                                            {property.address + " " + property.suburb + " " + property.state + " " + property.postcode}
                                        </div>
                                        <div className="font-semibold">
                                            {property.title}
                                        </div>
                                    </div>
                                    {isAdmin && (
                                        <div className="">
                                            <button type="button" className="mx-2 text-xs py-1 px-4 inline-flex justify-center items-center gap-2 rounded-md border-2 border-orange-500 font-semibold text-orange-500 hover:text-white hover:bg-pw focus:outline-none" onClick={() => {
                                                navigate(`/portfolio/otp/edit/${property.id}`)
                                            }}>
                                                Edit
                                            </button>

                                            {property && property.status > 0 ? (
                                                <Popconfirm
                                                    title="Unpublish Project"
                                                    description="Are you sure to unpublish?"
                                                    onConfirm={unpublishProject}
                                                    onCancel={() => { }}
                                                    okText="Yes"
                                                    cancelText="No"
                                                    okButtonProps={{ className: "bg-pw hover:bg-pw-hover" }}
                                                >
                                                    <button type="button" className="mx-2 text-xs py-1 px-4 inline-flex justify-center items-center gap-2 rounded-md border-2 border-orange-500 font-semibold text-orange-500 hover:text-white hover:bg-pw focus:outline-none">
                                                        Unpublish
                                                    </button>
                                                </Popconfirm>
                                            ) : (
                                                <Popconfirm
                                                    title="Publish Project"
                                                    description="Are you sure to publish?"
                                                    onConfirm={publishProject}
                                                    onCancel={() => { }}
                                                    okText="Yes"
                                                    cancelText="No"
                                                    okButtonProps={{ className: "bg-pw hover:bg-pw-hover" }}
                                                >
                                                    <button type="button" className="mx-2 text-xs py-1 px-4 inline-flex justify-center items-center gap-2 rounded-md border-2 border-orange-500 font-semibold text-orange-500 hover:text-white hover:bg-pw focus:outline-none">
                                                        Publish
                                                    </button>
                                                </Popconfirm>
                                            )}

                                        </div>
                                    )}
                                </div>

                                <div className="flex justify-between mt-10">
                                    <div className="text-pw text-lg font-semibold">
                                        <h3>Property Features</h3>
                                    </div>
                                </div>
                                <hr className="" />
                                <div className="">
                                    <i className="ri-home-8-line text-pw mr-2"></i>
                                    <span className="font-semibold">{property.propertyType}</span>
                                </div>
                                <div className="mb-10">
                                    <i className="ri-money-dollar-circle-line text-pw mr-2"></i>
                                    <span className="font-semibold">${Util.formatPrice(property.displayPrice)}</span>
                                </div>

                                {property.keySellingPoint && (
                                    <>
                                        <div className="flex justify-between ">
                                            <div className="text-pw text-lg font-semibold">
                                                <h3>Key Features</h3>
                                            </div>
                                        </div>
                                        <hr className="" />
                                        <div className="mb-10" dangerouslySetInnerHTML={{ __html: property.keySellingPoint.replace(/\n/g, "<br />").replace(/\t/g, "&nbsp;&nbsp;") }}>
                                        </div>
                                    </>
                                )}

                                {property.promotion && (
                                    <>
                                        <div className="flex justify-between ">
                                            <div className="text-pw text-lg font-semibold">
                                                <h3>Promotion</h3>
                                            </div>
                                        </div>
                                        <hr className="" />
                                        <div className="mb-10" dangerouslySetInnerHTML={{ __html: property.promotion.replace(/\n/g, "<br />").replace(/\t/g, "&nbsp;&nbsp;") }}>
                                        </div>
                                    </>
                                )}

                                {property.displayInfo && (
                                    <>
                                        <div className="flex justify-between ">
                                            <div className="text-pw text-lg font-semibold">
                                                <h3>Display Info</h3>
                                            </div>
                                        </div>
                                        <hr className="" />
                                        <div className="mb-10" dangerouslySetInnerHTML={{ __html: property.displayInfo.replace(/\n/g, "<br />").replace(/\t/g, "&nbsp;&nbsp;") }}>
                                        </div>
                                    </>
                                )}

                                <div className="flex justify-between ">
                                    <div className="text-pw text-lg font-semibold">
                                        <h3>Development Info</h3>
                                    </div>
                                </div>
                                <hr className="" />
                                <div>
                                    Developer: {property.developer}
                                </div>
                                <div>
                                    Builder: {property.builder}
                                </div>
                                <div>
                                    Completion Date: {property.completionDate?.length > 7 ? property.completionDate.substring(0, 7) : "-"}
                                </div>
                                <div className="mb-10">
                                    Construction Start From: {property.constructionStartDate?.length > 7 ? property.constructionStartDate.substring(0, 7) : "-"}
                                </div>

                                <div className="flex justify-between ">
                                    <div className="text-pw text-lg font-semibold">
                                        <h3>Commission</h3>
                                    </div>
                                </div>
                                <hr className="" />
                                <div className="mb-5">
                                    {commissionToDisplay === "" ? "-" : commissionToDisplay}
                                </div>

                                <div className="flex justify-between ">
                                    <div className="text-pw text-lg font-semibold">
                                        <h3>Payment Schedule</h3>
                                    </div>
                                </div>
                                <hr className="" />
                                <div className="mb-5">
                                    <Space size={[30, 6]} wrap>
                                    {property.paymentStage1 > 0 && (
                                        <div>
                                            Stage I: {property.paymentStage1+"%"}
                                        </div>
                                    )}
                                    {property.paymentStage2 > 0 && (
                                        <div>
                                            Stage II: {property.paymentStage2+"%"}
                                        </div>
                                    )}
                                    {property.paymentStage3 > 0 && (
                                        <div>
                                            Stage III: {property.paymentStage3+"%"}
                                        </div>
                                    )}
                                    {property.paymentStage4 > 0 && (
                                        <div>
                                            Stage IV: {property.paymentStage4+"%"}
                                        </div>
                                    )}
                                    {property.paymentStage5 > 0 && (
                                        <div>
                                            Stage V: {property.paymentStage5+"%"}
                                        </div>
                                    )}
                                    </Space>
                                </div>

                                <div className="flex justify-between ">
                                    <div className="text-pw text-lg font-semibold">
                                        <h3>Search Criteria</h3>
                                    </div>
                                </div>
                                <hr className="" />
                                <div className="mb-5">
                                    <Space size={[30, 6]} wrap>
                                        <div>
                                            Min. Price: ${Util.formatPrice(property.minSearchPrice)}
                                        </div>
                                        <div>
                                            Max. Price: ${Util.formatPrice(property.maxSearchPrice)}
                                        </div>
                                    </Space>
                                </div>

                                <div className="flex justify-between ">
                                    <div className="text-pw text-lg font-semibold">
                                        <h3>Resource</h3>
                                    </div>
                                </div>
                                <hr className="" />
                                <ul>
                                    {property.projectInfoLink && (
                                        <div>
                                            <i className="ri-link mr-2 text-pw"></i>
                                            <a className="link-pw" href={property.projectInfoLink} target="_blank" rel="noreferrer">Project Info</a>
                                        </div>
                                    )}
                                    {property.brochureLink && (
                                        <div>
                                            <i className="ri-link mr-2 text-pw"></i>
                                            <a className="link-pw" href={property.brochureLink} target="_blank" rel="noreferrer">Brochure</a>
                                        </div>
                                    )}
                                    {property.floorPlanLink && (
                                        <div>
                                            <i className="ri-link mr-2 text-pw"></i>
                                            <a className="link-pw" href={property.floorPlanLink} target="_blank" rel="noreferrer">Floor Plans / Lots</a>
                                        </div>
                                    )}
                                    {property.siteMapLink && (
                                        <div>
                                            <i className="ri-link mr-2 text-pw"></i>
                                            <a className="link-pw" href={property.siteMapLink} target="_blank" rel="noreferrer">Floor Plates / Site Map</a>
                                        </div>
                                    )}
                                    {property.priceListLink && (
                                        <div>
                                            <i className="ri-link mr-2 text-pw"></i>
                                            <a className="link-pw" href={property.priceListLink} target="_blank" rel="noreferrer">Price List</a>
                                        </div>
                                    )}
                                    {property.salesAdviceLink && (
                                        <div>
                                            <i className="ri-link mr-2 text-pw"></i>
                                            <a className="link-pw" href={property.salesAdviceLink} target="_blank" rel="noreferrer">Sales Advice</a>
                                        </div>
                                    )}
                                </ul>


                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}