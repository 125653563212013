import React, { useEffect, useState, useRef } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { isLoginUserAdmin } from "../functions/common/manage-login-user";
import axios from "axios";
import Toast from "../components/Toast";
import { Link } from "react-router-dom";
import { List, Avatar, Tag } from "antd";
import { UserOutlined } from '@ant-design/icons';



export default function Dashboard() {

    const [announcement, setAnnouncement] = useState("");

    const [teamMembers, setTeamMembers] = useState([]);

    useEffect(() => {
        // checkLogin();     
        document.title = "Home"

        const fetchDashboard = () => {
            axios.get(`${process.env.REACT_APP_API_URL}/api/dashboard/get-announcement`)
                .then(response => {
                    if (response.status === 200) {
                        setAnnouncement(decodeURIComponent(response.data.announcement));
                    }
                }).catch(error => {

                });
        }

        const fetchTeamMembers = () => {
            axios.get(`${process.env.REACT_APP_API_URL}/api/user/fetch-team-members`)
                .then(response => {
                    if (response.status === 200) {
                        // console.log(response.data)
                        setTeamMembers(response.data);
                    }
                }).catch(error => {

                });
        }

        fetchDashboard();
        fetchTeamMembers();

    }, []);

    const role = localStorage.getItem('role'); // Get user inits
    const userName = localStorage.getItem('userName'); // Get user name

    const isAdmin = isLoginUserAdmin();

    const [editMode, setEditMode] = useState(false);

    const textareaRef = useRef(null);

    const containerRef = useRef(null);

    const editAnnouncement = () => {
        setEditMode(true);

        // console.log(containerRef.current);
        // console.log(textareaRef.current);

        if (containerRef.current && textareaRef.current) {
            let containerHeight = containerRef.current.offsetHeight;

            if (containerHeight < 100) {
                containerHeight = 300;
            }

            textareaRef.current.style.height = `${containerHeight}px`;

            textareaRef.current.value = containerRef.current.innerHTML.replace(/<br\s*\/?>/gi, '\n');

            setTimeout(() => {
                // console.log("focus textarea")
                textareaRef.current.focus();
            }, 100);

        }

    }

    const cancelEdit = () => {
        setEditMode(false);
    }

    const saveEdit = () => {
        setEditMode(false);
        //update innerHTML
        containerRef.current.innerHTML = textareaRef.current.value.replace(/\n/g, '<br />');

        if (containerRef.current.innerHTML) {
            axios.post(`${process.env.REACT_APP_API_URL}/api/dashboard/edit-announcement`, encodeURIComponent(containerRef.current.innerHTML), {
                headers: {
                    'Content-Type': 'text/plain',
                },
            })
                .then(response => {
                    if (response.status === 200) {
                        showToast("success", "Update successfully!");
                    }
                }).catch(error => {

                });
        }
    }

    const [isToastVisible, setIsToastVisible] = useState(false);
    const [toastType, setToastType] = useState("normal");
    const [toastMessage, setToastMessage] = useState("");

    const showToast = (type, message) => {
        setIsToastVisible(true);
        setToastType(type);
        setToastMessage(message);

        // Optionally, you can set a timeout to hide the toast after a certain duration
        setTimeout(() => {
            setIsToastVisible(false);
        }, 2000); // Hide the toast after 3 seconds (adjust as needed)
    };

    return (
        <div>
            <Header currentPage="home" />

            <Toast isVisible={isToastVisible} type={toastType} message={toastMessage} />

            <div className="main-container">
                <div className="grid grid-cols-1 gap-4 p-5">
                    <div className="static card w-fill bg-base-100 shadow-md">
                        <div className="card-body">
                            <div className="flex flex-wrap items-center justify-between">
                                <div className="flex items-center">
                                    <div className="avatar placeholder">
                                        <div className="bg-pw text-white rounded-full w-16">
                                            < i className="ri-user-line text-3xl"></i>
                                        </div>
                                    </div>
                                    <div className="ml-5">
                                        <span className="text-2xl font-semibold">{userName}</span>
                                        <br />
                                        <span className="text-sm text-slate-400">{role}</span>
                                    </div>
                                </div>

                                <div className="flex items-center">
                                    <Link className="link-pw cursor-pointer" to="/profile">View Profile</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-4 gap-4 p-5">
                    <div className="md:col-span-3">
                        <div className="static card w-fill bg-base-100 shadow-md">
                            <div className="card-body flex">
                                <div className="flex justify-between border-b-2">
                                    <div>
                                        <h2 className="card-title pb-4">
                                            Announcement
                                        </h2>
                                    </div>
                                    {isAdmin ? (
                                        <div className={`cursor-pointer ${editMode ? "hidden" : ""}`} onClick={editAnnouncement}>
                                            <i className="ri-edit-line"></i>
                                        </div>
                                    ) : ("")}

                                </div>

                                <textarea className={`py-3 px-4 block w-full border border-solid border-gray-300 rounded-md text-sm focus:border-orange-500 ${editMode ? "" : "hidden"}`} ref={textareaRef}></textarea>

                                <div className={editMode ? "" : "hidden"}>
                                    <button type="button" className="btn btn-pw mr-4" onClick={saveEdit}>Save</button>
                                    <button type="button" className="btn font-normal normal-case" onClick={cancelEdit}>Cancel</button>
                                </div>

                                <div className={`pt-5 ${editMode ? "hidden" : ""}`} id="announcement-container"
                                    ref={containerRef}
                                    dangerouslySetInnerHTML={{ __html: announcement }} >
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="col-span-1">
                        <div className="static card w-fill bg-base-100 shadow-md">
                            <div className="card-body">
                                <h2 className="card-title pb-4 border-b-2">Quick Links</h2>
                                <ul className="list-disc ml-5 pt-5">
                                    <li>
                                        <a className="link-pw" target="_blank" rel="noreferrer" href="https://1drv.ms/u/s!AuWGzQNFiVN5g2BrlWpKpLEqNPeN?e=elplH8">PW Document</a>
                                    </li>
                                    <li>
                                        <a className="link-pw" target="_blank" rel="noreferrer" href="https://1drv.ms/u/s!AuWGzQNFiVN5g1l9ElMq74CrrAUN?e=65u7qC">PW Off The Plan</a>
                                    </li>
                                    <li>
                                        <a className="link-pw" target="_blank" rel="noreferrer" href="https://1drv.ms/u/s!AuWGzQNFiVN5g1X1LVLGOxf6sYvh?e=ADcqyr">PW Resale</a>
                                    </li>
                                    <li>
                                        <a className="link-pw" target="_blank" rel="noreferrer" href="https://1drv.ms/u/s!AuWGzQNFiVN5g18Nym4dizkg5-TX?e=4ckadR">PW Training</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                {teamMembers.length > 0 && (
                    <div className="grid grid-cols-1 gap-4 p-5">
                        <div className="static card w-fill bg-base-100 shadow-md">
                            <div className="card-body">
                                <h2 className="card-title pb-4 border-b-2">Team Members</h2>
                                <List
                                    itemLayout="horizontal"
                                    dataSource={teamMembers}
                                    renderItem={(member, index) => (
                                        <List.Item>
                                            <List.Item.Meta
                                                avatar={<Avatar size="large" icon={<UserOutlined />} />}
                                                title={
                                                    <Link className="font-medium"
                                                        to="/profile"
                                                        state={ {userId: member.id } }
                                                    >
                                                        {`${member.firstName}${member.middleName ? " " : ""}${member.middleName}${member.lastName ? " " : ""}${member.lastName}`}
                                                    </Link>
                                                }
                                                description={
                                                    <div className="">
                                                        <div>
                                                            <span className="text-sm text-gray-400 mr-5">
                                                                <i className="ri-user-shared-line"></i> {member.role ? member.role : member.agentType}
                                                            </span>
                                                        </div>
                                                        <div className="flex flex-wrap">
                                                            <span className="text-sm text-gray-400 mr-5">
                                                                <i className="ri-mail-line"></i>&nbsp;{member.emailAddress}
                                                            </span>
                                                            <span className="text-sm text-gray-400 mr-5">
                                                                <i className="ri-smartphone-line"></i>&nbsp;{member.contactPhone}
                                                            </span>
                                                        </div>
                                                    </div>
                                                }
                                            />
                                            <div>
                                                <Tag color={member.status === 0 ? "geekblue" : (member.status === 1 ? "green" : "volcano")}>
                                                    {member.status === 0 ? "Pending" : (member.status === 1 ? "Active" : "Inactive")}
                                                </Tag>
                                            </div>
                                        </List.Item>
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                )}

            </div>
            <Footer />

        </div>
    );
}