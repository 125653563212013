import React, { useEffect, useState, useRef } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import axios from "axios";
import { Space, Table, Tag, Input, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";


export default function WorkflowList() {


    useEffect(() => {
        document.title = "Workflow";

        const fetchWorkflows = () => {
            axios.get(`${process.env.REACT_APP_API_URL}/api/workflow/otp/fetch-all`)
                .then(response => {
                    if (response.status === 200) {
                        const workflows = response.data;

                        // console.log(workflows)

                        workflows.forEach((workflow, ndx) => {
                            // console.log(new Date(workflow.lastUpdateTimestamp))
                            setData((prevData) => ([
                                ...prevData,
                                {
                                    key: ndx,
                                    id: `${workflow.id}`,
                                    propertyId: workflow.propertyOtp.id,
                                    property: workflow.propertyOtp.title+""+workflow.propertyOtp.address,
                                    propertyTitle: workflow.propertyOtp.title,
                                    propertyAddress: workflow.propertyOtp.address,
                                    lotNumber: (workflow.lotNumber !== "" ? "Lot " + workflow.lotNumber + " " : "") + (workflow.unitNumber !== "" ? "Unit " + workflow.unitNumber : ""),
                                    purchaser: <div>
                                        <p>{workflow.purchaserName}</p>
                                        {workflow.purchaser2Name ? (<p></p>) : (<></>)}
                                    </div>,
                                    purchaserSearch: workflow.purchaserName + " " + workflow.purchaser2Name,
                                    // price: "$"+Util.formatPrice(workflow.price),
                                    //(workflow.approvedBy === 0 ? "Approval Required" : "Partner Approved")
                                    status: workflow.status,
                                    statusText: workflow.enabled ? (workflow.status === 0 ? "Draft" : 
                                        (workflow.status === 1 ?  (workflow.financialDetailSubmitDate === null ? "Contract Processing" : (workflow.approvedBy === 0 ? "Approval Required" : "Partner Approved") ) :
                                        "Completed")) : `Canceled`,
                                    statusColor: workflow.enabled ? (
                                            workflow.status === 0 ? "purple" : 
                                                (workflow.status === 1 ?  (workflow.financialDetailSubmitDate === null ? "geekblue" : (workflow.approvedBy === 0 ? "volcano" : "green") ) :
                                                "#8c8c8c")
                                        ) : `#d9d9d9`,
                                    requireApproval: workflow.status === 1 && workflow.approvedBy === 0 ? "Yes" : "",
                                    date: new Date(workflow.lastUpdateTimestamp === null ? workflow.insertTimestamp : workflow.lastUpdateTimestamp),
                                    sellingAgent: workflow.agentDetail.firstName
                                    + " " + (workflow.agentDetail.middleName?workflow.agentDetail.middleName:"")
                                    + " " + workflow.agentDetail.lastName,
                                    branchOffice: workflow.agentDetail.branchOffice === null ? "" : workflow.agentDetail.branchOffice,
                                    // sellingAgentFirstName: workflow.agentDetail.firstName,
                                    // sellingAgentMiddleName: workflow.agentDetail.middleName,
                                    // sellingAgentLastName: workflow.agentDetail.lastName,
                                    // sellingAgentEntityName: workflow.agentDetail.entityName,
                                }
                            ]))


                        })

                    }
                }).catch(error => {
                    console.log(error)
                });
        }

        fetchWorkflows();

    }, [])


    // const [searchText, setSearchText] = useState('');
    // const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {

        // console.log("selectedKeys", selectedKeys)
        // console.log("dataIndex", dataIndex)

        confirm();
        // setSearchText(selectedKeys[0]);
        // setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters, confirm) => {
        clearFilters();
        handleSearch([''], confirm, "")
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters, confirm)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    {/* <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button> */}
                    <Button
                        className="text-gray-500"
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) => (text)
            // searchedColumn === dataIndex ? (
            //     <Highlighter
            //         highlightStyle={{
            //             backgroundColor: '#ffc069',
            //             padding: 0,
            //         }}
            //         searchWords={[searchText]}
            //         autoEscape
            //         textToHighlight={text ? text.toString() : ''}
            //     />
            // ) : (
            //     text
            // ),
    });


    const columns = [
        {
            title: '',
            dataIndex: 'id',
            key: 'id',
            render: (text) => <span>#{text}</span>,
        },
        {
            title: 'Property',
            dataIndex: 'property',
            key: 'property',
            ...getColumnSearchProps('property'),
            render:  (_, record) => {
                return (
                <div>
                    <Link className="link-pw" to={`/portfolio/otp/detail/${record.propertyId}`}>{record.propertyTitle}</Link>
                    <p className="text-gray-400">{record.propertyAddress}</p>
                </div>
            )},
            sorter: (a, b) => a.property.localeCompare(b.property),
        },
        {
            title: 'Lot / Unit',
            dataIndex: 'lotNumber',
            key: 'lotNumber',
            ...getColumnSearchProps('lotNumber'),
            sorter: (a, b) => a.lotNumber.localeCompare(b.lotNumber),
        },
        {
            title: 'Purchaser',
            dataIndex: 'purchaser',
            key: 'purchaser',
            ...getColumnSearchProps('purchaserSearch'),
            sorter: (a, b) => a.purchaserSearch.localeCompare(b.purchaserSearch),
        },
        {
            title: 'Sales',
            dataIndex: 'sellingAgent',
            key: 'sellingAgent',
            ...getColumnSearchProps('sellingAgent'),
            sorter: (a, b) => a.sellingAgent.localeCompare(b.sellingAgent),
        },
        {
            title: 'Office',
            dataIndex: 'branchOffice',
            key: 'branchOffice',
            ...getColumnSearchProps('branchOffice'),
            sorter: (a, b) => a.branchOffice.localeCompare(b.branchOffice),
        },
        {
            title: 'Status',
            key: 'status',
            dataIndex: 'status',
            ...getColumnSearchProps('statusText'),
            render: (_, record) => (
                <span>
                    <Tag color={record.statusColor}>
                        {record.statusText}
                    </Tag>
                </span>
            ),
            sorter: (a, b) => a.statusText.localeCompare(b.statusText),
        },
        {
            title: 'Last Update',
            dataIndex: 'date',
            key: 'date',
            ...getColumnSearchProps('date'),
            render: (date) => {
                const year = date.getFullYear();
                const month = date.getMonth() + 1; // Months are zero-based, so add 1
                const day = date.getDate();
                const hours = date.getHours();
                const minutes = date.getMinutes();
                // const seconds = date.getSeconds();

                const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
                const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;

                return (<div>{formattedDate} {formattedTime}</div>)
            },
            sorter: (a, b) => new Date(a.date) - new Date(b.date)
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Link className="link-pw" to={`/workflow/otp/detail/${record.id}`}>Detail</Link>
            ),
        },
    ];
    const [data, setData] = useState([]);

    return <>
        <div>
            <Header currentPage="workflow" />

            <div className="main-container">
                <div className="grid grid-cols-1 gap-4 p-5">
                    <div className="flex justify-end">
                        <div>
                            <Link className="btn btn-sm btn-pw cursor-pointer font-medium" to="/workflow/otp/new"><i className="ri-add-line"></i> New OTP Workflow</Link>
                        </div>
                    </div>

                    <div className="static card w-fill bg-base-100 shadow-md">
                        <div className="card-body">

                            <Table
                                columns={columns}
                                pagination={{
                                    defaultPageSize: 10,
                                    position: ['bottomCenter'],
                                }}
                                dataSource={data}
                                scroll={{ x: true }}
                            />


                        </div>
                    </div>
                </div>

            </div>
            <Footer />

        </div>
    </>
}


