import React, { useState, useEffect } from "react"
import { ConfigProvider, Checkbox } from 'antd';
import { Select } from 'antd';
import SignaturePad from "../SignaturePad";
import PWButton from "../Button";
import axios from "axios";
import { validateForm } from "../../functions/form/form-validation"
import Toast from "../Toast";
import * as Util from "../../functions/common/util";
import { getPresignedURL, downloadFile } from "../../functions/common/file-upload"
import { UploadOutlined } from '@ant-design/icons';
import { Upload, Button } from 'antd';

export default function SalesAdviceForm({ prevSalesAdvice, updateWorkflow }) {

    const [salesAdvice, setSalesAdvice] = useState({
        id: 0,
        propertyId: "",
        status: 0,
        lotNumber: "",
        unitNumber: "",
        configuration: "",
        level: "",
        carParking: "",
        storage: "",
        price: "",
        salesDate: "",
        purchasePurpose: "",
        homeLoanApproved: "",
        purchaserName: "",
        purchaserAddress: "",
        purchaserPostcode: "",
        purchaserMobile: "",
        purchaserEmail: "",
        purchaserFirbStatus: "",
        purchaser2Name: "",
        purchaser2Address: "",
        purchaser2Postcode: "",
        purchaser2Mobile: "",
        purchaser2Email: "",
        purchaser2FirbStatus: "",
        solicitorCompany: "",
        solicitorName: "",
        solicitorAddress: "",
        solicitorPostcode: "",
        solicitorPoBox: "",
        solicitorPhone: "",
        solicitorFax: "",
        solicitorMobile: "",
        solicitorEmail: "",
        depositType: "Bank Transfer",
        depositAmount: "",
        accountHolding: "",
        specialCondition: "",
        agentSignature: "",
        agentSignatureDate: "",
        purchaserSignature: "",
        purchaserSignatureDate: "",
        idFiles: [],
        depositFiles: [],
    });

    const [agentSignatureImg, setAgentSignatureImg] = useState(null);
    const [purchaserSignatureImg, setPurchaserSignatureImg] = useState(null);

    useEffect(() => {
console.log(prevSalesAdvice)
        setSalesAdvice((prevData) => ({
            ...prevData,
            ...prevSalesAdvice,
        }));

        const fetchWorkflowDocs = (id) => {
            if (!id) {
                return;
            }

            axios.get(`${process.env.REACT_APP_API_URL}/api/workflow/otp/fetch-docs`, {
                params: {
                    workflowId: id,
                }, // Pass the search data as query parameters
            }).then(response => {
                if (response.status === 200) {
                    const files = response.data;
                    console.log(files)
                    files.forEach((file) => {
                        if (file.fileType === "id") {
                            setIdFilesUploaded(prevFiles => ([
                                ...prevFiles,
                                {
                                    name: file.filename,
                                    s3Key: file.s3Key
                                }
                            ]))
                        } else if (file.fileType === "deposit receipt") {
                            setDepositReceiptUploaded(prevFiles => ([
                                ...prevFiles,
                                {
                                    name: file.filename,
                                    s3Key: file.s3Key
                                }
                            ]))
                        }
                    })
                }
            }).catch(error => {
                console.log(error)
            });
        }

        fetchWorkflowDocs(prevSalesAdvice?.id);

        const fetchSignatures = async () => {
            if (prevSalesAdvice) {
                if (prevSalesAdvice.agentSignature) {
                    const agentSignatureUrl = await getPresignedURL({
                        name: `${prevSalesAdvice.agentSignature}`,
                        s3Key: `workflow/otp/${prevSalesAdvice.agentSignature}`,
                    }, true, true);
                    setAgentSignatureImg(agentSignatureUrl);
                }

                if (prevSalesAdvice.purchaserSignature) {
                    const purchaserSignatureUrl = await getPresignedURL({
                        name: `${prevSalesAdvice.purchaserSignature}`,
                        s3Key: `workflow/otp/${prevSalesAdvice.purchaserSignature}`,
                    }, true, true);
                    setPurchaserSignatureImg(purchaserSignatureUrl);
                }
            }
        }

        fetchSignatures();

    }, [prevSalesAdvice]);

    const [isSaving, setIsSaving] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [idFilesUploaded, setIdFilesUploaded] = useState([]);
    const [depositReceiptUploaded, setDepositReceiptUploaded] = useState([]);

    const setPurchasePurpose = (val) => {
        setSalesAdvice((prevData) => (
            {
                ...prevData,
                purchasePurpose: val,
            }
        ))
    }

    const setHomeLoanApproved = (val) => {
        setSalesAdvice((prevData) => (
            {
                ...prevData,
                homeLoanApproved: val,
            }
        ))
    }

    const setPurchaserFirbStatus = (val) => {
        setSalesAdvice((prevData) => (
            {
                ...prevData,
                purchaserFirbStatus: val,
            }
        ))
    }

    const setPurchaser2FirbStatus = (val) => {
        setSalesAdvice((prevData) => (
            {
                ...prevData,
                purchaser2FirbStatus: val,
            }
        ))
    }

    const setAccountHolding = (val) => {
        setSalesAdvice((prevData) => (
            {
                ...prevData,
                accountHolding: val,
            }
        ))
    }

    const onChangeHandler = (e) => {
        const { id, value } = e.target;

        setSalesAdvice((prevData) => (
            {
                ...prevData,
                [id]: value,
            }
        ))
    }

    const handleAgentSignatureChange = (signatureData) => {
        setSalesAdvice(prevData => (
            {
                ...prevData,
                agentSignature: signatureData
            }
        ));
    };

    const handlePurchaserSignatureChange = (signatureData) => {
        setSalesAdvice(prevData => (
            {
                ...prevData,
                purchaserSignature: signatureData
            }
        ));
    };

    const saveSalesAdvice = () => {
        if (salesAdvice.propertyId === "" || salesAdvice.propertyId <= 0) {
            showToast("error", "Please select a project")
            return;
        }

        setIsSaving(true);

        uploadWorkflowDocs();

        // salesAdvice.price = (salesAdvice.price + "").replace(/[^0-9.]/g, "");
        // salesAdvice.depositAmount = (salesAdvice.depositAmount + "").replace(/[^0-9.]/g, "");


        console.log(salesAdvice)

        axios.post(`${process.env.REACT_APP_API_URL}/api/workflow/otp/create`, salesAdvice, {
            headers: {
                'Content-Type': 'application/json', // Specify JSON content type
            },
        })
            .then((response) => {
                console.log(response);
                setSalesAdvice((prevData) => (
                    {
                        ...prevData,
                        id: response.data.id,
                    }
                )
                )

                setIsSaving(false);

                showToast("success", "Saved successfully!")
            })
            .catch((error) => {
                console.log(error);
                setIsSaving(false);


                showToast("error", "Error!")
            })


    }

    const submitSalesAdvice = async () => {
        if (salesAdvice.propertyId === "" || salesAdvice.propertyId <= 0) {
            showToast("error", "Please select a project")
            return;
        }

        let valid = validateForm("sales-advice-form", salesAdvice);

        // valid = true;
        if (!valid) {
            showToast("error", "Please fill in all mandatory fields.");
            return;
        }

        // if (salesAdvice.agentSignature === null || salesAdvice.agentSignature === "") {
        //     showToast("error", "Agent signature required.");
        //     return;
        // }
        // if (salesAdvice.purchaserSignature === null || salesAdvice.purchaserSignature === "") {
        //     showToast("error", "Purchaser signature required.");
        //     return;
        // }

        if (idFilesBrowsed.length === 0 && idFilesUploaded.length === 0) {
            showToast("error", "Identity document required.");
            return;
        }


        if (depositReceiptBrowsed.length === 0 && depositReceiptUploaded.length === 0) {
            showToast("error", "Deposit receipt required.");
            return;
        }

        setIsSubmitting(true);

        await uploadWorkflowDocs();

        salesAdvice.status = 1; //submitted        
        salesAdvice.price = (salesAdvice.price + "").replace(/[^0-9.]/g, "");
        salesAdvice.depositAmount = (salesAdvice.depositAmount + "").replace(/[^0-9.]/g, "");

        // console.log(salesAdvice);

        axios.post(`${process.env.REACT_APP_API_URL}/api/workflow/otp/create`, salesAdvice, {
            headers: {
                'Content-Type': 'application/json', // Specify JSON content type
            },
        })
            .then((response) => {
                // console.log(response);
                setIsSubmitting(false);
                
                //updateWorkflow(response.data);
                //window.scrollTo(0, 0);
                
                //if we use a state to change the workflow to next status
                //there's an issue is the page was dragged quite long because of the SA form
                //and when move to next component, the page is still very long
                //so we would rather re-render the page
                
                if(response.data.id > 0){
                    window.location.href = `/workflow/otp/detail/${response.data.id}`;
                }
            })
            .catch((error) => {
                console.log(error);
                setIsSubmitting(false);
            })

    }

    const uploadWorkflowDocs = async () => {
        salesAdvice.idFiles = [];

        for (const file of Array.from(idFilesBrowsed)) {
        //Array.from doesn't work with async, use for...of... instead
        // Array.from(idFilesBrowsed).forEach(async file => {  
            const timestamp = Date.now();
            const newFilename = `Identity_${timestamp}_${file.name}`;
            file.s3Key = `workflow/otp/${newFilename}`;

            if (!file.uploaded) {
                //if already uploaded , do not upload again
                // uploadSingleFile(file).then(
                //     response => {
                //         if (response) {
                //             setIdFilesUploaded((prevFiles) => [
                //                 ...prevFiles,
                //                 file
                //             ])
                //         }

                //         file.uploaded = true;
                //     }
                // );

                const response = await uploadSingleFile(file);

                if (response) {
                    setIdFilesUploaded((prevFiles) => [
                        ...prevFiles,
                        file
                    ])

                    file.uploaded = true;
                }

                salesAdvice.idFiles.push({
                    filename: file.name,
                    s3Key: file.s3Key
                });
                
            }
        }
        // })

        salesAdvice.depositFiles = [];
        //Array.from doesn't work with async, use for...of... instead
        // Array.from(depositReceiptBrowsed).forEach(async file => {
        for (const file of Array.from(depositReceiptBrowsed)) {
            const timestamp = Date.now();
            const newFilename = `DepositReceipt_${timestamp}_${file.name}`;
            file.s3Key = `workflow/otp/${newFilename}`;

            if (!file.uploaded) {
                // uploadSingleFile(file).then(
                //     response => {
                //         // console.log(response);
                //         if (response) {
                //             setDepositReceiptUploaded((prevFiles) => [
                //                 ...prevFiles,
                //                 file
                //             ])

                //             file.uploaded = true;
                //         }
                //     }
                // );

                
                const response = await uploadSingleFile(file);

                if (response) {
                    setDepositReceiptUploaded((prevFiles) => [
                        ...prevFiles,
                        file
                    ])

                    file.uploaded = true;
                }

                salesAdvice.depositFiles.push({
                    filename: file.name,
                    s3Key: file.s3Key
                });

            }
        }
        // })
    }

    const uploadSingleFile = async (file) => {
        const presignedUrl = await getPresignedURL(file, false, false);

        const xhr = new XMLHttpRequest();
        const success = await new Promise((resolve) => {
            xhr.upload.addEventListener("progress", (event) => {
                if (event.lengthComputable) {
                    // $("#uploading-file-name").html(file.name);
                    // $("#upload-progress").html(((event.loaded / event.total) * 100).toFixed(0) + "%");
                    console.log("Uploading " + file.name + ", progress: " + ((event.loaded / event.total) * 100).toFixed(0) + "%")
                }
            });
            xhr.addEventListener("progress", (event) => {
                if (event.lengthComputable) {
                    // console.log("download progress:", event.loaded / event.total);
                    // $("#download-progress").html(((event.loaded / event.total) * 100).toFixed(0) + "%");
                }
            });
            xhr.addEventListener("loadend", () => {
                resolve(xhr.readyState === 4 && xhr.status === 200);
            });
            xhr.open("PUT", presignedUrl, true);
            // xhr.setRequestHeader("Content-Type", "multipart/form-data");
            xhr.setRequestHeader("Content-Type", file.type);
            // xhr.setRequestHeader("Content-Disposition", "inline; filename=\""+file.name+"\"");
            xhr.send(file);
        });

        return success;
    }


    const [propertyOptions, setPropertyOptions] = useState([]);

    useEffect(() => {

        const fetchProperties = () => {
            axios.get(`${process.env.REACT_APP_API_URL}/api/property/otp/fetch-all-active`)
                .then(response => {
                    if (response.status === 200) {
                        // console.log(response.data)
                        response.data.forEach(p => {

                            setPropertyOptions((prevProperties) => [
                                ...prevProperties,
                                {
                                    value: p.id,
                                    label: p.title + " (" + p.address + ")",
                                }
                            ])
                        })
                    }
                }).catch(error => {
                    console.log(error)
                });
        }

        fetchProperties()
    }, []);

    const onChange = (value) => {
        setSalesAdvice((prevData) => (
            {
                ...prevData,
                propertyId: parseInt(value),
            }
        ))
    };
    const onSearch = (value) => {
        // console.log('search:', value);
    };

    // Filter `option.label` match the user type `input`
    const filterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());




    const [isToastVisible, setIsToastVisible] = useState(false);
    const [toastType, setToastType] = useState("normal");
    const [toastMessage, setToastMessage] = useState("");

    const showToast = (type, message) => {
        setIsToastVisible(true);
        setToastType(type);
        setToastMessage(message);

        // Optionally, you can set a timeout to hide the toast after a certain duration
        setTimeout(() => {
            setIsToastVisible(false);
        }, 2000); // Hide the toast after 3 seconds (adjust as needed)
    };


    const [idFilesBrowsed, setIdFilesBrowsed] = useState([]);
    const [depositReceiptBrowsed, setDepositReceiptBrowsed] = useState([]);
    const handleFileUpload = (e) => {
        const { id, files } = e.target;

        if (id === "id-files") {
            setIdFilesBrowsed(files);
        } else if (id === "deposit-receipt") {
            setDepositReceiptBrowsed(files);
        }
    }


    const props1 = {
        beforeUpload: (file) => {
            if(!Util.isImage(file)) {
                showToast("error", "Invalid image format. (.jpg .png only)");
                return false;
            }

            //add code here to upload to a pre-signed s3 url
            const newFilename = `Signature_${file.uid}.png`;
            file.s3Key = `workflow/otp/${newFilename}`;
            uploadSingleFile(file).then(() => {
                //display the image
                showToast("success", "Uploaded");
                setSalesAdvice(prevData => (
                    {
                        ...prevData,
                        agentSignature: newFilename,
                    }
                ));

                getPresignedURL(file, true, true).then((url) => {
                    setAgentSignatureImg(url);
                })
                
            }).catch(() => {
                showToast("error", "Upload error! Please sign manually.")
            })

            return false;
        },
    };

    const props2 = {
        beforeUpload: (file) => {
            if(!Util.isImage(file)) {
                showToast("error", "Invalid image format. (.jpg .png only)");
                return false;
            }

            //add code here to upload to a pre-signed s3 url
            const newFilename = `Signature_${file.uid}.png`;
            file.s3Key = `workflow/otp/${newFilename}`;
            uploadSingleFile(file).then(() => {
                //display the image
                showToast("success", "Uploaded");

                setSalesAdvice(prevData => (
                    {
                        ...prevData,
                        purchaserSignature: newFilename,
                    }
                ));

                getPresignedURL(file, true, true).then((url) => {
                    setPurchaserSignatureImg(url);
                })
                
            }).catch(() => {
                showToast("error", "Upload error! Please sign manually.")
            })

            return false;
        },
    };

    return <>

        <Toast isVisible={isToastVisible} type={toastType} message={toastMessage} />

        <div className="static card w-fill bg-base-100 shadow-md">
            <form id="sales-advice-form">
                <div className="card-body">
                    <div className="grid gap-4 grid-cols-1">
                        <div className="font-semibold text-lg">
                            Property Details
                        </div>
                    </div>

                    <div className="grid gap-4 grid-cols-1">
                        <div>
                            <label className="font-medium">Project Name <span className="text-error">*</span></label>
                            {/* <select id="projectName" className={`py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500 mandatory`} onChange={onChangeHandler} value={salesAdvice.projectName} >
                                <option value="">-- Select --</option>
                                {properties.map((property) => (
                                    <option key={property.id} value={property.id}>
                                        {property.title} {"(" + property.address + ")"}
                                    </option>
                                ))}
                            </select> */}

                            <Select
                                showSearch
                                placeholder="Select a project"
                                optionFilterProp="children"
                                onChange={onChange}
                                onSearch={onSearch}
                                filterOption={filterOption}
                                options={propertyOptions}
                                className="w-full"
                                value={salesAdvice.propertyId}
                            />
                        </div>
                    </div>

                    {/* <div className="grid gap-4 grid-cols-1">
                        <div>
                            <label className="font-medium" htmlFor="address">Address</label>
                            <input type="text" id="address" onChange={onChangeHandler} placeholder="" className="mt-2 py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500" value={salesAdvice.address} />
                        </div>
                    </div> */}

                    <div className="grid gap-4 grid-cols-1 sm:grid-cols-2">
                        <div>
                            <label className="font-medium" htmlFor="lotNumber">Lot No. </label>
                            <input type="text" id="lotNumber" onChange={onChangeHandler} placeholder="" className="mt-2 py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500" value={salesAdvice.lotNumber} />
                        </div>
                        <div>
                            <label className="font-medium" htmlFor="unitNumber">Unit No.</label>
                            <input type="text" id="unitNumber" onChange={onChangeHandler} placeholder="" className="mt-2 py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500" value={salesAdvice.unitNumber} />
                        </div>
                        <div>
                            <label className="font-medium" htmlFor="configuration">Configuration</label>
                            <input type="text" id="configuration" onChange={onChangeHandler} placeholder="" className="mt-2 py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500" value={salesAdvice.configuration} />
                        </div>
                        <div>
                            <label className="font-medium" htmlFor="level">Level</label>
                            <input type="text" id="level" onChange={onChangeHandler} placeholder="" className="mt-2 py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500" value={salesAdvice.level} />
                        </div>
                        <div>
                            <label className="font-medium" htmlFor="carParking">Car Parking</label>
                            <input type="text" id="carParking" onChange={onChangeHandler} placeholder="" className="mt-2 py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500" value={salesAdvice.carParking} />
                        </div>
                        <div>
                            <label className="font-medium" htmlFor="storage">Storage</label>
                            <input type="text" id="storage" onChange={onChangeHandler} placeholder="" className="mt-2 py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500" value={salesAdvice.storage} />
                        </div>
                    </div>

                    <div className="grid gap-4 grid-cols-1 mt-5">
                        <div className="font-semibold text-lg">
                            Sale Details
                        </div>
                    </div>

                    <div className="grid gap-4 grid-cols-1 sm:grid-cols-2">
                        <div>
                            <label className="font-medium" htmlFor="price">Price <span className="text-error">*</span></label>
                            <input type="text" id="price" onChange={onChangeHandler} placeholder="" className="mt-2 py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500 mandatory" value={Util.formatPrice(salesAdvice.price)} />
                        </div>
                        <div>
                            <label className="font-medium" htmlFor="salesDate">Date of Sales <span className="text-error">*</span></label>
                            <input type="date" id="salesDate" onChange={onChangeHandler} placeholder="" className="mt-2 py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500 mandatory" value={Util.formatDateValue(salesAdvice.salesDate)} />
                        </div>
                    </div>

                    <div className="grid gap-4 grid-cols-1">
                        <div>
                            <label className='font-medium'>Purchasing Purpose <span className="text-error">*</span></label>
                            <div className="grid grid-cols-1 md:grid-cols-3">
                                <ConfigProvider
                                    theme={{
                                        components: {
                                            Checkbox: {
                                                colorPrimary: '#f15b22',
                                                colorPrimaryBorder: '#f15b22',
                                                colorPrimaryHover: '#f15b22',
                                                fontSize: 16,
                                            }
                                        },
                                    }}
                                >
                                    <Checkbox checked={salesAdvice.purchasePurpose === "First Home Buyer"} onChange={() => { setPurchasePurpose("First Home Buyer") }} >First Home Buyer</Checkbox>

                                    <Checkbox checked={salesAdvice.purchasePurpose === "Owner Occupy"} onChange={() => { setPurchasePurpose("Owner Occupy") }} >Owner Occupy</Checkbox>

                                    <Checkbox checked={salesAdvice.purchasePurpose === "Investor"} onChange={() => { setPurchasePurpose("Investor") }} >Investor</Checkbox>
                                </ConfigProvider>
                            </div>
                        </div>
                    </div>

                    <div className="grid gap-4 grid-cols-1">
                        <div>
                            <label className="font-medium">Financial Status <span className="text-error">*</span></label>
                            <div className="grid grid-cols-1 md:grid-cols-3">
                                <span className="text-gray-500">Is home loan approved?</span>
                                <ConfigProvider
                                    theme={{
                                        components: {
                                            Checkbox: {
                                                colorPrimary: '#f15b22',
                                                colorPrimaryBorder: '#f15b22',
                                                colorPrimaryHover: '#f15b22',
                                                fontSize: 16,
                                            }
                                        },
                                    }}
                                >
                                    <Checkbox checked={salesAdvice.homeLoanApproved === "true"} onChange={() => { setHomeLoanApproved("true") }}>Yes</Checkbox>

                                    <Checkbox checked={salesAdvice.homeLoanApproved === "false"} onChange={() => { setHomeLoanApproved("false") }}>No</Checkbox>
                                </ConfigProvider>
                            </div>
                        </div>
                    </div>

                    <div className="grid gap-4 grid-cols-1 mt-5">
                        <div className="font-semibold text-lg">
                            Purchaser 1
                        </div>
                    </div>
                    <div className="grid gap-4 grid-cols-1">
                        <div>
                            <label className="font-medium" htmlFor="purchaserName">Purchaser Name <span className="text-error">*</span></label>
                            <input type="text" id="purchaserName" onChange={onChangeHandler} placeholder="" className="mt-2 py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500 mandatory" value={salesAdvice.purchaserName} />
                        </div>
                    </div>
                    <div className="grid gap-4 grid-cols-1 md:grid-cols-3">
                        <div className="md:col-span-2">
                            <label className="font-medium" htmlFor="purchaserAddress">Purchaser Address <span className="text-error">*</span></label>
                            <input type="text" id="purchaserAddress" onChange={onChangeHandler} placeholder="" className="mt-2 py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500 mandatory" value={salesAdvice.purchaserAddress} />
                        </div>
                        <div>
                            <label className="font-medium" htmlFor="purchaserPostcode">Postcode <span className="text-error">*</span></label>
                            <input type="text" id="purchaserPostcode" onChange={onChangeHandler} placeholder="" className="mt-2 py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500 mandatory" value={salesAdvice.purchaserPostcode} />
                        </div>
                    </div>

                    <div className="grid gap-4 grid-cols-1 md:grid-cols-2">
                        <div>
                            <label className="font-medium" htmlFor="purchaserMobile">Mobile <span className="text-error">*</span></label>
                            <input type="text" id="purchaserMobile" onChange={onChangeHandler} placeholder="" className="mt-2 py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500 mandatory" value={salesAdvice.purchaserMobile} />
                        </div>
                        <div>
                            <label className="font-medium" htmlFor="purchaserEmail">Email <span className="text-error">*</span></label>
                            <input type="text" id="purchaserEmail" onChange={onChangeHandler} placeholder="" className="mt-2 py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500 mandatory" value={salesAdvice.purchaserEmail} />
                        </div>
                    </div>

                    <div className="grid gap-4 grid-cols-1">
                        <div>
                            <label className="font-medium">FIRB Status <span className="text-error">*</span></label>
                            <div className="grid grid-cols-1 md:grid-cols-3">
                                <ConfigProvider
                                    theme={{
                                        components: {
                                            Checkbox: {
                                                colorPrimary: '#f15b22',
                                                colorPrimaryBorder: '#f15b22',
                                                colorPrimaryHover: '#f15b22',
                                                fontSize: 16,
                                            }
                                        },
                                    }}
                                >
                                    <Checkbox checked={salesAdvice.purchaserFirbStatus === "Australian Citizen"} onChange={() => { setPurchaserFirbStatus("Australian Citizen") }}>Australian Citizen</Checkbox>

                                    <Checkbox checked={salesAdvice.purchaserFirbStatus === "Permanent Resident"} onChange={() => { setPurchaserFirbStatus("Permanent Resident") }}>Permanent Resident</Checkbox>

                                    <Checkbox checked={salesAdvice.purchaserFirbStatus === "Oversea Buyer"} onChange={() => { setPurchaserFirbStatus("Oversea Buyer") }}>Oversea Buyer</Checkbox>
                                </ConfigProvider>
                            </div>
                        </div>
                    </div>


                    <div className="grid gap-4 grid-cols-1 mt-5">
                        <div className="font-semibold text-lg">
                            Purchaser 2
                        </div>
                    </div>
                    <div className="grid gap-4 grid-cols-1">
                        <div>
                            <label className="font-medium" htmlFor="purchaser2Name">Purchaser Name</label>
                            <input type="text" id="purchaser2Name" onChange={onChangeHandler} placeholder="" className="mt-2 py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500" value={salesAdvice.purchaser2Name} />
                        </div>
                    </div>
                    <div className="grid gap-4 grid-cols-1 md:grid-cols-3">
                        <div className="md:col-span-2">
                            <label className="font-medium" htmlFor="purchaser2Address">Purchaser Address</label>
                            <input type="text" id="purchaser2Address" onChange={onChangeHandler} placeholder="" className="mt-2 py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500" value={salesAdvice.purchaser2Address} />
                        </div>
                        <div>
                            <label className="font-medium" htmlFor="purchaser2Postcode">Postcode</label>
                            <input type="text" id="purchaser2Postcode" onChange={onChangeHandler} placeholder="" className="mt-2 py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500" value={salesAdvice.purchaser2Postcode} />
                        </div>
                    </div>

                    <div className="grid gap-4 grid-cols-1 md:grid-cols-2">
                        <div>
                            <label className="font-medium" htmlFor="purchaser2Mobile">Mobile</label>
                            <input type="text" id="purchaser2Mobile" onChange={onChangeHandler} placeholder="" className="mt-2 py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500" value={salesAdvice.purchaser2Mobile} />
                        </div>
                        <div>
                            <label className="font-medium" htmlFor="purchaser2Email">Email</label>
                            <input type="text" id="purchaser2Email" onChange={onChangeHandler} placeholder="" className="mt-2 py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500" value={salesAdvice.purchaser2Email} />
                        </div>
                    </div>

                    <div className="grid gap-4 grid-cols-1">
                        <div>
                            <label className="font-medium">FIRB Status</label>
                            <div className="grid grid-cols-1 md:grid-cols-3">
                                <ConfigProvider
                                    theme={{
                                        components: {
                                            Checkbox: {
                                                colorPrimary: '#f15b22',
                                                colorPrimaryBorder: '#f15b22',
                                                colorPrimaryHover: '#f15b22',
                                                fontSize: 16,
                                            }
                                        },
                                    }}
                                >
                                    <Checkbox checked={salesAdvice.purchaser2FirbStatus === "Australian Citizen"} onChange={() => { setPurchaser2FirbStatus("Australian Citizen") }}>Australian Citizen</Checkbox>

                                    <Checkbox checked={salesAdvice.purchaser2FirbStatus === "Permanent Resident"} onChange={() => { setPurchaser2FirbStatus("Permanent Resident") }}>Permanent Resident</Checkbox>

                                    <Checkbox checked={salesAdvice.purchaser2FirbStatus === "Oversea Buyer"} onChange={() => { setPurchaser2FirbStatus("Oversea Buyer") }}>Oversea Buyer</Checkbox>
                                </ConfigProvider>
                            </div>
                        </div>
                    </div>


                    <div className="grid gap-4 grid-cols-1 mt-5">
                        <div className="font-semibold text-lg">
                            Purchaser Solicitor Details
                        </div>
                    </div>
                    <div className="grid gap-4 grid-cols-1 md:grid-cols-2">
                        <div>
                            <label className="font-medium" htmlFor="solicitorCompany">Company Name <span className="text-error">*</span></label>
                            <input type="text" id="solicitorCompany" onChange={onChangeHandler} placeholder="" className="mt-2 py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500 mandatory" value={salesAdvice.solicitorCompany} />
                        </div>
                        <div>
                            <label className="font-medium" htmlFor="solicitorName">Contact Name <span className="text-error">*</span></label>
                            <input type="text" id="solicitorName" onChange={onChangeHandler} placeholder="" className="mt-2 py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500 mandatory" value={salesAdvice.solicitorName} />
                        </div>
                    </div>
                    <div className="grid gap-4 grid-cols-1 md:grid-cols-3">
                        <div className="md:col-span-2">
                            <label className="font-medium" htmlFor="solicitorAddress">Addres <span className="text-error">*</span></label>
                            <input type="text" id="solicitorAddress" onChange={onChangeHandler} placeholder="" className="mt-2 py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500 mandatory" value={salesAdvice.solicitorAddress} />
                        </div>
                        <div>
                            <label className="font-medium" htmlFor="solicitorPostcode">Postcode <span className="text-error">*</span></label>
                            <input type="text" id="solicitorPostcode" onChange={onChangeHandler} placeholder="" className="mt-2 py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500 mandatory" value={salesAdvice.solicitorPostcode} />
                        </div>
                    </div>
                    <div className="grid gap-4 grid-cols-1">
                        <div>
                            <label className="font-medium" htmlFor="solicitorPoBox">PO Box</label>
                            <input type="text" id="solicitorPoBox" onChange={onChangeHandler} placeholder="" className="mt-2 py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500" value={salesAdvice.solicitorPoBox} />
                        </div>
                    </div>
                    <div className="grid gap-4 grid-cols-1 md:grid-cols-2">
                        <div>
                            <label className="font-medium" htmlFor="solicitorPhone">Phone</label>
                            <input type="text" id="solicitorPhone" onChange={onChangeHandler} placeholder="" className="mt-2 py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500" value={salesAdvice.solicitorPhone} />
                        </div>
                        <div>
                            <label className="font-medium" htmlFor="solicitorFax">Fax</label>
                            <input type="text" id="solicitorFax" onChange={onChangeHandler} placeholder="" className="mt-2 py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500" value={salesAdvice.solicitorFax} />
                        </div>
                    </div>
                    <div className="grid gap-4 grid-cols-1 md:grid-cols-2">
                        <div>
                            <label className="font-medium" htmlFor="solicitorMobile">Mobile <span className="text-error">*</span></label>
                            <input type="text" id="solicitorMobile" onChange={onChangeHandler} placeholder="" className="mt-2 py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500 mandatory" value={salesAdvice.solicitorMobile} />
                        </div>
                        <div>
                            <label className="font-medium" htmlFor="solicitorEmail">Email <span className="text-error">*</span></label>
                            <input type="text" id="solicitorEmail" onChange={onChangeHandler} placeholder="" className="mt-2 py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500 mandatory" value={salesAdvice.solicitorEmail} />
                        </div>
                    </div>

                    <div className="grid gap-4 grid-cols-1 mt-5">
                        <div className="font-semibold text-lg">
                            Deposit Details
                        </div>
                    </div>
                    <div className="grid gap-4 grid-cols-1 md:grid-cols-2">
                        <div>
                            <label className="font-medium" htmlFor="depositType">Type of Deposit</label>
                            <input type="text" id="depositType" onChange={onChangeHandler} placeholder="" className="mt-2 py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500" value={salesAdvice.depositType} />
                        </div>
                        <div>
                            <label className="font-medium" htmlFor="depositAmount">Amount Taken <span className="text-error">*</span></label>
                            <input type="text" id="depositAmount" onChange={onChangeHandler} placeholder="" className="mt-2 py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500 mandatory" value={Util.formatPrice(salesAdvice.depositAmount)} />
                        </div>
                    </div>
                    <div className="grid gap-4 grid-cols-1">
                        <div>
                            <label className="font-medium">Account Holding <span className="text-error">*</span></label>
                            <div className="grid grid-cols-1 md:grid-cols-2">
                                <ConfigProvider
                                    theme={{
                                        components: {
                                            Checkbox: {
                                                colorPrimary: '#f15b22',
                                                colorPrimaryBorder: '#f15b22',
                                                colorPrimaryHover: '#f15b22',
                                                fontSize: 16,
                                            }
                                        },
                                    }}
                                >
                                    <Checkbox onChange={() => { setAccountHolding("Developer Trust Account") }} checked={salesAdvice.accountHolding === "Developer Trust Account"}>Developer Trust Account</Checkbox>

                                    <Checkbox onChange={() => { setAccountHolding("PW Realty Trust Account") }} checked={salesAdvice.accountHolding === "PW Realty Trust Account"}>PW Realty Trust Account</Checkbox>

                                </ConfigProvider>
                            </div>
                        </div>
                    </div>

                    <div className="grid gap-4 grid-cols-1 mt-5">
                        <div className="font-semibold text-lg">
                            Special Condition / Terms of Purchase
                        </div>
                    </div>
                    <div className="grid gap-4 grid-cols-1">
                        <textarea id="specialCondition" className={`py-3 px-4 block w-full border border-solid border-gray-300 rounded-md text-sm focus:border-orange-500 h-36`} onChange={onChangeHandler} value={salesAdvice.specialCondition}></textarea>
                    </div>



                    <div className="grid gap-4 grid-cols-1 mt-5">
                        <div>
                            <span className="font-semibold text-lg">Signature</span>
                        </div>
                    </div>
                    <div className="grid gap-4 grid-cols-1 md:grid-cols-4">
                        <div className="md:col-span-4">
                            <label className="font-medium">Agent Signature </label>
                            {agentSignatureImg ? (
                                <>
                                <button className="link-pw text-sm ml-5" onClick={() => {
                                    setAgentSignatureImg(null);
                                    setSalesAdvice(prevData => (
                                        {
                                            ...prevData,
                                            agentSignature: "",
                                        }
                                    ));
                                }}>[Remove]</button>
                                <div className="bg-gray-200">
                                    <img
                                        className="max-w-xs"
                                        src={agentSignatureImg}
                                        alt="Agent Signature"
                                    />
                                </div>
                                </>
                            ) : (
                                <div className="grid gap-4 grid-cols-1 mt-4">
                                    <Upload {...props1} showUploadList={false} accept="image/png, image/jpeg">
                                        <Button icon={<UploadOutlined />}>Select File</Button>
                                    </Upload>

                                    <SignaturePad onSignatureChange={handleAgentSignatureChange} />
                                </div>
                                
                            )}

                        </div>
                        <div>
                            <label className="font-medium" htmlFor="agentSignatureDate">Date</label>
                            <input type="date" id="agentSignatureDate" onChange={onChangeHandler} placeholder="" className="mt-2 py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500" value={Util.formatDateValue(salesAdvice.agentSignatureDate)} />
                        </div>
                    </div>

                    <div className="grid gap-4 grid-cols-1 md:grid-cols-4 mt-5">
                        <div className="md:col-span-4">
                            <label className="font-medium">Purchaser Signature</label>
                            {purchaserSignatureImg ? (
                                <>
                                <button className="link-pw text-sm ml-5" onClick={() => {
                                    setPurchaserSignatureImg(null);
                                    setSalesAdvice(prevData => (
                                        {
                                            ...prevData,
                                            purchaserSignature: "",
                                        }
                                    ));
                                }}>[Remove]</button>
                                <div className="bg-gray-200">
                                    <img
                                        className="max-w-xs"
                                        src={purchaserSignatureImg}
                                        alt="Purchaser Signature"
                                    />
                                </div>
                                </>
                            ) : (
                                <div className="grid gap-4 grid-cols-1 mt-4">
                                    <Upload {...props2} id="purchaser-signature-file" showUploadList={false} accept="image/png, image/jpeg">
                                        <Button icon={<UploadOutlined />}>Select File</Button>
                                    </Upload>
                                    <SignaturePad onSignatureChange={handlePurchaserSignatureChange} />
                                </div>
                            )}
                        </div>
                        <div>
                            <label className="font-medium" htmlFor="purchaserSignatureDate">Date</label>
                            <input type="date" id="purchaserSignatureDate" onChange={onChangeHandler} placeholder="" className="mt-2 py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500" value={Util.formatDateValue(salesAdvice.purchaserSignatureDate)} />
                        </div>
                    </div>

                    <div className="grid gap-4 grid-cols-1 mt-5 text-md text-gray-400">
                        The deposit is refundable if the offer is not accepted.
                    </div>

                </div>

            </form>
        </div>


        <div className="static card w-fill bg-base-100 shadow-md">
            <div className="card-body">
                <div className="grid gap-4 grid-cols-1">
                    <div className="font-semibold text-lg">
                        Documents
                    </div>
                </div>

                <div className="grid gap-4 grid-cols-1">
                    <div className="mb-4">
                        <label className="font-medium ltr:text-left">Identity (Passport or Driver License) <span className="text-error">*</span></label>
                        <label htmlFor="id-files" className="sr-only">Choose file</label>
                        <input type="file" multiple name="file-input" id="id-files" className="block w-full border border-gray-200 shadow-sm rounded-md text-sm focus:z-10 focus:border-orange-500 focus:ring-orange-500
                                        file:bg-transparent file:border-0
                                        file:bg-gray-100 file:mr-4
                                        file:py-3 file:px-4
                                        "
                            onChange={handleFileUpload} />
                    </div>
                </div>
                {idFilesUploaded.length > 0 && (
                    <div className="mb-4">
                        <ul className="space-y-3 text-sm">
                            {idFilesUploaded.map((thisfile, ndx) => (
                                <li key={"id-file-" + ndx} onClick={() => { downloadFile(thisfile) }} className="flex space-x-3 items-center cursor-pointer">
                                    <i className="ri-attachment-2 text-lg text-orange-500"></i>
                                    <span className="text-gray-800 link-pw">
                                        {thisfile.name}
                                    </span>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}


                <div className="grid gap-4 grid-cols-1">
                    <div className="mb-4">
                        <label className="font-medium ltr:text-left">Deposit Receipt <span className="text-error">*</span></label>
                        <label htmlFor="deposit-receipt" className="sr-only">Choose file</label>
                        <input type="file" multiple name="file-input" id="deposit-receipt" className="block w-full border border-gray-200 shadow-sm rounded-md text-sm focus:z-10 focus:border-orange-500 focus:ring-orange-500
                                        file:bg-transparent file:border-0
                                        file:bg-gray-100 file:mr-4
                                        file:py-3 file:px-4
                                        "
                            onChange={handleFileUpload} />
                    </div>
                </div>
                {depositReceiptUploaded.length > 0 && (
                    <div className="mb-4">
                        <ul className="space-y-3 text-sm">
                            {depositReceiptUploaded.map((thisfile, ndx) => (
                                <li key={"id-file-" + ndx} onClick={() => { downloadFile(thisfile) }} className="flex space-x-3 items-center cursor-pointer">
                                    <i className="ri-attachment-2 text-lg text-orange-500"></i>
                                    <span className="text-gray-800 link-pw">
                                        {thisfile.name}
                                    </span>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}


            </div>
        </div>

        <div className="space-x-5 m-5">
            <PWButton buttonText={"Save"} onClick={saveSalesAdvice} disabled={isSaving} />
            <PWButton buttonText={"Submit"} onClick={submitSalesAdvice} disabled={isSubmitting} />
        </div>
    </>
}