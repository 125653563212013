import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Skeleton } from 'antd';
import { getPresignedURL } from "../functions/common/file-upload"



export default function PortfolioResaleDetail() {

    const { propertyId } = useParams();

    const [property, setProperty] = useState({});

    const [coverImgSignedUrl, setCoverImgSignedUrl] = useState("")

    useEffect(() => {
        document.title = "Portfolio Detail"
    }, [])

    useEffect(() => {

        document.title = "Portfolio Detail"

        const getCoverImage = async (file) => {
            const presignedUrl = await getPresignedURL(file, true, true);
            return presignedUrl;
        }

        const fetchProperty = async (id) => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/property/resale/fetch/${id}`);
                if (response.status === 200) {
                    // console.log(response.data)
                    setProperty(response.data)

                    const file = {
                        name: "cover-image.jpg",
                        s3Key: `property/original/${response.data.coverImage}`
                    }

                    getCoverImage(file).then((url) => {
                        setCoverImgSignedUrl(url)
                    })
                }
            } catch (error) {
                console.log(error);
            }
        }

        fetchProperty(propertyId)

    }, [propertyId])


    if (!property.id > 0) {
        return (
            <div>
                <Header currentPage="portfolio" />

                <div className="main-container">
                    <div className="mx-auto px-5 mt-10 md:px-10">
                        <Skeleton active />
                    </div>
                </div>

                <Footer />
            </div>
        )
    }


    return (
        <div>
            <Header currentPage="portfolio" />

            <div className="main-container">

                <div className="mx-auto px-5 mt-10 md:px-10">

                    <div className={`static card w-fill bg-base-100 shadow-md mb-10`}>


                        {coverImgSignedUrl ? (
                            <div className="image-container" style={{ maxHeight: '500px', overflow: 'hidden', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <img className="w-full h-auto rounded-t-xl sm:block" src={coverImgSignedUrl} alt=""/>
                            </div>
                        ) : (
                            <Skeleton.Image active={true} className="!w-full !h-52" />
                        )}

                        <div className="card-body">

                            <div className="grid gap-4 grid-cols-1">
                                <div className="flex flex-wrap items-center">
                                    <div className="md: mr-10">
                                        <div className="font-semibold">
                                            {property.address + " " + property.suburb + " " + property.state + " " + property.postcode}
                                        </div>
                                    </div>
                                </div>

                                <div className="flex justify-between mt-5">
                                    <div className="text-pw text-lg font-semibold">
                                        <h3>Property Features</h3>
                                    </div>
                                </div>
                                <hr className="" />
                                <div className="mb-4">
                                    <i className="ri-user-3-line text-pw mr-2"></i>
                                    <span className="">Selling Agent: {property.sellingAgent}</span>
                                </div>


                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}