import React, { useEffect, useState, useRef } from "react";
import Toast from "../Toast";
import { Input, InputNumber, Select, Checkbox } from "antd";
import { getPresignedURL, downloadFile, isImage } from "../../functions/common/file-upload"
import Button from "../Button";
import axios from "axios";


export default function ResaleSellingInfo({ workflow, updateWorkflow }) {

    const [formData, setFormData] = useState({
        id: 0,
        status: 0,
        propertyId: 0,
        vendor: "",
        marketingFee: "",
        listingReferrer: "",
        listingReferrerCommRate: 0,
        sellingAgent: "",
        listingAgent: "",
        commissionRate: "",
        expense: "",
        buyerName: "",
        contractPrice: "",
        exchangeDate: "",
        estSettlementDate: "",
        insertBy: "",
        insertTimestamp: "",
        lastUpdateBy: "",
        lastUpdateTimestamp: "",
        property: {
            id: 0,
            status: 0,
            address: "",
            suburb: "",
            state: "",
            postcode: "",
            coverImage: "",
        },
    });

    useEffect(() => {
        if (workflow?.id > 0) {
            setFormData(workflow);
        }
    }, [workflow])


    const [users, setUsers] = useState([]);
    useEffect(() => {
        const fetchUsers = () => {
            axios.get(`${process.env.REACT_APP_API_URL}/api/user/all-users`)
                .then(response => {
                    if (response.status === 200) {
                        //login success redirect to dashboard
                        if (response.data != null) {
                            // console.log(response.data)
                            const activeUsers = response.data.filter((user) => user.status === 1);
                            setUsers(activeUsers);
                        }
                    }
                })
                .catch(error => { });
        }

        fetchUsers();
    }, [])

    const referralCommRef = useRef(null);

    const onChangeHandler = (e) => {
        const { id, value } = e.target;

        if ( id === "listingReferrer") {
            //if not empty then show referral commission selection
            referralCommRef.current.style.display = value === "" ? "none" : "block";
            setShowReferralFilesUpload(value !== "");
        }

        setFormData((prevData) => (
            {
                ...prevData,
                [id]: value,
            }
        ))
    }

    const onPropertyChangeHandler = (e) => {
        const { id, value } = e.target;
        setFormData((prevData) => (
            {
                ...prevData,
                property: {
                    ...prevData.property,
                    [id]: value,
                }
            }
        ))

        setValidInputs( prevData => (
            {
                ...prevData,
                [id]: value !== "",
            }
        ))
    }

    const [isToastVisible, setIsToastVisible] = useState(false);
    const [toastType, setToastType] = useState("normal");
    const [toastMessage, setToastMessage] = useState("");

    const showToast = (type, message) => {
        setIsToastVisible(true);
        setToastType(type);
        setToastMessage(message);

        // Optionally, you can set a timeout to hide the toast after a certain duration
        setTimeout(() => {
            setIsToastVisible(false);
        }, 2000); // Hide the toast after 3 seconds (adjust as needed)
    };


    const handleCoverImageUpload = (e) => {
        const coverImageOrignalFile = e.target.files[0];

        if(!isImage(coverImageOrignalFile)){
            showToast("error", "Invalid image file.")
            return;
        }

        //rename the file to a unique name with timestamp
        const timestamp = Date.now();
        const newFilename = `CoverImage_${timestamp}_${coverImageOrignalFile.name}`;

        if (coverImageOrignalFile) {
            coverImageOrignalFile.s3Key = `property/original/${newFilename}`;

            //handle concurrent state changes, use functional update
            setFormData((prevData) => ({
                ...prevData,
                property: {
                    ...prevData.property,
                    coverImage: newFilename,
                }
            }))

            uploadSingleFile(coverImageOrignalFile);

        }

        // Usage example:
        createThumbnail(coverImageOrignalFile)
            .then((thumbnailFile) => {

                thumbnailFile.s3Key = `property/thumbnail/${newFilename}`;

                uploadSingleFile(thumbnailFile);

            })
            .catch((error) => {
                console.error("Error creating thumbnail:", error);
            });
    }

    const createThumbnail = (originalFile) => {
        return new Promise((resolve, reject) => {
            const originalImage = new Image();

            originalImage.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                const thumbnailWidth = 400; // Set the desired width for the thumbnail
                const thumbnailHeight = (originalImage.height / originalImage.width) * thumbnailWidth; // Maintain aspect ratio

                canvas.width = thumbnailWidth;
                canvas.height = thumbnailHeight;

                // Draw the original image on the canvas (resized)
                ctx.drawImage(originalImage, 0, 0, thumbnailWidth, thumbnailHeight);

                // Convert the canvas to a Blob
                canvas.toBlob((blob) => {
                    if (!blob) {
                        reject(new Error("Failed to create thumbnail Blob"));
                        return;
                    }

                    // Create a File from the Blob
                    const thumbnailFile = new File([blob], 'thumbnail.jpg', { type: 'image/jpeg' }); // Change the filename and 'type' as needed

                    // Resolve the Promise with the thumbnail file
                    resolve(thumbnailFile);
                }, 'image/jpeg');
            };

            originalImage.onerror = (error) => {
                reject(error);
            };

            originalImage.src = URL.createObjectURL(originalFile);
        });
    };



    const uploadSingleFile = async (file) => {
        const presignedUrl = await getPresignedURL(file, false, false);

        const xhr = new XMLHttpRequest();
        const success = await new Promise((resolve) => {
            xhr.upload.addEventListener("progress", (event) => {
                if (event.lengthComputable) {
                    // $("#uploading-file-name").html(file.name);
                    // $("#upload-progress").html(((event.loaded / event.total) * 100).toFixed(0) + "%");
                    // console.log("Uploading " + file.name + ", progress: " + ((event.loaded / event.total) * 100).toFixed(0) + "%")
                }
            });
            xhr.addEventListener("progress", (event) => {
                if (event.lengthComputable) {
                    // console.log("download progress:", event.loaded / event.total);
                    // $("#download-progress").html(((event.loaded / event.total) * 100).toFixed(0) + "%");
                }
            });
            xhr.addEventListener("loadend", () => {
                resolve(xhr.readyState === 4 && xhr.status === 200);
            });
            xhr.open("PUT", presignedUrl, true);
            // xhr.setRequestHeader("Content-Type", "multipart/form-data");
            xhr.setRequestHeader("Content-Type", file.type);
            // xhr.setRequestHeader("Content-Disposition", "inline; filename=\""+file.name+"\"");
            xhr.send(file);
        });

        return success;
    }

    const [isSaving, setIsSaving] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [referralFilesUploaded, setReferralFilesUploaded] = useState([]);

    const uploadWorkflowDocs = () => {

        Array.from(referralFiles).forEach(file => {
            if (!file.uploaded) {
                //if already uploaded , do not upload again
                const timestamp = Date.now();
                const newFilename = `RA_${timestamp}_${file.name}`;
                file.s3Key = `workflow/resale/${newFilename}`;

                uploadSingleFile(file).then(
                    response => {
                        if (response) {
                            setReferralFilesUploaded((prevFiles) => [
                                ...prevFiles,
                                file
                            ]);
                        }
                        file.uploaded = true;
                    }
                );

                formData["referralFiles"] = [];
                formData.referralFiles.push({
                    filename: file.name,
                    s3Key: file.s3Key,
                })
            }
        })
    }


    const handleSave = () => {
        setIsSaving(true);

        uploadWorkflowDocs();

        formData.status = 0;

        formData.referrerCommInclGst = referrerCommAmountChecked

        console.log(formData)
        axios.post(`${process.env.REACT_APP_API_URL}/api/workflow/resale/create`, formData, {
            headers: {
                'Content-Type': 'application/json', // Specify JSON content type
            },
        })
            .then((response) => {
                // console.log(response);
                setFormData(response.data);

                setIsSaving(false);


                showToast("success", "Saved successfully!")
            })
            .catch((error) => {
                console.log(error);
                setIsSaving(false);


                showToast("error", "Error!")
            })
    }


    const [validInputs, setValidInputs] = useState({
        sellingAgent: workflow?.sellingAgent > 0,
        listingAgent: workflow?.listingAgent > 0,
        commissionRate: workflow?.commissionRate > 0,
        expense: workflow?.expense !== "",
        address: workflow?.address !== "",
        suburb: workflow?.suburb !== "",
        state: workflow?.state !== "",
        postcode: workflow?.postcode !== "",
    });
    const handleSubmit = () => {

        const isAnyInvalid = Object.values(validInputs).includes(false);

        if (isAnyInvalid) {
            showToast("error", "Please fill in all mandatory fields.");
            return;
        }

        if(formData.property.coverImage === "" ) {
            showToast("error", "Please upload a cover image.");
            return;
        }

        if (formData.listingReferrer === "") {
            formData.listingReferrerCommRate = 0;
        }
        if (formData.listingReferrerCommRate > 0 && referralFiles.length === 0 && referralFilesUploaded.length === 0) {
            showToast("error", "Referral agreement form required.");
            return;
        }

        setIsSubmitting(true);

        formData.status = 1;

        formData.referrerCommInclGst = referrerCommAmountChecked

        uploadWorkflowDocs();

        // console.log(formData)
        axios.post(`${process.env.REACT_APP_API_URL}/api/workflow/resale/create`, formData, {
            headers: {
                'Content-Type': 'application/json', // Specify JSON content type
            },
        })
            .then((response) => {
                 console.log(response);
                setFormData(response.data);

                setIsSubmitting(false);

                showToast("success", "Saved successfully!");

                updateWorkflow(response.data);
            })
            .catch((error) => {
                console.log(error);
                setIsSubmitting(false);

                showToast("error", "Error!")
            })
    }


    const filterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());


    const [showReferralFilesUpload, setShowReferralFilesUpload] = useState(workflow?.listingReferrer !== "" && workflow?.listingReferrerCommRate > 0);
    const handleReferralRateChange = (val) => {
        setFormData({ ...formData, listingReferrerCommRate: val });
        if (val > 0) {
            setShowReferralFilesUpload(true);
        } else {
            setShowReferralFilesUpload(false);
        }
    }

    const [referralFiles, setReferralFiles] = useState([]);
    const handleFileUpload = (e) => {
        const { id, files } = e.target;

        if (id === "referral-files") {
            setReferralFiles(files);
        }
    }


    useEffect(() => {
        const fetchWorkflowDocs = (id) => {
            if (!id) {
                return;
            }

            axios.get(`${process.env.REACT_APP_API_URL}/api/workflow/resale/fetch-docs`,
            {
                params: {
                    workflowId: id,
                    fileType: "referral agreement",
                }, // Pass the search data as query parameters
            }).then(response => {
                if (response.status === 200) {
                    const files = response.data;
                    files.forEach((file) => {
                        setReferralFilesUploaded(prevData => [
                            ...prevData,
                            {
                                name: file.filename,
                                s3Key: file.s3Key
                            }
                        ])
                    })
                }
            }).catch(error => {
                console.log(error)
            });
        }

        fetchWorkflowDocs(workflow?.id);
    }, [workflow?.id])

    const [referrerCommAmountChecked, setReferrerCommAmountChecked] = useState(workflow.referrerCommInclGst)

    const setReferrerCommCheck = () => {
        setReferrerCommAmountChecked(!referrerCommAmountChecked)
    }

    return <>

        <Toast isVisible={isToastVisible} type={toastType} message={toastMessage} />

        <div className="static card w-fill bg-base-100 shadow-md">
            <form id="resale-workflow-form">
                <div className="card-body">
                    <div className="grid gap-4 grid-cols-1">
                        <div className="font-semibold text-lg">
                            Selling Information
                        </div>
                    </div>

                    <div className="grid gap-4 grid-cols-1 md:grid-cols-3">
                        <div className="mb-4">
                            <label className="font-medium" htmlFor="vendor">Vendor</label>
                            <Input id="vendor" value={formData.vendor} onChange={onChangeHandler} />
                        </div>

                        <div className="mb-4">
                            <label className="font-medium" htmlFor="marketingFee">Marketing Fee $ (Incl. GST) <span className="text-error">*</span></label>
                            <InputNumber
                                id="marketingFee"
                                className="w-full"
                                value={formData.marketingFee}
                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                onChange={(value) => {
                                    setFormData({ ...formData, marketingFee: value })
                                    setValidInputs( prevData => (
                                        {
                                            ...prevData,
                                            marketingFee: value >= 0,
                                        }
                                    ))
                                }}
                            />
                        </div>
                    </div>

                    <div className="grid gap-4 grid-cols-1 md:grid-cols-3">
                        <div className="mb-4">
                            <label className="font-medium" htmlFor="listingReferrer">Listing Referrer</label>
                            <Input id="listingReferrer" value={formData.listingReferrer} onChange={onChangeHandler} />
                        </div>

                        <div className={`mb-4 ${formData.listingReferrer===""?"hidden":""}`} ref={referralCommRef}>
                            <label className="font-medium" htmlFor="listingReferrerCommRate">Referral Commission Rate &nbsp;&nbsp;&nbsp; <Checkbox defaultChecked={referrerCommAmountChecked} onChange={setReferrerCommCheck}>Incl. GST</Checkbox></label>
                            <Select
                                id="listingReferrerCommRate"
                                className="w-full"
                                showSearch
                                placeholder=""
                                optionFilterProp="children"
                                onChange={handleReferralRateChange}
                                options={
                                    [
                                        { value: "0", label: "0%" },
                                        { value: "20", label: "20%" },
                                        { value: "25", label: "25%" },
                                        { value: "30", label: "30%" },
                                    ]
                                }
                                filterOption={filterOption}
                                value={`${formData.listingReferrerCommRate>=0?formData.listingReferrerCommRate+"%":""}`}
                            />
                        </div>
                    </div>

                    {showReferralFilesUpload && (
                        <div className="grid gap-4 grid-cols-1">
                            <div className="mb-4">
                                <label className="font-medium ltr:text-left">Referral Agreement Form <span className="text-error">*</span></label>
                                <label htmlFor="referral-files" className="sr-only">Choose file</label>
                                <input type="file" multiple name="referral-files" id="referral-files" className="block w-full border border-gray-200 shadow-sm rounded-md text-sm focus:z-10 focus:border-orange-500 focus:ring-orange-500
                                            file:bg-transparent file:border-0
                                            file:bg-gray-100 file:mr-4
                                            file:py-3 file:px-4
                                            "
                                    onChange={handleFileUpload} />
                            </div>
                        </div>
                    )}

                    {referralFilesUploaded.length > 0 && (
                        <div className="mb-4">
                            <ul className="space-y-3 text-sm">
                                {referralFilesUploaded.map((thisfile, ndx) => (
                                    <li key={"id-file-" + ndx} onClick={() => { downloadFile(thisfile) }} className="flex space-x-3 items-center cursor-pointer">
                                        <i className="ri-attachment-2 text-lg text-orange-500"></i>
                                        <span className="text-gray-800 link-pw">
                                            {thisfile.name}
                                        </span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}


                    <div className="grid gap-4 grid-cols-1 md:grid-cols-3">
                        <div className="mb-4">
                            <label className="font-medium" htmlFor="sellingAgent">Selling Agent <span className="text-error">*</span></label>
                            <br />
                            <Select
                                id="sellingAgent"
                                className="w-full"
                                showSearch
                                placeholder=""
                                optionFilterProp="children"
                                onChange={(value) => {
                                    setFormData({ ...formData, sellingAgent: value })
                                    setValidInputs( prevData => (
                                        {
                                            ...prevData,
                                            sellingAgent: value > 0,
                                        }
                                    ))
                                }}
                                filterOption={filterOption}
                                options={
                                    users.map(user => (
                                        { value: user.id, label: user.firstName + " " + user.lastName + " (" + user.emailAddress + ")" }
                                    ))
                                }
                                value={formData.sellingAgent}
                            />
                        </div>

                        <div className="mb-4">
                            <label className="font-medium" htmlFor="listingAgent">Listing Agent <span className="text-error">*</span></label>
                            <br />
                            <Select
                                id="listingAgent"
                                className="w-full"
                                showSearch
                                placeholder=""
                                optionFilterProp="children"
                                onChange={(value) => {
                                    setValidInputs( prevData => (
                                        {
                                            ...prevData,
                                            listingAgent: value > 0,
                                        }
                                    ))
                                    setFormData({ ...formData, listingAgent: value })
                                }}
                                filterOption={filterOption}
                                options={
                                    users.map(user => (
                                        { value: user.id, label: user.firstName + " " + user.lastName + " (" + user.emailAddress + ")" }
                                    ))
                                }
                                value={formData.listingAgent}
                            />
                        </div>
                    </div>

                    <div className="grid gap-4 grid-cols-1 md:grid-cols-3">
                        <div className="mb-4">
                            <label className="font-medium" htmlFor="commissionRate">Commission % (Incl. GST) <span className="text-error">*</span></label>
                            <InputNumber
                                id="commissionRate"
                                className="w-full"
                                value={formData.commissionRate}
                                min={0}
                                max={100}
                                //formatter={(value) => value==="" ? "" : `${value}%`}
                                parser={(value) => value.replace('%', '')}
                                onChange={(value) => {
                                    setFormData({ ...formData, commissionRate: value })
                                    setValidInputs( prevData => (
                                        {
                                            ...prevData,
                                            commissionRate: value > 0,
                                        }
                                    ))
                                }}
                            />
                        </div>

                        <div className="mb-4">
                            <label className="font-medium" htmlFor="expense">Other Expense $ (Incl. GST) <span className="text-error">*</span></label>
                            <InputNumber
                                id="expense"
                                className="w-full"
                                value={formData.expense}
                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                onChange={(value) => {
                                    setFormData({ ...formData, expense: value })
                                    setValidInputs( prevData => (
                                        {
                                            ...prevData,
                                            expense: value >= 0,
                                        }
                                    ))
                                }}
                            />
                        </div>
                    </div>

                    <div className="grid gap-4 grid-cols-1 mt-5">
                        <div className="font-semibold text-lg">
                            Property Details
                        </div>
                    </div>
                    <div className="grid gap-4 grid-cols-1">
                        <label className="font-medium">Address <span className="text-error">*</span></label>
                    </div>
                    <div className="grid gap-4 grid-cols-1 md:grid-cols-5">
                        <div className="md:col-span-2 mb-4">
                            <Input id="address" value={formData.property.address} onChange={onPropertyChangeHandler} />
                            <p className="text-sm text-gray-400">Unit & Street</p>
                        </div>
                        <div className="mb-4">
                            <Input id="suburb" value={formData.property.suburb} onChange={onPropertyChangeHandler} />
                            <p className="text-sm text-gray-400">Suburb</p>
                        </div>
                        <div className="mb-4">
                            <Select
                                id="state"
                                className="w-full"
                                onChange={(value) => {
                                    setFormData((prevData) => ({
                                        ...prevData,
                                        property: {
                                            ...prevData.property,
                                            state: value,
                                        }
                                    }))

                                    setValidInputs( prevData => (
                                        {
                                            ...prevData,
                                            state: value !== "",
                                        }
                                    ))
                                }}
                                options={[
                                    { value: 'NSW', label: 'NSW' },
                                    { value: 'VIC', label: 'VIC' },
                                    { value: 'QLD', label: 'QLD' },
                                    { value: 'ACT', label: 'ACT' },
                                    { value: 'WA', label: 'WA' },
                                    { value: 'SA', label: 'SA' },
                                    { value: 'NT', label: 'NT' },
                                    { value: 'TAS', label: 'TAS' },
                                ]}
                                value={formData.property.state}
                            />
                            <p className="text-sm text-gray-400">State</p>
                        </div>
                        <div className="mb-4">
                            <Input id="postcode" value={formData.property.postcode} className="mandatory" onChange={onPropertyChangeHandler} />
                            <p className="text-sm text-gray-400">Postcode</p>
                        </div>
                    </div>

                    <div className="grid gap-4 grid-cols-1 md:grid-cols-3">
                        <div className="mb-4">
                            <label className="font-medium ltr:text-left">Cover Image <span className="text-error">*</span></label>
                            <label htmlFor="file-input" className="sr-only">Choose file</label>
                            <input type="file" name="file-input" id="file-input" className="block w-full border border-gray-200 shadow-sm rounded-md text-sm focus:z-10 focus:border-orange-500 focus:ring-orange-500
                            file:bg-transparent file:border-0
                            file:bg-gray-100 file:mr-4
                            file:py-3 file:px-4
                            "
                                onChange={handleCoverImageUpload} />
                        </div>
                    </div>
                    {workflow.property?.coverImage && workflow.property?.coverImage !== "" && (
                        <span className="link-pw cursor-pointer" onClick={ async () => {
                            const file = {
                                name: "cover-image.jpg",
                                s3Key: `property/original/${workflow.property.coverImage}`
                            }
                            const url = await getPresignedURL(file, true, true);
                            // console.log("presignedUrl", url)
                            const newTab = window.open(url, '_blank');
                            // Ensure the new tab was successfully opened (not blocked by a popup blocker)
                            if (newTab) {
                            // You can optionally focus the new tab
                                newTab.focus();
                            } else {
                            // Handle the case where the new tab was blocked by a popup blocker
                                console.error('The new tab was blocked by a popup blocker.');
                            }
                        }}>
                            <i className="ri-attachment-2 text-lg text-pw"></i>
                            {workflow.property.coverImage}
                        </span>
                    )}
                </div>
            </form>
        </div>

        <div className="space-x-5 m-5">
            <Button buttonText={"Save"} onClick={handleSave} disabled={isSaving} />
            <Button buttonText={"Submit"} onClick={handleSubmit} disabled={isSubmitting} />
        </div>
    </>
}