import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { isLoginUserAdmin } from "../functions/common/manage-login-user";
import CreatePortfolio from "./CreatePortfolio";
import axios from "axios";


export default function PortfolioOtpEdit() {
    const { propertyId } = useParams();

    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Edit Portfolio"
    }, [])

    useEffect(() => {
        const hasAccess = isLoginUserAdmin();
        if (!hasAccess) {
            navigate("/login")
            return;
        }

    }, [navigate])

    const[property, setProperty] = useState(null);

    useEffect(() => {
        const fetchProperty = (id) => {
            axios.get(`${process.env.REACT_APP_API_URL}/api/property/otp/fetch/${id}`)
                .then(response => {
                    if (response.status === 200) {
                        setProperty(response.data)
                    }
                }).catch(error => {

                });

        }

        fetchProperty(propertyId)

    }, [propertyId])

    if (property === null) {
        // Token validation is in progress, you can show a loading indicator or do something else here
        return (
            
            <div className="grid grid-cols-1 gap-4 place-content-center text-center">
                <div className='flex flex-col justify-center items-center'>
                    {/* <i className="ri-home-7-fill text-orange-600 text-5xl"></i> */}
                    <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-orange-500"></div>
                </div>
            </div>
        );
    }

    return (
        <div>
            <CreatePortfolio property={property} />
        </div>
    )
}