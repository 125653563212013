import React, { useState } from "react";
import { Space, Card, Table, Tag, Popover, Modal, Checkbox, InputNumber } from "antd";
import { QuestionCircleOutlined, EditOutlined } from '@ant-design/icons';
import Button from "../Button";
import { formatPrice } from "../../functions/common/util";
import { isLoginUserAdmin, isLoginUserManager } from "../../functions/common/manage-login-user";
import Toast from "../Toast";
import axios from "axios";
import Decimal from "decimal.js";


export default function CommissionAllocationResale(props) {

    const [commissionResale, setCommissionResale] = useState(props.commissionResale);
    const [commissionAllocationResales, setCommissionAllocationResales] = useState(props.commissionResale.commissionAllocationResales);


    const [updateField, setUpdateField] = useState({
        id: commissionResale.id,
        contractPrice: commissionResale.contractPrice,
        commissionRate: commissionResale.commissionRate,
        customerRebate: commissionResale.customerRebateAgent,
        expense: commissionResale.expense,
        referralCommInclGst: commissionResale.referralCommInclGst,
        totalCommissionInclGst: commissionResale.totalCommissionInclGst,
        netCommissionInclGst: commissionResale.netCommissionInclGst,
        commissionAllocationResales: {},
    });


    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const handleCancel = () => {
        setIsEditModalOpen(false);
    }


    const columns = [
        {
            title: 'Contract Price',
            dataIndex: 'contractPrice',
            key: 'contractPrice',
        },
        {
            title: 'Commission Rate',
            dataIndex: 'commissionRate',
            key: 'commissionRate',
        },
        {
            title: 'Customer Rebate',
            dataIndex: 'customerRebate',
            key: 'customerRebate',
        },
        {
            title: 'Expense',
            dataIndex: 'expense',
            key: 'expense',
        },
    ]

    if (isLoginUserAdmin()) {
        columns.push(
            {
                title: 'Action',
                key: 'action',
                render: (_, record) => {
                    if (isLoginUserAdmin()) {
                        return <div className="link-pw cursor-pointer" onClick={() => { setIsEditModalOpen(true) }}>
                            <EditOutlined /> Edit
                        </div>
                    } else {
                        return ""
                    }
                }
            },
        )
    }

    //todo - display the rate and cash amount differently for sales (consider cap)
    let gstRate = new Decimal(1.1);
    let customerRebateStr = '-'
    let customerRebateAgent = new Decimal(commissionResale.customerRebateAgent);
    if(customerRebateAgent > 0) {
        if (commissionResale.workflowResale.customerRebateInclGst) {
            customerRebateStr = "$" + formatPrice(customerRebateAgent) + " (incl. GST)"
        } else {
            let customerRebateExclGst = customerRebateAgent.dividedBy(gstRate).toDecimalPlaces(2)
            customerRebateStr = "$" + formatPrice(customerRebateExclGst) + " (excl. GST)"
        }
    }
    const data = [
        {
            key: '1',
            contractPrice: `$${formatPrice(commissionResale.contractPrice)}`,
            commissionRate: `${commissionResale.commissionRate}% (incl. GST)`,
            customerRebate: commissionResale.customerRebateAgent > 0 ? customerRebateStr : "-",//`$${formatPrice(commissionResale.customerRebateAgent)}`,
            expense: `$${formatPrice(commissionResale.expense)} (incl. GST)`,
        },
    ];


    const [updating, setUpdating] = useState(false);

    const [resendEmail, setResendEmail] = useState(false);
    const handleResendEmailCheck = (e) => {
        setResendEmail(e.target.checked)
    }


    let columns_allocation = [
        {
            title: "Name",
            dataIndex: "labelName",
            key: "labelName",
        },
        {
            title: "Role",
            dataIndex: "role",
            key: "role",
        },
        {
            title: "Rate",
            dataIndex: "level",
            key: "level",
        },
        {
            title: "GST",
            dataIndex: "gst",
            key: "gst",
        },
        {
            title: "Amount (Excl. GST)",
            dataIndex: "amountExclGst",
            key: "amountExclGst",
        },
        {
            title: "Amount (Incl. GST)",
            dataIndex: "amount",
            key: "amount",
        },
    ]

    let data_allocation = [];
    commissionAllocationResales.forEach((comm, ndx) => {
        if (!comm.viewable) {
            return "";
        }

        let thisCommData = {
            key: `comm-al-${ndx}`,
            firstName: comm.user?.firstName,
            middleName: comm.user?.middleName,
            lastName: comm.user?.lastName,
            entityName: comm.user?.entityName,
            role: comm.role,
            level: `${comm.rate}%`,
            gst: <Tag color={(comm.user === null || comm.user.gstStatus) ? "green" : "red"}>{(comm.user === null || comm.user.gstStatus) ? "Yes" : "No"}</Tag>,
            amountExclGst: <>
                { (comm.user === null || comm.user.gstStatus) ? (
                    <div>&mdash;</div>
                ) : (
                    (isLoginUserManager() || isLoginUserAdmin()) ? (
                        // show calculation
                        //if the commission setting is changed
                        //all parties commission will be showing "edited"
                        //if setting not changed, only changed some party, then only those changed party will display 'Edited'
                        //because once setting is changed, the formula will not make any sense (eg. if i change commission rate)
                        <Popover content={commissionResale.lastUpdateBy === 0 && comm.lastUpdateBy === 0 ? ("(" + comm.formula + ") ÷ 1.1" ) : <Tag color="red">Edited</Tag>} title="Calculation:">
                            <Space>
                                ${formatPrice(comm.commissionExclGst)}
                                <QuestionCircleOutlined className="cursor-help" />
                            </Space>
                        </Popover>
                    ) : (
                        <Space>
                            ${formatPrice(comm.commissionExclGst)}
                        </Space>
                    )
                )}
            </>,
            amount: <>
                {(comm.user === null || comm.user.gstStatus) ? (
                    (isLoginUserManager() || isLoginUserAdmin()) ? (
                        // show calculation
                        //if the commission setting is changed
                        //all parties commission will be showing "edited"
                        //if setting not changed, only changed some party, then only those changed party will display 'Edited'
                        //because once setting is changed, the formula will not make any sense (eg. if i change commission rate)
                        <Popover content={commissionResale.lastUpdateBy === 0 && comm.lastUpdateBy === 0 ? comm.formula : <Tag color="red">Edited</Tag>} title="Calculation:">
                            <Space>
                                ${formatPrice(comm.commissionInclGst)}
                                <QuestionCircleOutlined className="cursor-help" />
                            </Space>
                        </Popover>
                    ) : (
                        <Space>
                            ${formatPrice(comm.commissionInclGst)}
                        </Space>
                    )
                ) : (
                    <div>&mdash;</div>
                )}
            </>,
        }

        let labelName = '';
        if (comm.role === "Company") {
            labelName = "PW Retainment";
        } else {
            if (comm.user.firstName === "" && comm.user.middleName === "" && comm.user.lastName === "") {
                labelName = comm.user.entityName;
            } else {
                labelName = comm.user.firstName + (comm.user.middleName === "" ? "" : (" " + comm.user.middleName)) + " " + comm.user.lastName;
            }
        }
        thisCommData["labelName"] = labelName;

        data_allocation.push(thisCommData);
    });


    const [currentTimeMillis, setCurrentTimeMillis] = useState(new Date().getTime());
    const handleUpdateCommission = () => {
        updateField["sendEmail"] = resendEmail;

        // console.log(updateField);
        setUpdating(true);

        axios.post(`${process.env.REACT_APP_API_URL}/api/commission/resale/update`, updateField, {
            headers: {
                'Content-Type': 'application/json', // Specify JSON content type
            },
        })
            .then(response => {
                const data = response.data;
                // console.log(data);
                if (data.validationPass) {
                    //great job!
                    showToast("success", "Update success!");

                    // console.log(data.commissionOtp);
                    setCurrentTimeMillis(new Date().getTime());
                    // Update the state with the dynamic update object
                    setCommissionResale(data.commissionResale);
                    setCommissionAllocationResales(data.commissionResale.commissionAllocationResales);

                    setIsEditModalOpen(false);
                } else {
                    //failed, pls double check setting
                    showToast("error", `${data.message}`);
                }

            }).catch(error => {
                console.log(error)
            }).finally(() => {
                setUpdating(false);

            });
    }


    const [isToastVisible, setIsToastVisible] = useState(false);
    const [toastType, setToastType] = useState("normal");
    const [toastMessage, setToastMessage] = useState("");

    const showToast = (type, message) => {
        setIsToastVisible(true);
        setToastType(type);
        setToastMessage(message);

        // Optionally, you can set a timeout to hide the toast after a certain duration
        setTimeout(() => {
            setIsToastVisible(false);
        }, 3000); // Hide the toast after 3 seconds (adjust as needed)
    };

    return (
        <>
            <Toast isVisible={isToastVisible} type={toastType} message={toastMessage} />

            <Modal title="Update Commission (Incl. GST)"
                width={800}
                open={isEditModalOpen}
                onCancel={handleCancel}
                footer={() => (
                    <>
                        <Checkbox className="text-md" checked={resendEmail} onChange={handleResendEmailCheck} ><Tag color="red">Email to Accountant</Tag></Checkbox>
                        <Button onClick={handleUpdateCommission} buttonText={"Confirm"} disabled={updating} />
                    </>
                )}>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    <div>
                        <label>Contract Price:</label>
                        <InputNumber
                            disabled={true}
                            id="contractPrice"
                            className="w-full"
                            value={commissionResale.contractPrice}
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        />
                    </div>

                    <div>
                        <label>Commission Rate (%):</label>
                        <InputNumber
                            id="commissionRate"
                            className="w-full"
                            value={updateField.commissionRate}
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                            onChange={(val) => {
                                setUpdateField({
                                    ...updateField,
                                    commissionRate: val,
                                })
                            }}
                            min={0}
                            max={100}
                        />
                    </div>

                    <div>
                        <label>Customer Rebate ($):</label>
                        <InputNumber
                            id="customerRebate"
                            className="w-full"
                            value={updateField.customerRebate}
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                            onChange={(val) => {
                                setUpdateField({
                                    ...updateField,
                                    customerRebate: val,
                                })
                            }}
                        />
                    </div>

                    <div>
                        <label>Other Expense. ($):</label>
                        <InputNumber
                            id="expense"
                            className="w-full"
                            value={updateField.expense}
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                            onChange={(val) => {
                                setUpdateField({
                                    ...updateField,
                                    expense: val,
                                })
                            }}
                        />
                    </div>

                    <div>
                        <label>Total Comm. ($):</label>
                        <InputNumber
                            id="totalCommissionInclGst"
                            className="w-full"
                            value={updateField.totalCommissionInclGst}
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                            onChange={(val) => {
                                setUpdateField({
                                    ...updateField,
                                    totalCommissionInclGst: val,
                                })
                            }}
                        />
                    </div>

                    <div className="mb-4">
                        <label>Net Comm. ($):</label>
                        <InputNumber
                            id="netCommissionInclGst"
                            className="w-full"
                            value={updateField.netCommissionInclGst}
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                            onChange={(val) => {
                                setUpdateField({
                                    ...updateField,
                                    netCommissionInclGst: val,
                                })
                            }}
                        />
                    </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-2">
                    <label className="font-semibold">Referral Comm.</label>
                    <InputNumber
                        id="referralCommInclGst"
                        className="w-full"
                        value={updateField.referralCommInclGst}
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        onChange={(val) => {
                            setUpdateField({
                                ...updateField,
                                referralCommInclGst: val,
                            })
                        }}
                    />
                </div>
                
                {commissionAllocationResales.map((comm, ndx) => {
                    // const filteredKey = Object.keys(commissionAllocationOtps).filter(key => commissionAllocationOtps[key] === comm.id);
                    const inputVal = updateField.commissionAllocationResales[comm.id] ? updateField.commissionAllocationResales[comm.id] : comm.commissionInclGst;
                    return (
                        <div key={`${comm.id}-${currentTimeMillis}`} className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-2">
                            <label className="font-semibold">{comm.user == null ? "PW Retainment" : (`${comm.user.firstName} ${comm.user.lastName} ${comm.user.entityName}`)}</label>
                            <InputNumber
                                id={comm.id}
                                className="w-full"
                                value={inputVal}
                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                onChange={(val) => {
                                    setUpdateField({
                                        ...updateField,
                                        commissionAllocationResales: {
                                            ...updateField.commissionAllocationResales,
                                            [comm.id]: val + "",
                                        },
                                    })
                                }}
                            />
                        </div>
                    )
                })}
            </Modal>

            <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                <Card className="w-full">
                    <Table
                        columns={columns}
                        dataSource={data}
                        scroll={{ x: true }}
                        pagination={false}
                    />
                    {/* <p className="mt-2">* All figures above are incl. GST</p> */}
                </Card>

                {(isLoginUserManager() || isLoginUserAdmin()) && (
                    <Card className="w-full">
                        <Table
                            columns={[
                                {
                                    title: 'Total Comm. (Excl. Gst)',
                                    dataIndex: 'totalCommExclGst',
                                    key: 'totalCommExclGst',
                                },
                                {
                                    title: 'Total Comm. (Incl. Gst)',
                                    dataIndex: 'totalCommInclGst',
                                    key: 'totalCommInclGst',
                                },
                                {
                                    title: 'Net Comm. (Excl. GST)',
                                    dataIndex: 'netCommExclGst',
                                    key: 'netCommExclGst',
                                },
                                {
                                    title: 'Net Comm. (Incl. GST)',
                                    dataIndex: 'netCommInclGst',
                                    key: 'netCommInclGst',
                                },
                                {
                                    title: 'Referral Fee (Excl. GST)',
                                    dataIndex: 'referralCommExclGst',
                                    key: 'referralCommExclGst',
                                },
                                {
                                    title: 'Referral Fee (Incl. GST)',
                                    dataIndex: 'referralCommInclGst',
                                    key: 'referralCommInclGst',
                                },
                            ]}
                            dataSource={[
                                {
                                    key: 'comm',
                                    totalCommExclGst: `$${formatPrice(commissionResale.totalCommissionExclGst)}`,
                                    totalCommInclGst: <>
                                        {isLoginUserManager() || isLoginUserAdmin() ? (
                                            // show calculation
                                            <Popover content={`${formatPrice(commissionResale.contractPrice)} x ${formatPrice(commissionResale.commissionRate)}%`} title="Calculation:">
                                                <Space>
                                                    ${formatPrice(commissionResale.totalCommissionInclGst)}
                                                    <QuestionCircleOutlined className="cursor-help" />
                                                </Space>
                                            </Popover>
                                        ) : (
                                            <Space>
                                                ${formatPrice(commissionResale.totalCommissionInclGst)}
                                            </Space>
                                        )}
                                    </>,
                                    netCommExclGst: `$${formatPrice(commissionResale.netCommissionExclGst)}`,
                                    netCommInclGst: <>
                                        {isLoginUserManager() || isLoginUserAdmin() ? (
                                            // show calculation
                                            <Popover content={`${formatPrice(commissionResale.contractPrice)} x ${commissionResale.commissionRate}% - ${formatPrice(commissionResale.customerRebateAgent)} - ${formatPrice(commissionResale.expense)}`} title="Calculation:">
                                                <Space>
                                                    ${formatPrice(commissionResale.netCommissionInclGst)}
                                                    <QuestionCircleOutlined className="cursor-help" />
                                                </Space>
                                            </Popover>
                                        ) : (
                                            <Space>
                                                ${formatPrice(commissionResale.netCommissionInclGst)}
                                            </Space>
                                        )}
                                    </>,
                                    referralCommExclGst: <>
                                        {
                                            commissionResale.workflowResale.referrerCommInclGst ? `-` : 
                                            (
                                                isLoginUserManager() || isLoginUserAdmin() ? (
                                                    // show calculation
                                                    <Popover content={`${formatPrice(commissionResale.netCommissionInclGst)} x ${commissionResale.referralCommissionRate}% ÷ 1.1`} title="Calculation:">
                                                        <Space>
                                                            ${formatPrice(commissionResale.referralCommExclGst)}
                                                            <QuestionCircleOutlined className="cursor-help" />
                                                        </Space>
                                                    </Popover>
                                                ) : (
                                                    <Space>
                                                        ${formatPrice(commissionResale.referralCommExclGst)}
                                                    </Space>
                                                )
                                            )
                                        }
                                    </>,
                                    // `$${formatPrice(commissionResale.referralCommExclGst)}`,
                                    referralCommInclGst: <>
                                        {
                                            commissionResale.workflowResale.referrerCommInclGst ? (
                                                isLoginUserManager() || isLoginUserAdmin() ? (
                                                    // show calculation
                                                    <Popover content={`${formatPrice(commissionResale.netCommissionInclGst)} x ${commissionResale.referralCommissionRate}%`} title="Calculation:">
                                                        <Space>
                                                            ${formatPrice(commissionResale.referralCommInclGst)}
                                                            <QuestionCircleOutlined className="cursor-help" />
                                                        </Space>
                                                    </Popover>
                                                ) : (
                                                    <Space>
                                                        ${formatPrice(commissionResale.referralCommInclGst)}
                                                    </Space>
                                                )
                                            ) : `-`
                                        }
                                    </>,
                                }
                            ]}
                            scroll={{ x: true }}
                            pagination={false}
                        />
                    </Card>
                )}

                <Card className="w-full">
                    <Table
                        columns={columns_allocation}
                        dataSource={data_allocation}
                        scroll={{ x: true }}
                        pagination={false}
                    />
                </Card>

            </Space>
        </>)
}