import React, { useState, useEffect } from "react";
import Toast from "../Toast";
import { Collapse, Descriptions, Tag, Modal, Alert, Checkbox } from "antd";
import Button from "../Button";
import * as Util from "../../functions/common/util";
import axios from "axios";
import { formatPrice } from "../../functions/common/util";
import {
  downloadFile,
  uploadSingleFile,
} from "../../functions/common/file-upload";
import {
  isLoginUserAdmin,
  isLoginUserPartner,
} from "../../functions/common/manage-login-user";

export default function ResaleOnsale({ workflow, updateWorkflow }) {
  const [formData, setFormData] = useState({
    id: workflow.id,
    // listingReferrer: workflow.listingReferrer,
    // listingReferrerCommRate: workflow.listingReferrerCommRate,
    // sellingAgent: workflow.sellingAgent,
    // listingAgent: workflow.listingAgent,
    // commissionRate: workflow.commissionRate,
    // expense: workflow.expense,
    buyerName: workflow.buyerName,
    contractPrice: workflow.contractPrice > 0 ? workflow.contractPrice : "",
    exchangeDate: workflow.exchangeDate
      ? Util.formatDateValue(workflow.exchangeDate)
      : "",
    estSettlementDate: workflow.estSettlementDate
      ? Util.formatDateValue(workflow.estSettlementDate)
      : "",
    customerRebate: workflow.customerRebate > 0 ? workflow.customerRebate : "",
  });

  const [partnerApproved, setPartnerApproved] = useState(
    workflow.approvedBy > 0
  );

  const [showCustomerRebateFileUpload, setShowCustomerRebateFileUpload] =
    useState(workflow.customerRebate > 0);

  const [customerRebateFilesUploaded, setCustomerRebateFilesUploaded] =
    useState([]);

  const onChangeHandler = (e) => {
    const { id, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));

    if (id === "customerRebate") {
      setShowCustomerRebateFileUpload(value !== "");
    }

    setChangesNotSave(true);
  };

  const uploadWorkflowDocs = () => {
    Array.from(rebateFiles).forEach((file) => {
      if (!file.uploaded) {
        //if already uploaded , do not upload again
        const timestamp = Date.now();
        const newFilename = `CBA_${timestamp}_${file.name}`;
        file.s3Key = `workflow/resale/${newFilename}`;

        uploadSingleFile(file);

        formData["customerRebateFiles"] = [];
        formData.customerRebateFiles.push({
          filename: file.name,
          s3Key: file.s3Key,
        });

        setCustomerRebateFilesUploaded((prevData) => [
          ...prevData,
          {
            name: file.name,
            s3Key: file.s3Key,
          },
        ]);
      }
    });
  };

  const [changesNotSave, setChangesNotSave] = useState(false);
  const [showLogModal, setShowLogModal] = useState(false);
  const [commissionLog, setCommissionLog] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleExchange = () => {
    if (!formData.exchangeDate) {
      showToast("error", "Exchange date required.");
      return;
    }

    if (!formData.estSettlementDate) {
      showToast("error", "Estimate settlement date required.");
      return;
    }

    axios
      .put(
        `${process.env.REACT_APP_API_URL}/api/workflow/resale/exchange`,
        formData,
        {
          headers: {
            "Content-Type": "application/json", // Specify JSON content type
          },
        }
      )
      .then((response) => {
        console.log(response);

        if (response.data.length !== 2) {
          showToast("error", "Error!");
          return;
        }

        const workflowResaleRes = response.data[0];
        const commissionResaleRes = response.data[1];

        if (workflowResaleRes.status === 1) {
          showToast("error", "Please review your commission setting.");
          setCommissionLog(commissionResaleRes);
          setViewLogButton(true);
          return;
        }

        // console.log(response);
        setFormData(workflowResaleRes);

        showToast("success", "Exchanged!");

        let updatedWorkflow = workflowResaleRes;
        updatedWorkflow.property = workflow.property;
        updateWorkflow(updatedWorkflow);
      })
      .catch((error) => {
        console.log(error);
        showToast("error", "Error!");
      })
      .finally(() => {});
  };

  const handleSubmit = () => {
    if (formData.buyerName === "") {
      showToast("error", "Buyer name required.");
      return;
    }

    if (formData.contractPrice === "0" || formData.contractPrice === "") {
      showToast("error", "Invalid contract price.");
      return;
    }

    if (
      showCustomerRebateFileUpload &&
      rebateFiles.length === 0 &&
      customerRebateFilesUploaded.length === 0
    ) {
      showToast("error", "Customer rebate agreement required.");
      return;
    }

    setIsSubmitting(true);

    uploadWorkflowDocs();

    // console.log(formData)
    formData.customerRebateInclGst = rebateChecked;

    axios
      .put(
        `${process.env.REACT_APP_API_URL}/api/workflow/resale/submit-exchange-details`,
        formData,
        {
          headers: {
            "Content-Type": "application/json", // Specify JSON content type
          },
        }
      )
      .then((response) => {
        console.log(response);

        showToast("success", "Saved!");

        setChangesNotSave(false);
      })
      .catch((error) => {
        console.log(error);
        showToast("error", "Error!");
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleApprove = () => {
    if (changesNotSave) {
      showToast("error", "Please submit your changes first.");
      return;
    }

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/workflow/resale/partner-approval/${workflow.id}`
      )
      .then((response) => {
        if (response.status === 200) {
          setPartnerApproved(true);
          showToast("success", "Approved successfully.");
        }
      })
      .catch((error) => {
        console.log(error);
        showToast("error", "Error.");
      });
  };

  const [isToastVisible, setIsToastVisible] = useState(false);
  const [toastType, setToastType] = useState("normal");
  const [toastMessage, setToastMessage] = useState("");

  const showToast = (type, message) => {
    setIsToastVisible(true);
    setToastType(type);
    setToastMessage(message);

    // Optionally, you can set a timeout to hide the toast after a certain duration
    setTimeout(() => {
      setIsToastVisible(false);
    }, 2000); // Hide the toast after 3 seconds (adjust as needed)
  };

  const sellingInfo = [
    {
      label: "Vendor",
      children: workflow.vendor,
    },
    {
      label: "Marketing Fee",
      children: `$${formatPrice(workflow.marketingFee)}`,
    },
    {
      label: "Listing Referrer",
      children: workflow.listingReferrer,
    },
    {
      label: (
        <div>
          Listing Referral
          <br />
          Commission Rate
        </div>
      ),
      children:
        workflow.listingReferrerCommRate +
        "% " +
        (workflow.referrerCommInclGst ? `(incl. GST)` : `(excl. GST)`),
    },
    {
      label: "Selling Agent",
      children: workflow.sellingAgentName,
    },
    {
      label: "Listing Agent",
      children: workflow.listingAgentName,
    },
    {
      label: "Commission Rate",
      children: `${workflow.commissionRate}% (incl. GST)`,
    },
    {
      label: "Other Expense",
      children: `$${formatPrice(workflow.expense)} (incl. GST)`,
    },
    {
      label: "Address",
      children: `${workflow.property.address} ${workflow.property.suburb}, ${workflow.property.state} ${workflow.property.postcode}`,
    },
  ];

  const [referralFilesUploaded, setReferralFilesUploaded] = useState([]);
  useEffect(() => {
    const fetchWorkflowDocs = (id) => {
      if (!id) {
        return;
      }

      axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/workflow/resale/fetch-docs`,
          {
            params: {
              workflowId: id,
              fileType: "referral agreement",
            }, // Pass the search data as query parameters
          }
        )
        .then((response) => {
          // console.log(response)
          if (response.status === 200) {
            const files = response.data;
            files.forEach((file) => {
              setReferralFilesUploaded((prevData) => [
                ...prevData,
                {
                  name: file.filename,
                  s3Key: file.s3Key,
                },
              ]);
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const fetchRebateAgreement = (id) => {
      if (!id) {
        return;
      }

      axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/workflow/resale/fetch-docs`,
          {
            params: {
              workflowId: id,
              fileType: "rebate agreement",
            }, // Pass the search data as query parameters
          }
        )
        .then((response) => {
          // console.log(response)
          if (response.status === 200) {
            const files = response.data;
            files.forEach((file) => {
              setCustomerRebateFilesUploaded((prevData) => [
                ...prevData,
                {
                  name: file.filename,
                  s3Key: file.s3Key,
                },
              ]);
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    fetchWorkflowDocs(workflow?.id);
    fetchRebateAgreement(workflow?.id);
  }, [workflow?.id]);

  const editSellingInfo = () => {
    workflow.status = 0;
    updateWorkflow(workflow);
  };

  const [rebateFiles, setRebateFiles] = useState([]);
  const handleFileUpload = (e) => {
    const { id, files } = e.target;

    if (id === "rebate-files") {
      setRebateFiles(files);
    }
  };

  const [showExchangeModal, setShowExchangeModal] = useState(false);
  const [viewLogButton, setViewLogButton] = useState(false);

  const exchangeOnclickHandler = () => {
    if (changesNotSave) {
      showToast("error", "Please submit your changes first.");
      return;
    }
    setShowExchangeModal(true);
  };

  const [rebateChecked, setRebateChecked] = useState(
    workflow.customerRebateInclGst
  );

  const setRebateCheck = () => {
    setRebateChecked(!rebateChecked);
  };

  return (
    <>
      <Toast
        isVisible={isToastVisible}
        type={toastType}
        message={toastMessage}
      />

      <Modal
        onCancel={() => {
          setShowExchangeModal(false);
        }}
        open={showExchangeModal}
        title=""
        footer={() => (
          <>
            <Button onClick={handleExchange} buttonText={"Submit"} />
          </>
        )}
      >
        <div className="mt-10 mb-10 grid gap-4 grid-cols-1 sm:grid-cols-2">
          <div>
            <label className="font-medium" htmlFor="exchangeDate">
              Exchange Date <span className="text-error">*</span>
            </label>
            <input
              type="date"
              id="exchangeDate"
              onChange={onChangeHandler}
              placeholder=""
              className="mt-2 py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500 mandatory"
              value={formData.exchangeDate}
            />
          </div>
          <div>
            <label className="font-medium" htmlFor="estSettlementDate">
              Est. Settlement Date
            </label>
            <input
              type="date"
              id="estSettlementDate"
              onChange={onChangeHandler}
              placeholder=""
              className="mt-2 py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500"
              value={formData.estSettlementDate}
            />
          </div>
        </div>

        {viewLogButton && (
          <div className="grid gap-4 grid-cols-1 mb-5">
            <span
              className="link-pw cursor-pointer"
              onClick={() => setShowLogModal(true)}
            >
              View Error Log
            </span>
          </div>
        )}
      </Modal>

      <Modal
        onCancel={() => {
          setShowLogModal(false);
        }}
        open={showLogModal}
        title=""
        footer={() => <></>}
      >
        <div className="mt-10 mb-10 grid grid-cols-1">
          <Alert
            className="mb-5"
            description={
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    "<p>Please review the commission setting and calculation results.</p>&#8226; All numbers must be bigger than 0<br/>&#8226; Allocation add up must be equal to net commission.",
                }}
              ></div>
            }
            type="error"
          />
          <label>Settings (Incl. GST):</label>
          <Tag className="mb-5">
            <p>Contract Price: ${formatPrice(commissionLog.contractPrice)}</p>
            <p>Commission Rate: {commissionLog.commissionRate}%</p>
            <p>
              Referral Commission Rate: {commissionLog.referralCommissionRate}%
            </p>
            <p>Expense: ${formatPrice(commissionLog.expense)}</p>
            {commissionLog.customerRebateAgent > 0 && (
              <p>
                Customer Rebate: $
                {formatPrice(commissionLog.customerRebateAgent)}
              </p>
            )}
          </Tag>

          <label>Calculation Results (Incl. GST):</label>
          <Tag className="mb-2">
            <p>
              Total Commission:{" "}
              <span className="underline decoration-solid underline-offset-2 ml-2">
                {commissionLog.totalCommissionInclGst >= 0
                  ? "$" + formatPrice(commissionLog.totalCommissionInclGst)
                  : "-$" + formatPrice(-commissionLog.totalCommissionInclGst)}
              </span>
            </p>
            <p>
              Net Commission:{" "}
              <span className="underline decoration-solid underline-offset-2 ml-2">
                {commissionLog.netCommissionInclGst >= 0
                  ? "$" + formatPrice(commissionLog.netCommissionInclGst)
                  : "-$" + formatPrice(-commissionLog.netCommissionInclGst)}
              </span>
            </p>
          </Tag>
          <Tag>
            {commissionLog.referralCommInclGst > 0 && (
              <p>
                Referral Commission:{" "}
                <span className="underline decoration-solid underline-offset-2 ml-2">
                  {commissionLog.referralCommInclGst >= 0
                    ? "$" + formatPrice(commissionLog.referralCommInclGst)
                    : "-$" + formatPrice(-commissionLog.referralCommInclGst)}
                </span>
              </p>
            )}

            {commissionLog.commissionAllocationResales?.map((comm, ndx) => {
              return (
                <p key={ndx}>
                  {comm.user === null
                    ? "PW Retainment"
                    : comm.user.firstName +
                      " " +
                      comm.user.lastName +
                      "(" +
                      comm.role +
                      ")"}
                  <span className="underline decoration-solid underline-offset-2 ml-2">
                    {comm.commissionInclGst >= 0
                      ? "$" + formatPrice(comm.commissionInclGst)
                      : "-$" + formatPrice(-comm.commissionInclGst)}
                  </span>
                </p>
              );
            })}
          </Tag>
        </div>
      </Modal>

      <div className="static card w-fill bg-base-100 shadow-md">
        <div className="card-body">
          <Collapse
            items={[
              {
                key: "1",
                label: "Selling Information",
                children: (
                  <>
                    <div className="flex justify-end mb-4">
                      {isLoginUserAdmin() && (
                        <div
                          title="Click to edit."
                          className={`cursor-pointer link-pw`}
                          onClick={editSellingInfo}
                        >
                          <i className="ri-edit-line"></i>
                        </div>
                      )}
                    </div>
                    <Descriptions
                      bordered
                      column={{ xs: 1, sm: 2, md: 2, lg: 2, xl: 2, xxl: 2 }}
                      items={sellingInfo}
                      size="small"
                    />

                    {referralFilesUploaded.length > 0 && (
                      <div className="mt-4 ml-4">
                        <label>Referral Agreement:</label>
                        <ul className="space-y-3 text-sm">
                          {referralFilesUploaded.map((thisfile, ndx) => (
                            <li
                              key={"id-file-" + ndx}
                              onClick={() => {
                                downloadFile(thisfile);
                              }}
                              className="flex space-x-3 items-center cursor-pointer"
                            >
                              <i className="ri-attachment-2 text-lg text-pw"></i>
                              <span className="text-gray-800 link-pw">
                                {thisfile.name}
                              </span>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </>
                ),
              },
            ]}
          />

          <Collapse
            activeKey="1"
            items={[
              {
                key: "1",
                label: "Exchange Details",
                children: (
                  <>
                    <div className="grid gap-4 grid-cols-1 md:grid-cols-3">
                      <div className="mb-4">
                        <label className="font-medium" htmlFor="buyerName">
                          Buyer Name <span className="text-error">*</span>
                        </label>
                        <input
                          type="text"
                          id="buyerName"
                          onChange={onChangeHandler}
                          placeholder=""
                          className="mt-2 py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500 mandatory"
                          value={formData.buyerName}
                        />
                      </div>
                      <div className="mb-4">
                        <label className="font-medium" htmlFor="contractPrice">
                          Contract Price <span className="text-error">*</span>
                        </label>
                        <input
                          type="text"
                          id="contractPrice"
                          onChange={onChangeHandler}
                          placeholder=""
                          className="mt-2 py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500 mandatory"
                          value={Util.formatPrice(formData.contractPrice)}
                        />
                      </div>
                      <div className="mb-4">
                        <label className="font-medium" htmlFor="customerRebate">
                          Rebate <span className="text-error">*</span>{" "}
                          &nbsp;&nbsp;&nbsp;{" "}
                          <Checkbox
                            defaultChecked={rebateChecked}
                            onChange={setRebateCheck}
                          >
                            Incl. GST
                          </Checkbox>
                        </label>
                        <input
                          type="text"
                          id="customerRebate"
                          onChange={onChangeHandler}
                          placeholder=""
                          className="mt-2 py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500 mandatory"
                          value={Util.formatPrice(formData.customerRebate)}
                        />
                      </div>
                    </div>
                    {showCustomerRebateFileUpload && (
                      <div className="grid gap-4 grid-cols-1 mb-4">
                        <div className="">
                          <label className="font-medium ltr:text-left">
                            Customer Rebate Agreement{" "}
                            <span className="text-error">*</span>
                          </label>
                          <label htmlFor="rebate-files" className="sr-only">
                            Choose file
                          </label>
                          <input
                            type="file"
                            multiple
                            name="rebate-files"
                            id="rebate-files"
                            className="block w-full border border-gray-200 shadow-sm rounded-md text-sm focus:z-10 focus:border-orange-500 focus:ring-orange-500
                                                    file:bg-transparent file:border-0
                                                    file:bg-gray-100 file:mr-4
                                                    file:py-3 file:px-4
                                                    "
                            onChange={handleFileUpload}
                          />
                        </div>

                        {customerRebateFilesUploaded.length > 0 && (
                          <div className="">
                            {/* <label>Customer Rebate Agreement:</label> */}
                            <ul className="space-y-3 text-sm">
                              {customerRebateFilesUploaded.map(
                                (thisfile, ndx) => (
                                  <li
                                    key={"id-file-" + ndx}
                                    onClick={() => {
                                      downloadFile(thisfile);
                                    }}
                                    className="flex space-x-3 items-center cursor-pointer"
                                  >
                                    <i className="ri-attachment-2 text-lg text-pw"></i>
                                    <span className="text-gray-800 link-pw">
                                      {thisfile.name}
                                    </span>
                                  </li>
                                )
                              )}
                            </ul>
                          </div>
                        )}
                      </div>
                    )}
                  </>
                ),
              },
            ]}
          />

          {(isLoginUserAdmin() || !partnerApproved) && (
            <div className="space-x-5 mt-10">
              <Button
                buttonText={"Submit"}
                onClick={handleSubmit}
                disabled={isSubmitting}
              />
            </div>
          )}
        </div>
      </div>

      <div className="ml-5">
        {/* only admin and partner can click exchange, and the workflow must not be disabled */}
        {(isLoginUserAdmin() || isLoginUserPartner()) &&
          workflow.enabled &&
          (partnerApproved ? (
            <Button buttonText={"Exchange"} onClick={exchangeOnclickHandler} />
          ) : (
            <button
              type="button"
              className="pointer-events-none py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border-2 border-red-200 font-medium bg-white text-red-300 shadow-sm align-middle focus:outline-none transition-all cursor-not-allowed text-sm"
            >
              Waiting for Partner Approval
            </button>
          ))}

        {/* show the approve button, if user is partner and has not been approved yet and has submitted exchange details */}
        {workflow.exchangeDetailSubmitDate !== null &&
          !partnerApproved &&
          isLoginUserPartner() && (
            <Button buttonText={"Partner Approve"} onClick={handleApprove} />
          )}
      </div>
    </>
  );
}
