import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Button from "../components/Button";
import Toast from "../components/Toast";
import { isStrongPassword } from "../functions/common/util";

export default function UpdatePassword() {

    const [user, setUser] = useState(null);

    useEffect(() => {
        // checkLogin();     
        document.title = "Update Password"

        const fetchUserDetail = () => {
            axios.get(`${process.env.REACT_APP_API_URL}/api/user/profile`)
                .then(response => {
                    // console.log(response)
                    if (response.status === 200) {
                        setUser(response.data);
                    }
                }).catch(error => {

                });
        }

        fetchUserDetail();

    }, []);

    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const [isSubmitting, setIsSubmitting] = useState(false);

    const [isToastVisible, setIsToastVisible] = useState(false);
    const [toastType, setToastType] = useState("normal");
    const [toastMessage, setToastMessage] = useState("");


    const handleOnclick = async () => {

        // console.log("currentPassword " + currentPassword)
        // console.log("newPassword " + newPassword)
        // console.log("confirmPassword " + confirmPassword)

        if (currentPassword === "") {
            showToast("error", "Please enter your current password.")
            return;
        }

        if (newPassword !== confirmPassword) {
            showToast("error", "Passwords do not match.")
            return;
        }

        
        //check password strength
        if(!isStrongPassword(newPassword)) {
            showToast("error", "Password's minimum length is 8 characters and must contain at least one uppercase letter, one lowercase letter and one digit.")
            return
        }

        setIsSubmitting(true);

        const data = {
            userId: user.id,
            currentPassword: currentPassword,
            newPassword: newPassword,
            confirmPassword: confirmPassword,
        }

        await axios.post(`${process.env.REACT_APP_API_URL}/api/user/update-password`, data, {
            headers: {
                'Content-Type': 'application/json', // Specify JSON content type
            },
        }).then(response => {
            if (response.status === 200) {
                //update success 
                showToast("success", "Password updated successfully!")
            }
        }).catch(error => {
            console.log(error)
            if (error.response.status === 422) {
                showToast("error", "Incorrect current password.")
                return;
            }
            showToast("error", "Failed to update password.");
        });

        setIsSubmitting(false);

    }

    const showToast = (type, message) => {
        setIsToastVisible(true);
        setToastType(type);
        setToastMessage(message);

        let timer = 2000;
        if(type === "error") {
            timer = 4000;
        }

        // Optionally, you can set a timeout to hide the toast after a certain duration
        setTimeout(() => {
            setIsToastVisible(false);
        }, timer); // Hide the toast after 3 seconds (adjust as needed)
    };

    return (
        <div>
            <Toast isVisible={isToastVisible} type={toastType} message={toastMessage} />

            <Header />

            <div className="main-container">
                <div className={`static card bg-base-100 shadow-md my-5 mx-10`}>
                    <div className="card-body">
                        <div className="flex justify-between ">
                            <div className="text-pw text-lg font-semibold">
                                <h3>Platform Login Detail</h3>
                            </div>
                        </div>
                        <hr className="mb-4" />
                        <div className="grid gap-4 grid-cols-1 md:grid-cols-4">
                            <div className="mb-4">
                                <label className="font-medium ltr:text-left" htmlFor="emailAddress">Login Email</label>
                                <input type="text" id="emailAddress" className="can-edit bg-gray-200 pointer-events-none py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500" defaultValue={user != null ? user.emailAddress : ""} />
                            </div>
                        </div>
                        <div className="grid gap-4 grid-cols-1 md:grid-cols-4">
                            <div className="mb-4">
                                <label className="font-medium ltr:text-left" htmlFor="oldPassword">Current Password</label>
                                <input type="password" id="oldPassword" placeholder="Current Password" className="py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500" value={currentPassword} onChange={(e) => { setCurrentPassword(e.target.value) }} />
                            </div>
                            <div className="mb-4">
                                <label className="font-medium ltr:text-left" htmlFor="newPassword">New Password</label>
                                <input type="password" id="newPassword" placeholder="New Password" className="py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500" value={newPassword} onChange={(e) => { setNewPassword(e.target.value) }} />
                            </div>
                            <div className="mb-4">
                                <label className="font-medium ltr:text-left" htmlFor="confirmPassword">Confirm Password</label>
                                <input type="password" id="confirmPassword" placeholder="Confirm New Password" className="py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500" value={confirmPassword} onChange={(e) => { setConfirmPassword(e.target.value) }} />
                            </div>

                        </div>

                        <div>
                            <Button buttonText="Update" onClick={handleOnclick} disabled={isSubmitting} />
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}