import axios from "axios";
import { downloadFileToLocal } from "./file-upload";

export const printSalesAdvice = async (workflowId, company) => {

    const apiUrl = process.env.REACT_APP_API_URL; //'http://localhost:8080';
    
    axios.get(`${apiUrl}/api/pdf/create/sales-advice`, {
        params: {
            workflowId: workflowId,
            company: company
        }, 
    })
    .then((response) => {
        // console.log(response);
        // const file = {
        //     filename: "response.data.name",
        //     s3Key: response.data,
        //     previewOnly: previewOnly
        // }
        downloadFileToLocal(response.data)
    })
    .catch(error => {
        console.log(error);
    });

}