import React, { useEffect, useState, useRef } from "react";
import axios from "axios";

import Button from "../Button";
import Toast from "../Toast";
import { isLoginUserAdmin } from "../../functions/common/manage-login-user";
import { isStrongPassword } from "../../functions/common/util";



export default function CreateUserAccount({ user, readOnly }) {

    const editingAdminUser = user.role === 'Admin';

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const [formData, setFormData] = useState({
        id: user.id,
        emailAddress: user.emailAddress,
        password: "",
        role: user.role ? user.role : "",
        otpCommissionLevel: user.otpCommissionLevel ? user.otpCommissionLevel : "L0",
        resaleCommissionLevel: user.resaleCommissionLevel ? user.resaleCommissionLevel : "L0",
        reportingManager: user.reportingManager ? user.reportingManager : 0,
    });

    const reportingManagerEle = useRef(null);
    const [managers, setManagers] = useState([]);

    useEffect(() => {
        const fetchManagerAndPartners = () => {
            axios.get(`${process.env.REACT_APP_API_URL}/api/user/managers-and-partners`)
                .then(response => {
                    if (response.status === 200) {
                        //login success redirect to dashboard
                        if (response.data !== null) {
                            // console.log(response)
                            setManagers(response.data);
                        }

                    }
                })
                .catch(error => { });
        }

        fetchManagerAndPartners();
    }, [user])

    useEffect(() => {
        if (managers.length > 0) {
            // Ensure that managers have been fetched before updating the select value
            reportingManagerEle.current.value = user.reportingManager;
        }
    }, [managers, user]);

    const [isSubmitting, setIsSubmitting] = useState(false);

    const [isToastVisible, setIsToastVisible] = useState(false);
    const [toastType, setToastType] = useState("normal");
    const [toastMessage, setToastMessage] = useState("");

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData({
            ...formData,
            [id]: value,
        });

    };

    const handleOnClick = async () => {
        // console.log(formData);

        if (formData.emailAddress === "" || formData.password === "") {
            showToast("error", "Invalid login details. Email and password cannot be empty.")
            return
        }

        //check password strength
        if(!isStrongPassword(formData.password)) {
            showToast("error", "Password's minimum length is 8 characters and must contain at least one uppercase letter, one lowercase letter and one digit.")
            return
        }

        setIsSubmitting(true);

        await createUserAccount(formData);

        setIsSubmitting(false);
    }

    const showToast = (type, message) => {
        setIsToastVisible(true);
        setToastType(type);
        setToastMessage(message);

        let timer = 2000;
        if(type === "error") {
            timer = 4000;
        }

        // Optionally, you can set a timeout to hide the toast after a certain duration
        setTimeout(() => {
            setIsToastVisible(false);
        }, timer); // Hide the toast after x seconds (adjust as needed)
    };

    const createUserAccount = async (data) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/user/create-account`, data, {
                headers: {
                    'Content-Type': 'application/json', // Specify JSON content type
                },
            });
            // console.log(response);

            if (response.status === 200) {
                showToast("success", "Account created successfully!");
                user.emailAddress = formData.emailAddress;
                user.status = 1;
                setUserStatus(user.status)
            }

            //return response.data;
        } catch (error) {
            if (error?.response) {
                // The request was made and the server responded with a status code
                console.log("HTTP Status Code:", error.response.status);
                console.log("Error Message:", error.response.data);
                showToast("error", error.response.data);
            } else if (error?.request) {
                // The request was made but no response was received
                console.log("Request made, but no response received:", error.request);
            } else {
                // Something happened in setting up the request
                console.log("Error setting up the request:", error.message);
            }
        }
    }

    const [userStatus, setUserStatus] = useState(user.status);

    const handleStatusChange = (newStatus) => {
        if (newStatus === userStatus) {
            //no change
            return;
        } else {
            updateUserStatus(newStatus);
        }
    }

    const updateUserStatus = (newStatus) => {
        let data = {
            id: user.id,
            status: newStatus
        }

        axios.put(`${process.env.REACT_APP_API_URL}/api/user/update-status`, data, {
            headers: {
                'Content-Type': 'application/json', // Specify JSON content type
            },
        })
            .then(response => {
                setUserStatus(newStatus);
                user.status = newStatus;
                showToast("success", "User status updated successfully!")
            }).catch(error => {
                console.log(error)
                showToast("error", "Error while updating user status.")
            });
    }


    const handleOnUpdate = async () => {

        setIsSubmitting(true);

        await updateBasicInfo(formData);

        setIsSubmitting(false);

    }

    const updateBasicInfo = async (formData) => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/user/update-basic-info`, formData, {
                headers: {
                    'Content-Type': 'application/json', // Specify JSON content type
                },
            });
            // console.log(response);

            if (response.status === 200) {
                //update the user prop so the parent can reload the state variable
                user.role = formData.role;
                user.otpCommissionLevel = formData.otpCommissionLevel;
                user.resaleCommissionLevel = formData.resaleCommissionLevel;
                user.reportingManager = formData.reportingManager;
                showToast("success", "Update successfully!");
            }

            //return response.data;
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                console.log("HTTP Status Code:", error.response.status);
                console.log("Error Message:", error.response.data);
                showToast("error", error.response.data);
            } else {
                // Something happened in setting up the request
                console.log(error);
                showToast("error", "Time out.");
            }
        }
    }

    return (
        <div>
            <Toast isVisible={isToastVisible} type={toastType} message={toastMessage} />

            <div className="flex justify-between ">
                <div className="text-pw text-lg font-semibold">
                    <h3>Platform Login Detail</h3>
                </div>
            </div>
            <hr className="mb-4" />

            {user.emailAddress === "" && (
                <div className="grid gap-4 grid-cols-1 mb-4">
                    <p>This user does not have a login account.</p>
                </div>
            )}

            <form id="create-account-form">
                <div className="grid gap-4 grid-cols-1 md:grid-cols-4">
                    <div className="mb-4">
                        <label className="font-medium ltr:text-left" htmlFor="emailAddress">Login Email</label>
                        <input type="text" id="emailAddress" value={formData.emailAddress} onChange={handleChange} placeholder="Login Email" className={`py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500 ${user.emailAddress !== "" ? "opacity-80 pointer-events-none" : ""}`} />
                    </div>
                    {user.emailAddress === "" &&
                        <div className="mb-4">
                            <label className="font-medium ltr:text-left" htmlFor="password">Password</label>
                            <input type="password" id="password" onChange={handleChange} placeholder="Login Password" className="py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500" />
                        </div>                        
                    }

                    {!readOnly && user.emailAddress !== "" && 
                    <div className="mb-4">
                        <label className="font-medium ltr:text-left">Status</label>
                        <br />
                        <div className="hs-dropdown relative inline-flex [--placement:bottom-right]">
                            <button id="hs-dropdown" type="button" className="btn-sm hs-dropdown-toggle py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 ">
                                <span className="text-red-500">
                                    {userStatus === 0 ? <span className="text-red-500">Pending</span>
                                        :
                                        (userStatus === 1 ? <span className="text-green-500">Active</span>
                                            : <span className="text-gray-500">Inactive</span>)}
                                </span>

                                <svg className="hs-dropdown-open:rotate-180 w-2.5 h-2.5 text-gray-600" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
                                </svg>
                            </button>

                            <div className="hs-dropdown-menu w-30 transition-[opacity,margin] duration hs-dropdown-open:opacity-100 opacity-0 hidden z-10 bg-white shadow-md rounded-lg p-2" aria-labelledby="hs-dropdown">
                                <span onClick={() => { handleStatusChange(1) }} className="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-orange-500">
                                    <span className="text-green-500" >Active</span>
                                </span>
                                <span onClick={() => { handleStatusChange(-1) }} className="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-orange-500">
                                    <span className="text-salute-500">Inactive</span>
                                </span>
                            </div>
                        </div>
                    </div>
                    }

                </div>

                <div className="flex justify-between mt-5">
                    <div className="text-pw text-lg font-semibold">
                        <h3>Basic Info</h3>
                    </div>
                </div>
                <hr className="mb-4" />
                <div className="grid gap-4 grid-cols-1 md:grid-cols-4">
                    <div className="mb-4">
                        <label className="font-medium ltr:text-left" htmlFor="role">Role</label>
                        <select id="role" defaultValue={user.role ? user.role : ""} onChange={handleChange} className={`py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500 ${readOnly ? "opacity-80 pointer-events-none" : ""}`}>
                            <option value="">-- Select --</option>
                            <option value="Admin">Admin</option>
                            <option value="CEO">CEO</option>
                            <option value="Partner">Partner</option>
                            <option value="Sales Director">Sales Director</option>
                            <option value="Senior Manager">Senior Manager</option>
                            <option value="Sales Manager">Sales Manager</option>
                            <option value="Senior Consultant">Senior Consultant</option>
                            <option value="Sales Consultant">Sales Consultant</option>
                        </select>
                    </div>

                    {/* {TODO - If this editing user is admin, do not show reporting manager} */}
                    <div className={`mb-4 ${editingAdminUser ? "hidden" : ""}`}>
                        <label className={`font-medium ltr:text-left`} htmlFor="reportingManager">Reporting Manager</label>
                        <select id="reportingManager" ref={reportingManagerEle} defaultValue={user.reportingManager} onChange={handleChange} className={`py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500 ${readOnly ? "opacity-80 pointer-events-none" : ""}`} >
                            <option value="0">-- Select --</option>
                            {managers.map((manager) => (
                                <option key={manager.id} value={manager.id}>
                                    {manager.firstName} {manager.lastName}
                                </option>
                            ))}
                        </select>
                    </div>


                    <div className="mb-4">
                        <label className="font-medium ltr:text-left" htmlFor="otpCommissionLevel">OTP Commission Level</label>
                        <select id="otpCommissionLevel" defaultValue={user.otpCommissionLevel ? user.otpCommissionLevel : ""} onChange={handleChange} className={`py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500 ${readOnly ? "opacity-80 pointer-events-none" : ""}`}  >
                            <option value="">-- Select --</option>
                            <option value="L1">L1</option>
                            <option value="L2">L2</option>
                            <option value="L3">L3</option>
                            <option value="L4">L4</option>
                            <option value="L5">L5</option>
                        </select>
                    </div>
                    <div className="mb-4">
                        <label className="font-medium ltr:text-left" htmlFor="resaleCommissionLevel">Resale Commission Level</label>
                        <select id="resaleCommissionLevel" defaultValue={user.resaleCommissionLevel ? user.resaleCommissionLevel : ""} onChange={handleChange} className={`py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500 ${readOnly ? "opacity-80 pointer-events-none" : ""}`}  >
                            <option value="">-- Select --</option>
                            <option value="L1">L1</option>
                            <option value="L2">L2</option>
                            <option value="L3">L3</option>
                        </select>
                    </div>

                </div>

                {isLoginUserAdmin() && user.emailAddress==="" && !readOnly && (
                    <div className="grid gap-4 grid-cols-1 mb-4 mt-5">
                        <div className="mb-4">
                            <Button buttonText="Create Account" onClick={handleOnClick} disabled={isSubmitting} />
                        </div>
                    </div>
                )}

                {isLoginUserAdmin() && user.emailAddress!=="" && !readOnly ? (
                    <div className="grid gap-4 grid-cols-1 md:grid-cols-4 mt-5">
                        <div className="mb-4">
                            <Button buttonText="Update" onClick={handleOnUpdate} disabled={isSubmitting} />
                        </div>
                    </div>
                ) : ("")}

            </form>
        </div>
    );
}