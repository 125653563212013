import React from "react";

export default function Button({ children, buttonText, onClick, disabled, btnClass }) {
    return (
        <button type="button" className={`cursor-pointer font-medium px-6 py-2 ${btnClass ? btnClass : "btn-pw"}`}
            disabled={disabled} onClick={onClick}>
                {disabled?(
                    <div className="mr-4 align-middle items-center animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent text-white-600 rounded-full" role="status" aria-label="loading">
                        <span className="sr-only">Loading...</span>
                    </div>
                ):(
                    children && (
                        children
                    )
                )}
                {buttonText}
        </button>
    );
}
