import React, {useState, useEffect} from 'react';
import { Navigate } from 'react-router-dom';
import {validateToken} from '../functions/common/token-validation';

const PrivateRoute = ({ children }) => {    

    const [isValidToken, setIsValidToken] = useState(null);

    // function sleep(ms) {
    //     return new Promise(resolve => setTimeout(resolve, ms));
    // }

    useEffect(() => {
        const checkToken = async () => {
            const result = await validateToken();
            

            setIsValidToken(result);
        };

        checkToken();
    }, []);

    if (isValidToken === null) {
        // Token validation is in progress, you can show a loading indicator or do something else here
        return (
            
            <div className="grid grid-cols-1 gap-4 place-content-center text-center">
                <div className='flex flex-col justify-center items-center'>
                    {/* <i className="ri-home-7-fill text-orange-600 text-5xl"></i> */}
                    <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-orange-500"></div>
                </div>
            </div>
        );
    }

    return isValidToken ? children : <Navigate to="/login" />;

};

export default PrivateRoute;
