import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import LoginPage from './pages/LoginPage';
import SignupPage from './pages/SignupPage';
import Dashboard from './pages/Dashboard';
import PortfolioOTP from './pages/PortfolioOTP';
import PortfolioResale from './pages/PortfolioResale';
import PortfolioOtpDetail from './pages/PortfolioOtpDetail';
import PortfolioResaleDetail from './pages/PortfolioResaleDetail';
import WorkflowDetail from './pages/WorkflowDetail';
import Commission from './pages/Commission';
import CommissionResale from './pages/CommissionResale';
import User from './pages/User';
import Profile from './pages/Profile';
import UpdatePassword from './pages/UpdatePassword';
import PortfolioOtpEdit from './pages/PortfolioOtpEdit';

import './App.css';
import PrivateRoute from './pages/PrivateRoute';
import 'remixicon/fonts/remixicon.css'
import CreatePortfolio from './pages/CreatePortfolio';
import PrintSalesAdvice from './pages/PrintSalesAdvice';
import WorkflowList from './pages/WorkflowList';
import WorkflowResaleList from './pages/WorkflowResaleList';
import WorkflowResaleDetail from './pages/WorkflowResaleDetail';
import SysConfig from './pages/SysConfig';
import WorkflowExport from './pages/WorkflowExport';
import ResetPassword from './pages/ResetPassword';

import('preline')



function App() {
  return (
    <Router>
      <main>
        {/* Use the Routes component to handle routing */}
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<SignupPage />} />

          <Route path="/reset-password" element={<ResetPassword />} />

          <Route
            path="/home"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />

          <Route
            path="/profile"
            element={
              <PrivateRoute>
                <Profile />
              </PrivateRoute>
            }
          />

          <Route
            path="/update-password"
            element={
              <PrivateRoute>
                <UpdatePassword />
              </PrivateRoute>
            }
          />

          <Route
            path="/user"
            element={
              <PrivateRoute>
                <User />
              </PrivateRoute>
            }
          />

          {/* <Route
            path="/user/:userId"
            element={
              <PrivateRoute>
                <User />
              </PrivateRoute>
            }
          /> */}

          <Route
            path="/portfolio/otp"
            element={
              <PrivateRoute>
                <PortfolioOTP />
              </PrivateRoute>
            }
          />

          <Route
            path="/portfolio/resale"
            element={
              <PrivateRoute>
                <PortfolioResale />
              </PrivateRoute>
            }
          />

          <Route
            path="/portfolio/create"
            element={
              <PrivateRoute>
                <CreatePortfolio />
              </PrivateRoute>
            }
          />

          <Route
            path="/portfolio/otp/edit/:propertyId"
            element={
              <PrivateRoute>
                <PortfolioOtpEdit />
              </PrivateRoute>
            }
          />

          <Route
            path="/portfolio/otp/detail/:propertyId"
            element={
              <PrivateRoute>
                <PortfolioOtpDetail />
              </PrivateRoute>
            }
          />

          <Route
            path="/portfolio/resale/detail/:propertyId"
            element={
              <PrivateRoute>
                <PortfolioResaleDetail />
              </PrivateRoute>
            }
          />

          <Route
            path="/workflow/otp"
            element={
              <PrivateRoute>
                <WorkflowList />
              </PrivateRoute>
            }
          />

          <Route
            path="/workflow/otp/new"
            element={
              <PrivateRoute>
                <WorkflowDetail />
              </PrivateRoute>
            }
          />

          <Route
            path="/workflow/otp/detail/:workflowId"
            element={
              <PrivateRoute>
                <WorkflowDetail />
              </PrivateRoute>
            }
          />

          <Route
            path="/workflow/resale"
            element={
              <PrivateRoute>
                <WorkflowResaleList />
              </PrivateRoute>
            }
          />

          <Route
            path="/workflow/resale/new"
            element={
              <PrivateRoute>
                <WorkflowResaleDetail />
              </PrivateRoute>
            }
          />

          <Route
            path="/workflow/resale/detail/:workflowId"
            element={
              <PrivateRoute>
                <WorkflowResaleDetail />
              </PrivateRoute>
            }
          />

          <Route
            path="/commission/otp"
            element={
              <PrivateRoute>
                <Commission />
              </PrivateRoute>
            }
          />


          <Route
            path="/commission/resale"
            element={
              <PrivateRoute>
                <CommissionResale />
              </PrivateRoute>
            }
          />


          <Route
            path="/print/sales-advice/:workflowId/:companyName"
            element={
              <PrivateRoute>
                <PrintSalesAdvice />
              </PrivateRoute>
            }
          />

          <Route
            path="/config"
            element={
              <PrivateRoute>
                <SysConfig />
              </PrivateRoute>
            }
          />

          <Route
            path="/report/workflow-export"
            element={
              <PrivateRoute>
                <WorkflowExport />
              </PrivateRoute>
            }
          />

          {/* all other routes, direct to login page */}
          <Route path="*" element={<LoginPage />} />

        </Routes>
      </main>
    </Router>
  );
}

export default App;
