import axios from "axios";

function formatPropertyJson(p) {
  return {
    id: p.id,
    imgSrc: p.coverImage,
    price: p.displayPrice,
    title: p.title,
    propertyType: p.propertyType,
    address: p.address,
    suburb: p.suburb,
    postcode: p.postcode,
    unpublished: p.status === 0,
  };
}

const fetchProperties = async () => {
  let properties = [];

  await axios
    .get(`${process.env.REACT_APP_API_URL}/api/property/otp/fetch-all-active`)
    .then((response) => {
      if (response.status === 200) {
        // console.log(response.data)
        response.data.forEach((p) => {
          properties.push(formatPropertyJson(p));
        });
      }
    })
    .catch((error) => {
      console.log(error);
    });

  return properties;
};

const searchProperties = async (searchData) => {
  let properties = [];

  await axios
    .get(`${process.env.REACT_APP_API_URL}/api/property/otp/search`, {
      params: searchData, // Pass the search data as query parameters
    })
    .then((response) => {
      if (response.status === 200) {
        // console.log(response.data)
        response.data.forEach((p) => {
          properties.push(formatPropertyJson(p));
        });
      }
    })
    .catch((error) => {
      console.log(error);
    });

  return properties;
};

export { fetchProperties, searchProperties };
