import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Select, Input } from "antd";
import axios from "axios";
import Button from "../components/Button";
import Toast from "../components/Toast";

export default function WorkflowExport() {

    const [formData, setFormData] = useState({
        propertyId: "",
        branchOffice: '',
        sellingAgent: '',
        exchangeDate: '',
        status: '',
        workflowType: "",
    })

    const [users, setUsers] = useState([]);
    const [propertyOptions, setPropertyOptions] = useState([]);

    useEffect(() => {
        document.title = "Workflow Data Export";

        const fetchUsers = () => {
            axios.get(`${process.env.REACT_APP_API_URL}/api/user/all-users`)
                .then(response => {
                    if (response.status === 200) {
                        //login success redirect to dashboard
                        if (response.data != null) {
                            // console.log(response.data)
                            const activeUsers = response.data.filter((user) => user.status === 1);
                            setUsers(activeUsers);
                        }
                    }
                })
                .catch(error => { });
        }

        fetchUsers();

        const fetchProperties = () => {
            axios.get(`${process.env.REACT_APP_API_URL}/api/property/otp/fetch-all-active`)
                .then(response => {
                    if (response.status === 200) {
                        // console.log(response.data)
                        response.data.forEach(p => {

                            setPropertyOptions((prevProperties) => [
                                ...prevProperties,
                                {
                                    value: p.id,
                                    label: p.title + " (" + p.address + ")",
                                }
                            ])
                        })
                    }
                }).catch(error => {
                    console.log(error)
                });
        }

        fetchProperties()
    }, [])


    const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const onChangeHandler = (e) => {
        const { id, value } = e.target;

        setFormData((prevData) => (
            {
                ...prevData,
                [id]: value,
            }
        ))
    }

    const [isExporting, setIsExporting] = useState(false);
    const exportData = () => {
        setIsExporting(true);
        // console.log(formData)

        axios.post(`${process.env.REACT_APP_API_URL}/api/report/workflow-data-export`, formData, {
            headers: {
                'Content-Type': 'application/json', // Specify JSON content type
            },
        })
            .then(response => {
                if (response.status === 200) {
                    // console.log(response.data)
                    const currentMilliseconds = new Date().getTime();
                    exportToCsv(response.data, `export-workflows-${currentMilliseconds}.csv`)
                    showToast("success", "Export successfully.");
                }
            }).catch(error => {
                console.log(error)
                showToast("error", "An error has occurred.");
            }).finally(() => {
                setIsExporting(false);
            });
    }


    const exportToCsv = (data, filename) => {
        const encodedUri = convertToCsv(data);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
    };


    function convertToCsv(data) {
        const csvContent = "data:text/csv;charset=utf-8," +
            data.map(row => row.map(field => {
                // If the field contains a comma, encapsulate it within double quotes
                if (field.includes(',')) {
                    return `"${field}"`;
                }
                return field;
            }).join(",")).join("\n");
        return encodeURI(csvContent);
    }

    const [isToastVisible, setIsToastVisible] = useState(false);
    const [toastType, setToastType] = useState("normal");
    const [toastMessage, setToastMessage] = useState("");

    const showToast = (type, message) => {
        setIsToastVisible(true);
        setToastType(type);
        setToastMessage(message);

        // Optionally, you can set a timeout to hide the toast after a certain duration
        setTimeout(() => {
            setIsToastVisible(false);
        }, 3000); // Hide the toast after 3 seconds (adjust as needed)
    };

    return <div>
        <Toast isVisible={isToastVisible} type={toastType} message={toastMessage} />

        <Header currentPage="report" />
        <div className="main-container">
            <div className="grid grid-cols-1 gap-4 p-5">

                <div className="static card w-fill bg-base-100 shadow-md">
                    <div className="card-body">
                        <div className="grid gap-10 grid-cols-1 md:grid-cols-3 lg:grid-cols-6">
                            <div>
                                <label className="font-medium" htmlFor="workflowType">Workflow Type</label>
                                <Select
                                    id="workflowType"
                                    showSearch
                                    optionFilterProp="children"
                                    onChange={(value) => {
                                        setFormData({ ...formData, workflowType: value })
                                    }}
                                    filterOption={filterOption}
                                    options={[
                                        {
                                            value: "OTP",
                                            label: "OTP",
                                        },
                                        {
                                            value: "Resale",
                                            label: "Resale",
                                        },
                                    ]}
                                    className="w-full"
                                />
                            </div>

                            <div>
                                <label className="font-medium" htmlFor="branchOffice">Branch Office</label>
                                <Select
                                    id="branchOffice"
                                    showSearch
                                    optionFilterProp="children"
                                    onChange={(value) => {
                                        setFormData({ ...formData, branchOffice: value })
                                    }}
                                    filterOption={filterOption}
                                    options={[
                                        {
                                            value: "PW Lindfield",
                                            label: "PW Lindfield",
                                        },
                                        {
                                            value: "PW Norwest",
                                            label: "PW Norwest",
                                        },
                                        {
                                            value: "PW Sydney",
                                            label: "PW Sydney",
                                        },
                                    ]}
                                    className="w-full"
                                />
                            </div>

                            <div className="mb-4">
                                <label className="font-medium" htmlFor="sellingAgent">Selling Agent</label>
                                <br />
                                <Select
                                    id="sellingAgent"
                                    className="w-full"
                                    showSearch
                                    placeholder=""
                                    optionFilterProp="children"
                                    onChange={(value) => {
                                        setFormData({ ...formData, sellingAgent: value })
                                    }}
                                    filterOption={filterOption}
                                    options={
                                        users.map(user => (
                                            { value: user.id, label: user.firstName + " " + user.lastName + " (" + user.emailAddress + ")" }
                                        ))
                                    }
                                />
                            </div>

                            <div>
                                <label className="font-medium" htmlFor="propertyId">Project Name</label>
                                <Select
                                    id="propertyId"
                                    showSearch
                                    optionFilterProp="children"
                                    onChange={(value) => {
                                        setFormData({ ...formData, propertyId: value })
                                    }}
                                    filterOption={filterOption}
                                    options={propertyOptions}
                                    className="w-full"
                                />
                            </div>

                            <div>
                                <label className="font-medium" htmlFor="exchangeDate">Exchange Date</label>
                                <Input id="exchangeDate" type="date" value={formData.vendor} onChange={onChangeHandler} />
                            </div>

                            <div>
                                <label className="font-medium" htmlFor="status">Status</label>
                                <Select
                                    id="status"
                                    showSearch
                                    optionFilterProp="children"
                                    onChange={(value) => {
                                        setFormData({ ...formData, status: value })
                                    }}
                                    filterOption={filterOption}
                                    options={[
                                        {
                                            value: "Draft",
                                            label: "Draft",
                                        },
                                        {
                                            value: "Contract Processing",
                                            label: "Contract Processing",
                                        },
                                        {
                                            value: "Approval Required",
                                            label: "Approval Required",
                                        },
                                        {
                                            value: "Partner Approved",
                                            label: "Partner Approved",
                                        },
                                        {
                                            value: "Completed",
                                            label: "Completed",
                                        },
                                        {
                                            value: "Canceled",
                                            label: "Canceled",
                                        },
                                    ]}
                                    className="w-full"
                                />
                            </div>
                        </div>

                        <div className="grid gap-10 grid-cols-1 mt-4">
                            <div>
                                <Button buttonText={"Export"} onClick={exportData} disabled={isExporting}>
                                    <i className="ri-download-2-line mr-2"></i>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <Footer />

    </div>
}