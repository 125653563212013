import React, { useEffect, useState } from "react";
import Button from "../Button";
import * as Util from "../../functions/common/util";
import axios from "axios";
import Toast from "../Toast";
import { getPresignedURL, downloadFile } from "../../functions/common/file-upload"
import { isLoginUserAdmin } from "../../functions/common/manage-login-user";
import { Checkbox } from 'antd';



export default function FinancialDetails({ workflow, setChangesDetected, partnerReviewed }) {

    // console.log(workflow)

    const isAdmin = isLoginUserAdmin();

    const [localWorkflow, setLocalWorkflow] = useState(workflow);

    const [rebateAgreementFile, setRebateAgreementFile] = useState([]);
    const [filesUploaded, setFilesUploaded] = useState([])

    const [referralAgreementFile, setReferralAgreementFile] = useState([]);
    const [referralFilesUploaded, setReferralFilesUploaded] = useState([])

    const [workflowApproved, setWorkflowApproved] = useState(workflow.approvedBy > 0);

    useEffect(() => {
        if(partnerReviewed) {
            setWorkflowApproved(true);
        }

        // console.log("reloaded financial module")
    }, [partnerReviewed]);

    useEffect(() => {

        // console.log(workflow)

        const fetchWorkflowDocs = (id) => {
            if (!id) {
                return;
            }

            axios.get(`${process.env.REACT_APP_API_URL}/api/workflow/otp/fetch-docs`,
                {
                    params: {
                        workflowId: id,
                        fileType: "rebate agreement",
                    }, // Pass the search data as query parameters
                }).then(response => {
                    if (response.status === 200) {
                        const files = response.data;
                        files.forEach((file) => {
                            setFilesUploaded(prevData => [
                                ...prevData,
                                {
                                    name: file.filename,
                                    s3Key: file.s3Key
                                }
                            ])
                        })
                    }
                }).catch(error => {
                    console.log(error)
                });


                axios.get(`${process.env.REACT_APP_API_URL}/api/workflow/otp/fetch-docs`,
                {
                    params: {
                        workflowId: id,
                        fileType: "referral agreement",
                    }, // Pass the search data as query parameters
                }).then(response => {
                    if (response.status === 200) {
                        const files = response.data;
                        files.forEach((file) => {
                            setReferralFilesUploaded(prevData => [
                                ...prevData,
                                {
                                    name: file.filename,
                                    s3Key: file.s3Key
                                }
                            ])
                        })
                    }
                }).catch(error => {
                    console.log(error)
                });
        }

        fetchWorkflowDocs(workflow.id);

        // console.log("load financial details")

        setWorkflowApproved(workflow.approvedBy > 0);

    }, [workflow])

    const handleFileUpload = (e) => {
        const { files } = e.target;

        // console.log(files);

        setRebateAgreementFile(files);
    }


    const handleReferralAgreementUpload = (e) => {
        const { files } = e.target;

        // console.log(files);

        setReferralAgreementFile(files);
    }


    const onChangeHandler = (e) => {
        const { id, value } = e.target;

        // console.log(id, value);
        if(id === "customerRebateAmount") {
            if(value === "" || value === "0") {
                setShowRebateUpload(false);
            } else {
                setShowRebateUpload(true);
            }
        } else if (id === "referrerCommAmount") {
            if(value === "" || value === "0") {
                setShowReferralAgreementUpload(false);
            } else {
                setShowReferralAgreementUpload(true);
            }
        }
 
        setLocalWorkflow(prevData => (
            {
                ...prevData,
                [id]: value,
            }
        ))

        setChangesDetected(true);
    }

    const [showRebateUpload, setShowRebateUpload] = useState(workflow.customerRebateAmount > 0);
    const [showReferralAgreementUpload, setShowReferralAgreementUpload] = useState(workflow.referrerCommAmount > 0);
    
    const [referrerCommAmountChecked, setReferrerCommAmountChecked] = useState(workflow.referrerCommInclGst);
    const [customerRebateVendorChecked, setCustomerRebateVendorChecked] = useState(workflow.vendorRebateInclGst);
    const [customerRebateAmountChecked, setCustomerRebateAmountChecked] = useState(workflow.customerRebateInclGst);

    const submit = () => {
        // console.log(localWorkflow);

        /*
        If the customer rebate is more than 0, then the user must upload the rebate agreement
        */
        if (showRebateUpload) {
            if (rebateAgreementFile.length === 0 && filesUploaded.length === 0) {
                showToast("error", "Rebate agreement required.");
                return;
            }
        }

        if (showReferralAgreementUpload) {
            if (referralAgreementFile.length === 0 && referralFilesUploaded.length === 0){
                showToast("error", "Referral agreement required.");
                return;
            }
        }

        //set this to hide the submit button, we allow users to submit multiple times,
        //but each time they can only submit once, to re-submit, they have to refresh the page
        //if the workflow is really approved, then only admin can submit any changes.
        setWorkflowApproved(true);  

        setChangesDetected(false);

        localWorkflow.rebateAgreementFiles = [];
        if (rebateAgreementFile.length > 0) {
            Array.from(rebateAgreementFile).forEach(file => {
                const timestamp = Date.now();
                const newFilename = `RebateAgreement_${timestamp}_${file.name}`;
                file.s3Key = `workflow/otp/${newFilename}`;

                if (!file.uploaded) {
                    //if already uploaded , do not upload again
                    uploadSingleFile(file).then(
                        response => {
                            if (response) {
                                setFilesUploaded((prevFiles) => [
                                    ...prevFiles,
                                    file
                                ])
                            }

                            file.uploaded = true;
                        }
                    );

                    localWorkflow.rebateAgreementFiles.push({
                        filename: file.name,
                        s3Key: file.s3Key
                    });
                }
            })
        }

        localWorkflow.referralAgreementFile = [];
        if (referralAgreementFile.length > 0) {
            Array.from(referralAgreementFile).forEach(file => {
                const timestamp = Date.now();
                const newFilename = `ReferralAgreement_${timestamp}_${file.name}`;
                file.s3Key = `workflow/otp/${newFilename}`;

                if (!file.uploaded) {
                    //if already uploaded , do not upload again
                    uploadSingleFile(file).then(
                        response => {
                            if (response) {
                                setReferralFilesUploaded((prevFiles) => [
                                    ...prevFiles,
                                    file
                                ])
                            }

                            file.uploaded = true;
                        }
                    );

                    localWorkflow.referralAgreementFile.push({
                        filename: file.name,
                        s3Key: file.s3Key
                    });
                }
            })
        }

        localWorkflow.referrerCommInclGst = referrerCommAmountChecked
        localWorkflow.vendorRebateInclGst = customerRebateVendorChecked
        localWorkflow.customerRebateInclGst = customerRebateAmountChecked

        // console.log(localWorkflow);

        axios.post(`${process.env.REACT_APP_API_URL}/api/workflow/otp/submit-financial-details`, localWorkflow, {
            headers: {
                'Content-Type': 'application/json', // Specify JSON content type
            },
        })
            .then(response => {
                console.log(response)
                if (response.status === 200) {
                    showToast("success", "Saved.");
                }
            }).catch(error => {
                console.log(error)
            });

    }

    const uploadSingleFile = async (file) => {
        const presignedUrl = await getPresignedURL(file, false, false);

        const xhr = new XMLHttpRequest();
        const success = await new Promise((resolve) => {
            xhr.upload.addEventListener("progress", (event) => {
                if (event.lengthComputable) {
                    // $("#uploading-file-name").html(file.name);
                    // $("#upload-progress").html(((event.loaded / event.total) * 100).toFixed(0) + "%");
                    console.log("Uploading " + file.name + ", progress: " + ((event.loaded / event.total) * 100).toFixed(0) + "%")
                }
            });
            xhr.addEventListener("progress", (event) => {
                if (event.lengthComputable) {
                    // console.log("download progress:", event.loaded / event.total);
                    // $("#download-progress").html(((event.loaded / event.total) * 100).toFixed(0) + "%");
                }
            });
            xhr.addEventListener("loadend", () => {
                resolve(xhr.readyState === 4 && xhr.status === 200);
            });
            xhr.open("PUT", presignedUrl, true);
            // xhr.setRequestHeader("Content-Type", "multipart/form-data");
            xhr.setRequestHeader("Content-Type", file.type);
            // xhr.setRequestHeader("Content-Disposition", "inline; filename=\""+file.name+"\"");
            xhr.send(file);
        });

        return success;
    }

    const [isToastVisible, setIsToastVisible] = useState(false);
    const [toastType, setToastType] = useState("normal");
    const [toastMessage, setToastMessage] = useState("");

    const showToast = (type, message) => {
        setIsToastVisible(true);
        setToastType(type);
        setToastMessage(message);

        // Optionally, you can set a timeout to hide the toast after a certain duration
        setTimeout(() => {
            setIsToastVisible(false);
        }, 2000); // Hide the toast after 3 seconds (adjust as needed)
    };

    const setReferrerCommCheck = () => {
        setReferrerCommAmountChecked(!referrerCommAmountChecked);
    }

    const setCustomerRebateVendorCheck = () => {
        setCustomerRebateVendorChecked(!customerRebateVendorChecked);
    }

    const setCustomerRebateAmountCheck = () => {
        setCustomerRebateAmountChecked(!customerRebateAmountChecked);
    }

    return <>

        <Toast isVisible={isToastVisible} type={toastType} message={toastMessage} />

        <div className="grid gap-4 grid-cols-1 mb-4">
            {/* Link to download a file */}
            <div>
                <a
                    href="/Customer Rebate Agreement.pdf" 
                    download="Customer Rebate Agreement.pdf" 
                    className="link-pw hover:underline cursor-pointer"
                >
                    <i className="ri-download-2-line text-lg text-orange-500 mr-2"></i>
                    Customer Rebate Agreement - Template
                </a>
            </div>

            <div>
                <a
                    href="/Referral Agreement for Property Selling.pdf"
                    download="Referral Agreement for Property Selling.pdf" 
                    className="link-pw hover:underline cursor-pointer"
                >
                    <i className="ri-download-2-line text-lg text-orange-500 mr-2"></i>
                    Referral Agreement for Property Selling - Template
                </a>
            </div>
        </div>
        
        <div className="grid gap-4 grid-cols-1 md:grid-cols-3">
            <div className="mb-4">
                <label className="font-medium" htmlFor="referrerName">Buyer Referrer </label>
                <input type="text" id="referrerName" value={localWorkflow.referrerName} onChange={onChangeHandler} placeholder="" className="mt-2 py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500" />
            </div>
            <div className="mb-4">
                <label className="font-medium" htmlFor="referrerCommAmount">Referrer Commission Amount &nbsp;&nbsp;&nbsp; <Checkbox defaultChecked={referrerCommAmountChecked} onChange={setReferrerCommCheck}  id="referrerCommAmount-check">Incl. GST</Checkbox></label>
                <input type="text" id="referrerCommAmount" value={Util.formatPrice(localWorkflow.referrerCommAmount)} onChange={onChangeHandler} placeholder="" className="mt-2 py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500" />
            </div>
        </div>
        {workflow?.status === 1 && showReferralAgreementUpload && (
            <div className="grid gap-4 grid-cols-1">
                <div>
                    <label className="font-medium">Referral Agreement Form</label>
                    <label htmlFor="referral-file-input" className="sr-only">Choose file</label>
                    <input type="file" name="referral-file-input" id="referral-file-input" className="block w-full border border-gray-200 shadow-sm rounded-md text-sm focus:z-10 focus:border-orange-500 focus:ring-orange-500
                                        file:bg-transparent file:border-0
                                        file:bg-gray-100 file:mr-4
                                        file:py-3 file:px-4
                                        "
                        onChange={handleReferralAgreementUpload} />
                </div>
                {referralFilesUploaded.length > 0 && (
                    <div className="mb-4">
                        <ul className="space-y-3 text-sm">
                            {referralFilesUploaded.map((thisfile, ndx) => (
                                <li key={"file-" + ndx} onClick={() => { downloadFile(thisfile) }} className="flex space-x-3 items-center cursor-pointer">
                                    <i className="ri-attachment-2 text-lg text-orange-500"></i>
                                    <span className="text-gray-800 link-pw">
                                        {thisfile.name}
                                    </span>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
        )}

        <div className="grid gap-4 grid-cols-1 md:grid-cols-3 mt-5">
            <div className="mb-4">
                <label className="font-medium" htmlFor="customerRebateVendor">Customer Rebate (Vendor) &nbsp;&nbsp;&nbsp; <Checkbox defaultChecked={customerRebateVendorChecked} id="customerRebateVendor-check" onChange={setCustomerRebateVendorCheck}>Incl. GST</Checkbox></label>
                <input type="text" id="customerRebateVendor" value={Util.formatPrice(localWorkflow.customerRebateVendor)} onChange={onChangeHandler} placeholder="" className="mt-2 py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500" />
            </div>
            <div className="mb-4">
                <label className="font-medium" htmlFor="customerRebateAmount">Customer Rebate (PW) &nbsp;&nbsp;&nbsp; <Checkbox defaultChecked={customerRebateAmountChecked} id="customerRebateAmount-check" onChange={setCustomerRebateAmountCheck}>Incl. GST</Checkbox></label>
                <input type="text" id="customerRebateAmount" value={Util.formatPrice(localWorkflow.customerRebateAmount)} onChange={onChangeHandler} placeholder="" className="mt-2 py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500" />
            </div>
        </div>
        {/* <div className="grid gap-4 grid-cols-1 md:grid-cols-3">
            <div className="mb-4">
                <label className="font-medium" htmlFor="referrerAccName">Account Name </label>
                <input type="text" id="referrerAccName" value={localWorkflow.referrerAccName} onChange={onChangeHandler} placeholder="" className="mt-2 py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500" />
            </div>
            <div className="mb-4">
                <label className="font-medium" htmlFor="referrerAccNumber">Account Number </label>
                <input type="text" id="referrerAccNumber" value={localWorkflow.referrerAccNumber} onChange={onChangeHandler} placeholder="" className="mt-2 py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500" />
            </div>
            <div className="mb-4">
                <label className="font-medium" htmlFor="referrerAccBsb">BSB </label>
                <input type="text" id="referrerAccBsb" value={localWorkflow.referrerAccBsb} onChange={onChangeHandler} placeholder="" className="mt-2 py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500" />
            </div>
        </div> */}

        {workflow?.status === 1 && showRebateUpload && (
            <div className="grid gap-4 grid-cols-1">
                <div>
                    <label className="font-medium">Customer Rebate Agreement Form</label>
                    <label htmlFor="file-input" className="sr-only">Choose file</label>
                    <input type="file" name="file-input" id="file-input" className="block w-full border border-gray-200 shadow-sm rounded-md text-sm focus:z-10 focus:border-orange-500 focus:ring-orange-500
                                        file:bg-transparent file:border-0
                                        file:bg-gray-100 file:mr-4
                                        file:py-3 file:px-4
                                        "
                        onChange={handleFileUpload} />
                </div>
                {filesUploaded.length > 0 && (
                    <div className="mb-4">
                        <ul className="space-y-3 text-sm">
                            {filesUploaded.map((thisfile, ndx) => (
                                <li key={"file-" + ndx} onClick={() => { downloadFile(thisfile) }} className="flex space-x-3 items-center cursor-pointer">
                                    <i className="ri-attachment-2 text-lg text-orange-500"></i>
                                    <span className="text-gray-800 link-pw">
                                        {thisfile.name}
                                    </span>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
        )}

        {workflow?.status === 1 && (
            <div className="space-x-5 mt-5 mb-5">
                {(!workflowApproved || isAdmin) && (
                    <Button buttonText={"Submit"} onClick={submit} />
                )}
            </div>
        )}

    </>
}