import React, { useState, useRef, useEffect } from 'react';
import { ConfigProvider, Checkbox } from 'antd';


const PropertyTypeFilter = ({ propertyTypes, selectPropertyType }) => {
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);

    const handleOptionChange = (propertyType) => {
        // Check if the property type is already selected
        if (selectedOptions.includes(propertyType)) {
            // If selected, remove it from the list
            const filteredOptions = selectedOptions.filter((option) => option !== propertyType)
            setSelectedOptions(filteredOptions);
            selectPropertyType(filteredOptions);
        } else {
            // If not selected, add it to the list
            setSelectedOptions([...selectedOptions, propertyType]);
            selectPropertyType([...selectedOptions, propertyType]);
        }        
    };

    // useEffect(() => {
    //     selectPropertyType(selectedOptions);
    // }, [selectedOptions]);

    // Close the dropdown when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
            }
        };

        if (isDropdownOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isDropdownOpen]);

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    return (
        <div ref={dropdownRef} className="relative">
            <div onClick={toggleDropdown} className="cursor-pointer">
                <div className="block mt-2 p-2 text-gray-700 rounded-lg border border-gray-200 bg-white w-full overflow-hidden">
                    {selectedOptions.length > 0
                        ? selectedOptions.map((option) => (
                            <span key={option} className="mr-2">
                                {option}
                            </span>
                        ))
                        : (
                            <div>
                                <div className='text-gray-400'>
                                    Select property type...
                                </div>
                            </div>
                        )}
                </div>
                {selectedOptions.length > 0 && (
                    <p className="text-sm text-gray-400 ml-1">{selectedOptions.length} type{selectedOptions.length>1?"s":""} selected</p>
                )}
            </div>
            {isDropdownOpen && (
                <div className="z-[99] origin-top-right absolute left-0 mt-2 w-56 p-5 rounded-lg shadow-lg bg-white border border-gray-300 divide-y divide-gray-100">
                {propertyTypes.map( propertyType => {
                    return (
                        <div key={propertyType} className="">
                            <label className='flex align-center cursor-pointer py-2 px-2 hover:bg-gray-200'>
                            <ConfigProvider
                                theme={{
                                    components: {
                                        Checkbox: {
                                            colorPrimary: '#f15b22',
                                            colorPrimaryBorder: '#f15b22',
                                            colorPrimaryHover: '#f15b22',
                                            fontSize: 16,
                                        }
                                    },
                                }}
                            >
                                <Checkbox checked={selectedOptions.includes(propertyType)} onChange={() => handleOptionChange(propertyType)} >
                                {propertyType}
                                </Checkbox>
                            </ConfigProvider>
                                {/* <input type="checkbox" checked={selectedOptions.includes(propertyType)} onChange={() => handleOptionChange(propertyType)} />
                                <span className="pw-checkbox mr-4">
                                    <span className="checkmark"></span>
                                </span> */}
                                {/* {propertyType} */}
                            </label>
                        </div>
                    )
                })}
                
            </div>
            )}
        </div>
    );
};

export default PropertyTypeFilter;
