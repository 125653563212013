function validateForm(formId) {
    const form = document.getElementById(formId);
    const mandatoryFields = form.querySelectorAll('.mandatory');

    // console.log("formId " + formId)

    let valid = true;
    for (const field of mandatoryFields) {
        if (!field.value.trim()) {
            // If a mandatory field is empty, return false
            field.classList.add('invalid-input');
            
            valid = false;
        } else {
            field.classList.remove('invalid-input');
        }
    }

    // All mandatory fields are filled in
    return valid;
}

export {validateForm};