import React, { useEffect, useState, useRef } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import { Space, Table, Input, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import CommissionAllocationResale from "../components/commission/CommissionAllocationResale";
import axios from "axios";

const CommissionResale = () => {

    const [data, setData] = useState([]);

    useEffect(() => {
        document.title = "Commission"

        const fetchCommissions = () => {
            axios.get(`${process.env.REACT_APP_API_URL}/api/commission/resale/fetch-all`)
                .then(response => {
                    if (response.status === 200) {
                        const commissions = response.data;

                        console.log(commissions)

                        commissions.forEach((commission, ndx) => {
                            // console.log(new Date(workflow.lastUpdateTimestamp))
                            setData((prevData) => ([
                                ...prevData,
                                {
                                    key: ndx,
                                    commissionData: commission,
                                    id: `#${commission.id}`,
                                    workflowId: commission.workflowId,
                                    lastUpdateDate: new Date(commission.lastUpdateTimestamp === null ? commission.insertTimestamp : commission.lastUpdateTimestamp),
                                    propertyAddress: commission.workflowResale.property.address+" "+commission.workflowResale.property.suburb+" "+commission.workflowResale.property.state,
                                    sellingAgent: commission.workflowResale.sellingAgentName,
                                    listingAgent: commission.workflowResale.listingAgentName,
                                }
                            ]))
                        })

                    }
                }).catch(error => {
                    console.log(error)
                });
        }

        fetchCommissions();

    }, [])

    
    const expandedRowRender = (record) => {
        return (
            <CommissionAllocationResale commissionResale={record.commissionData} />
        )    
    }
    

    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
    };
    const handleReset = (clearFilters, confirm) => {
        clearFilters();
        handleSearch([''], confirm, "")
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters, confirm)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        className="text-gray-500"
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) => (text)
    });

    const columns = [
        {
            title: ' ',
            dataIndex: 'workflowId',
            key: 'workflowId',
        },
        {
            title: 'Update Date',
            dataIndex: 'lastUpdateDate',
            key: 'lastUpdateDate',
            render: (date) => {
                const year = date.getFullYear();
                const month = date.getMonth() + 1; // Months are zero-based, so add 1
                const day = date.getDate();
                const hours = date.getHours();
                const minutes = date.getMinutes();
                // const seconds = date.getSeconds();

                const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
                const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;

                return (<div>{formattedDate} {formattedTime}</div>)
            }
        },
        {
            title: 'Project / Property',
            dataIndex: 'propertyAddress',
            key: 'propertyAddress',
            ...getColumnSearchProps('propertyAddress'),
            render:  (_, record) => {
                return (
                <div>
                    <Link className="link-pw" to={`/workflow/resale/detail/${record.workflowId}`}>{record.propertyAddress}</Link>
                </div>
            )},
            
        },
        {
            title: 'Selling Agent',
            dataIndex: 'sellingAgent',
            key: 'sellingAgent',
            ...getColumnSearchProps('sellingAgent'),
        },
        {
            title: 'Listing Agent',
            dataIndex: 'listingAgent',
            key: 'listingAgent',
            ...getColumnSearchProps('listingAgent'),
        },
    ];


    return (
        <>
            <div>
                <Header currentPage="commission" />

                <div className="main-container">
                    <div className="grid grid-cols-1 gap-4 p-5">
                        <div className="static card w-fill bg-base-100 shadow-md">
                            <div className="card-body">

                                <Table
                                    columns={columns}
                                    expandable={{
                                        expandedRowRender
                                    }}
                                    dataSource={data}
                                    pagination={{
                                        defaultPageSize: 10,
                                        position: ['bottomCenter'],
                                    }}
                                    scroll={{ x: true }}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />

            </div>
        </>
    );
};

export default CommissionResale;