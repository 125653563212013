import React, { useState } from "react";
import Button from "../Button";
import { searchProperty } from "../../functions/portfolio/resale";

export default function PropertySearchFilter2( {updatePropertyResult} ) {

    const [isSubmitting, setIsSubmitting] = useState(false);

    const [searchData, setSearchData] = useState({
        address: "",
    });

    const searchProjects = async () => {
        setIsSubmitting(true);

        // console.log(searchData)

        await searchProperty(searchData).then(fetchedData => {
            updatePropertyResult(fetchedData);
        }).catch(error => {
            console.log(error);
        })
            
        setIsSubmitting(false);
    }

    const handleOnChange = (e) => {
        const {id, value} = e.target;
        setSearchData((prevData) => (
            {
                ...prevData,
                [id]: value,
            }
        ))
    }

    return (
        <div className="static card w-fill bg-base-100 shadow-md">
            <div className="card-body">
                <div className="grid gap-10 grid-cols-1 md:grid-cols-2 lg:grid-cols-2">

                    <div>
                        <label className="font-medium" htmlFor="address">Address</label>
                        <input type="text" id="address" placeholder="Property address" className="mt-2 py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500" onChange={handleOnChange} />
                    </div>

                </div>

                <div className="grid gap-10 grid-cols-1 mt-4">
                    <div className="flex justify-between">
                        <Button buttonText={"Search"} onClick={searchProjects} disabled={isSubmitting}>
                            < i className="ri-search-line mr-2"></i>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}