import React, { useEffect, useState, useRef } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import { Space, Table, Input, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import CommissionAllocation from "../components/commission/CommissionAllocation";
import axios from "axios";
import { fetchUserLevelAndRate } from "../functions/common/manage-login-user";

const Commission = () => {

    const [data, setData] = useState([]);

    useEffect(() => {
        document.title = "Commission"

        const fetchCommissions = async () => {

            const userRates = await fetchUserLevelAndRate();

            axios.get(`${process.env.REACT_APP_API_URL}/api/commission/otp/fetch-all`)
                .then(response => {
                    if (response.status === 200) {
                        const commissions = response.data;

                        // console.log(commissions)

                        commissions.forEach((commission, ndx) => {
                            // console.log(new Date(workflow.lastUpdateTimestamp))

                            if(userRates.otpCommissionLevel === "L1" || userRates.otpCommissionLevel === "L2") {
                                commission.showCommRate = false;
                            } else {
                                commission.showCommRate = true;
                            }

                            setData((prevData) => ([
                                ...prevData,
                                {
                                    key: ndx,
                                    commissionOtp: commission,
                                    id: `#${commission.id}`,
                                    workflowId: commission.workflowId,
                                    lastUpdateDate: new Date(commission.lastUpdateTimestamp === null ? commission.insertTimestamp : commission.lastUpdateTimestamp),
                                    propertySearch: commission.workflowOtp.propertyOtp.title+""+commission.workflowOtp.propertyOtp.address,
                                    propertyTitle: commission.workflowOtp.propertyOtp.title,
                                    propertyAddress: commission.workflowOtp.propertyOtp.address+" "+commission.workflowOtp.propertyOtp.suburb+" "+commission.workflowOtp.propertyOtp.state,
                                    lotNumber: (commission.workflowOtp.lotNumber !== "" ? "Lot " + commission.workflowOtp.lotNumber + " " : "") + (commission.workflowOtp.unitNumber !== "" ? "Unit " + commission.workflowOtp.unitNumber : ""),
                                    sellingAgent: commission.workflowOtp.agentDetail.firstName
                                    + " " + commission.workflowOtp.agentDetail.middleName
                                    + " " + commission.workflowOtp.agentDetail.lastName
                                    + " " + commission.workflowOtp.agentDetail.entityName,
                                    sellingAgentFirstName: commission.workflowOtp.agentDetail.firstName,
                                    sellingAgentMiddleName: commission.workflowOtp.agentDetail.middleName,
                                    sellingAgentLastName: commission.workflowOtp.agentDetail.lastName,
                                    sellingAgentEntityName: commission.workflowOtp.agentDetail.entityName,
                                    purchaser: <div>
                                        <p>{commission.workflowOtp.purchaserName}</p>
                                        {commission.workflowOtp.purchaser2Name ? (<p></p>) : (<></>)}
                                    </div>,
                                    purchaserSearch: commission.workflowOtp.purchaserName + " " + commission.workflowOtp.purchaser2Name,
                                }
                            ]))
                        })

                    }
                }).catch(error => {
                    console.log(error)
                });
        }

        fetchCommissions();

    }, [])

    
    const expandedRowRender = (record) => {
        return (
            <CommissionAllocation commissionOtp={record.commissionOtp} />
        )    
    }
    

    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
    };
    const handleReset = (clearFilters, confirm) => {
        clearFilters();
        handleSearch([''], confirm, "")
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters, confirm)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        className="text-gray-500"
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) => (text)
    });

    const columns = [
        {
            title: ' ',
            dataIndex: 'workflowId',
            key: 'workflowId',
        },
        {
            title: 'Update Date',
            dataIndex: 'lastUpdateDate',
            key: 'lastUpdateDate',
            render: (date) => {
                const year = date.getFullYear();
                const month = date.getMonth() + 1; // Months are zero-based, so add 1
                const day = date.getDate();
                const hours = date.getHours();
                const minutes = date.getMinutes();
                // const seconds = date.getSeconds();

                const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
                const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;

                return (<div>{formattedDate} {formattedTime}</div>)
            }
        },
        {
            title: 'Project / Property',
            dataIndex: 'propertyTitle',
            key: 'propertyTitle',
            ...getColumnSearchProps('propertySearch'),
            render:  (_, record) => {
                return (
                <div>
                    <Link className="link-pw" to={`/workflow/otp/detail/${record.workflowId}`}>{record.propertyTitle}</Link>
                    <p className="text-gray-400">{record.propertyAddress}</p>
                </div>
            )},
            
        },
        {
            title: 'Lot / Unit',
            dataIndex: 'lotNumber',
            key: 'lotNumber',
            ...getColumnSearchProps('lotNumber'),
        },
        {
            title: 'Purchaser',
            dataIndex: 'purchaser',
            key: 'purchaser',
            ...getColumnSearchProps('purchaserSearch'),
        },
        {
            title: 'Selling Agent',
            dataIndex: 'sellingAgent',
            key: 'sellingAgent',
            ...getColumnSearchProps('sellingAgent'),
            render: (_, record) => {
                let name = '';
                if(record.sellingAgentFirstName === "" && record.sellingAgentMiddleName === "" && record.sellingAgentLastName === "") {
                    name = record.sellingAgentEntityName;
                } else {
                    name = record.sellingAgentFirstName + (record.sellingAgentMiddleName === "" ? "" : (" " + record.sellingAgentMiddleName) ) + " " + record.sellingAgentLastName;
                }
                return name;
            }
        },
    ];


    return (
        <>
            <div>
                <Header currentPage="commission" />

                <div className="main-container">
                    <div className="grid grid-cols-1 gap-4 p-5">
                        <div className="static card w-fill bg-base-100 shadow-md">
                            <div className="card-body">

                                <Table
                                    columns={columns}
                                    expandable={{
                                        expandedRowRender
                                    }}
                                    dataSource={data}
                                    pagination={{
                                        defaultPageSize: 10,
                                        position: ['bottomCenter'],
                                    }}
                                    scroll={{ x: true }}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />

            </div>
        </>
    );
};

export default Commission;