import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { validateForm } from "../functions/form/form-validation"
import css from './loginpage.module.css';
import { Link } from "react-router-dom";

export default function LoginPage() {

    useEffect(() => {
        // Set the title for this page
        document.title = 'Log in - PW Realty';
    }, []);

    const [errorMessage, setErrorMessage] = useState("");


    const [formData, setFormData] = useState({
        emailAddress: "",
        password: ""
    });

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData({
            ...formData,
            [id]: value,
        });

    };


    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleOnSubmit = async (e) => {
        e.preventDefault(); // Prevent the default form submission

        let valid = validateForm("log-in-form", formData);
        // valid = true;

        if (!valid) {
            return;
        }

        setIsSubmitting(true);

        await login(formData);

        setIsSubmitting(false);

        //submit login form
        return;
    }

    const navigate = useNavigate();

    const apiUrl = process.env.REACT_APP_API_URL; //'http://localhost:8080';

    // console.log("apiUrl: " + apiUrl)

    const login = async (data) => {
        axios.post(`${apiUrl}/api/user/login`, data, {
            headers: {
                'Content-Type': 'application/json', // Specify JSON content type
            },
        }).then(response => {
            if (response.status === 200) {
                //login success redirect to dashboard
                if (response.data != null) {
                    localStorage.setItem('token', response.data.token);
                    localStorage.setItem('userId', response.data.userId);
                    localStorage.setItem('userName', response.data.userName);
                    localStorage.setItem('userNameInit', response.data.userNameInit);
                    localStorage.setItem('role', response.data.role);

                    // console.log("init localStorage... token = " + response.data.token);
                }

                navigate("/home"); // Programmatically navigate to a new route
            }
        }).catch(error => {
            setErrorMessage("Incorrect login details.");
        });


    };

    return (
        <section className={`${css["md:h-screen"]} ${css["py-36"]} flex items-center relative overflow-hidden ${css["zoom-image"]}`} >
            <div
                style={{ backgroundImage: "url('/images/bg/01.jpg')" }}
                className={`${css.absolute} ${css["inset-0"]} ${css["image-wrap"]} ${css["z-1"]} ${css["bg-no-repeat"]} ${css["bg-center"]} ${css["bg-cover"]}`} >
            </div>
            <div className={`${css.absolute} ${css["inset-0"]} ${css["bg-gradient-to-b"]} ${css["from-transparent"]} ${css["to-black"]} ${css["z-2"]} `}></div>

            <div className={`${css.container} ${css["z-3"]}`} >
                <div className="flex justify-center">
                    <div className="max-w-[400px] w-full m-auto p-6 bg-white rounded-md">
                        <img src="/images/logo-dark.png" className="mx-auto" alt="" width={64} height={64} />
                        <h5 className="my-6 text-xl font-semibold text-center">Login</h5>
                        <form id="log-in-form" className="text-left" onSubmit={handleOnSubmit}>
                            <div className="grid grid-cols-1">
                                <div className="mb-4">
                                    <label className="font-medium ltr:text-left" htmlFor="emailAddress">Email Address:</label>
                                    <input id="emailAddress" type="email" className={`${css["form-input"]} ${css["mt-3"]} mandatory`} placeholder="Your Email" onChange={handleChange} />
                                </div>

                                <div className="mb-4">
                                    <label className="font-medium" htmlFor="password">Password:</label>
                                    <input id="password" type="password" className={`${css["form-input"]} ${css["mt-3"]} mandatory`} placeholder="Password:" onChange={handleChange} />
                                </div>

                                <div className="mb-4">
                                    <button type="submit" className={`${css.btn} btn-pw ${css["text-white"]} ${css["rounded-md"]} ${css["w-full"]}`} disabled={isSubmitting}>Login / Sign in</button>
                                </div>

                                <div className="grid grid-cols-1 text-center text-error">
                                    <div className="mb-4">
                                        <p id="error-message">{errorMessage}</p>
                                    </div>
                                </div>

                                <div className="text-center">
                                    <span className="text-slate-400 me-2">Forgot password ?</span> <Link to="/reset-password" className="link-pw font-bold">Reset</Link>
                                </div>

                                <div className="text-center mt-2">
                                    <span className="text-slate-400 me-2">Don't have an account ?</span> <Link to="/signup" className="link-pw font-bold">Sign Up</Link>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );

}
