import React, { useState } from "react";
import Button from "../Button";
import PropertyTypeFilter from "./PropertyTypeFilter";
import { Switch, ConfigProvider } from 'antd';
import { isLoginUserAdmin } from "../../functions/common/manage-login-user";
import { searchProperties } from "../../functions/portfolio/otp";


export default function PropertySearchFilter({ updatePropertyResult }) {

    const [isSubmitting, setIsSubmitting] = useState(false);

    const isAdmin = isLoginUserAdmin();

    const [searchData, setSearchData] = useState({
        title: "",
        address: "",
        minPrice: 0,
        maxPrice: 0,
        propertyTypes: "",
        inclUnPublished: false,
    });


    const searchProjects = async () => {
        setIsSubmitting(true);

        // console.log(searchData)

        const fetchedData = await searchProperties(searchData);

        updatePropertyResult(fetchedData);
            
        setIsSubmitting(false);
        
    }


    const priceRange = [
        50000, 100000, 150000, 200000, 250000, 300000, 350000, 400000, 450000, 500000, 550000, 600000, 650000, 700000, 750000, 800000, 850000, 900000, 950000, 1000000, 1100000, 1200000, 1300000, 1400000, 1500000, 1600000, 1700000, 1800000, 1900000, 2000000, 2250000, 2500000, 2750000, 3000000
    ];
    
    const selectPropertyType = (selectedTypes) => {
        setSearchData((prevData) => 
            (
                {
                    ...prevData,
                    propertyTypes: selectedTypes.join(","),
                }
            )
        )
    }

    const onChangeHandler = (e) => {
        const {id, value} = e.target;        

        setSearchData( (prevData) => (
            {
                ...prevData,
                [id]: value,
            }
        ))
    }

    const handleInclUnpublished = (e) => {
        setSearchData( (prevData) => (
            {
                ...prevData,
                inclUnPublished: e,
            }
        ))
    }


    return (
        <div className="static card w-fill bg-base-100 shadow-md">
            <div className="card-body">
                <div className="grid gap-10 grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
                    <div>
                        <label className="font-medium" htmlFor="title">Project</label>
                        <input type="text" id="title" onChange={onChangeHandler} placeholder="Project Name" className="mt-2 py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500" />
                    </div>

                    <div>
                        <label className="font-medium" htmlFor="address">Location</label>
                        <input type="text" id="address" onChange={onChangeHandler} placeholder="Project Location" className="mt-2 py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500" />
                    </div>

                    <div>
                        <label className="font-medium">Price Range</label>
                        <div className="grid gap-4 grid-cols-1 md:grid-cols-2">
                            <div>
                                <select id="minPrice" onChange={onChangeHandler} className='mt-2 py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500'  >
                                    <option value="">Any</option>
                                    {priceRange.map((optionValue, index) => {
                                        return (
                                            <option key={index} value={optionValue}>
                                                {optionValue.toLocaleString('en-AU', {
                                                    style: 'currency',
                                                    currency: 'AUD',
                                                    minimumFractionDigits: 0, // Set minimum decimal places to 0
                                                    maximumFractionDigits: 2, // Set maximum decimal places to 0

                                                })}
                                            </option>
                                        );
                                    })}
                                </select>
                                <p className="text-sm text-gray-400 ml-1">Min Price</p>
                            </div>
                            <div>
                                <select id="maxPrice" onChange={onChangeHandler} className='mt-2 py-2 px-3 w-full border border-solid border-gray-200 rounded-md text-sm focus:border-orange-500 focus-visible:border-orange-500'  >
                                    <option value="">Any</option>
                                    {priceRange.map((optionValue, index) => {
                                        return (
                                            <option key={index} value={optionValue}>
                                                {optionValue.toLocaleString('en-AU', {
                                                    style: 'currency',
                                                    currency: 'AUD',
                                                    minimumFractionDigits: 0, // Set minimum decimal places to 0
                                                    maximumFractionDigits: 2, // Set maximum decimal places to 0

                                                })}
                                            </option>
                                        );
                                    })}
                                </select>
                                <p className="text-sm text-gray-400 ml-1">Max Price</p>
                            </div>
                        </div>

                    </div>

                    <div>
                        <label className="font-medium">Property Type</label>
                        <PropertyTypeFilter selectPropertyType={selectPropertyType} propertyTypes={['House', 'Apartment', 'Townhouse', 'Rural', 'Commercial', 'Duplex']} />
                    </div>
                </div>

                {isAdmin && (
                    <div className="grid gap-10 grid-cols-1">
                        <div>
                            <ConfigProvider
                                theme={{
                                    components: {
                                        Switch: {
                                            /* here is your component tokens */
                                            colorPrimary: "#f15b22",
                                            colorPrimaryHover: "#f15b22",
                                        },
                                    },
                                }}
                                >
                                <Switch checkedChildren="Incl. Unpublish" unCheckedChildren="Excl. Unpublish"  className="bg-gray-400" onChange={handleInclUnpublished}/>
                            </ConfigProvider>
                        </div>
                    </div>
                )}
                

                <div className="grid gap-10 grid-cols-1 mt-4">
                    <div>
                        <Button buttonText={"Search"} onClick={searchProjects} disabled={isSubmitting}>
                            < i className="ri-search-line mr-2"></i>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}