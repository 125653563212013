import React, { useState } from "react";
import { Space, Card, Table, Tag, Popover, Modal, InputNumber, Checkbox } from "antd";
import { QuestionCircleOutlined, EditOutlined } from '@ant-design/icons';
import { formatPrice } from "../../functions/common/util";
import { isLoginUserAdmin, isLoginUserManager } from "../../functions/common/manage-login-user";
import Button from "../Button";
import axios from "axios";
import Toast from "../Toast";
import Decimal from 'decimal.js';


export default function CommissionAllocation(props) {

    const [commissionOtp, setCommissionOtp] = useState(props.commissionOtp);
    const [commissionAllocationOtps, setCommissionAllocationOtps] = useState(props.commissionOtp.commissionAllocationOtps);
    

    const columns = [
        {
            title: 'Contract Price',
            dataIndex: 'contractPrice',
            key: 'contractPrice',
        },
        {
            title: 'Comm. Rate',
            dataIndex: 'commissionRate',
            key: 'commissionRate',
        },
        {
            title: 'Comm. Amount',
            dataIndex: 'commissionCash',
            key: 'commissionCash',
        },
        {
            title: 'Agent Bonus',
            dataIndex: 'commissionBonus',
            key: 'commissionBonus',
        },
        {
            title: 'Rebate (Vendor)',
            dataIndex: 'customerRebateVendor',
            key: 'customerRebateVendor',
        },
        {
            title: 'Customer Rebate (PW)',
            dataIndex: 'customerRebateAgent',
            key: 'customerRebateAgent',
        },
        {
            title: 'Referral Fee',
            dataIndex: 'referralFee',
            key: 'referralFee',
        },
    ]

    if (isLoginUserAdmin()) {
        columns.push(
            {
                title: 'Action',
                key: 'action',
                render: (_, record) => {
                    if (isLoginUserAdmin()) {
                        return <div className="link-pw cursor-pointer" onClick={() => { setIsEditModalOpen(true) }}>
                            <EditOutlined /> Edit
                        </div>
                    } else {
                        return ""
                    }
                }
            },
        )
    }

    //todo - display the rate and cash amount differently for sales (consider cap)
    let gstRate = new Decimal(1.1);
    let customerRebateVendorStr = '-';
    let customerRebateVendor = new Decimal(commissionOtp.customerRebateVendor);
    if(customerRebateVendor > 0) {
        if (commissionOtp.workflowOtp.vendorRebateInclGst) {
            customerRebateVendorStr = "$" + formatPrice(customerRebateVendor) + " (incl. GST)"
        } else {
            let customerRebateVendorExclGst = customerRebateVendor.dividedBy(gstRate).toDecimalPlaces(2)
            customerRebateVendorStr = "$" + formatPrice(customerRebateVendorExclGst) + " (excl. GST)"
        }
    }

    let customerRebateStr = '-'
    let customerRebateAgent = new Decimal(commissionOtp.customerRebateAgent);
    if(customerRebateAgent > 0) {
        if (commissionOtp.workflowOtp.customerRebateInclGst) {
            customerRebateStr = "$" + formatPrice(customerRebateAgent) + " (incl. GST)"
        } else {
            let customerRebateExclGst = customerRebateAgent.dividedBy(gstRate).toDecimalPlaces(2)
            customerRebateStr = "$" + formatPrice(customerRebateExclGst) + " (excl. GST)"
        }
    }

    let referralCommStr = '-'
    let referralFee = new Decimal(commissionOtp.referralFee);
    if(referralFee > 0 ) {
        if (commissionOtp.workflowOtp.referrerCommInclGst) {
            referralCommStr = "$" + formatPrice(referralFee) + " (incl. GST)"
        } else {
            let referralFeeExclGst = referralFee.dividedBy(gstRate).toDecimalPlaces(2)
            referralCommStr = "$" + formatPrice(referralFeeExclGst) + " (excl. GST)"
        }
    }
    
    const data = [
        {
            key: '1',
            contractPrice: `$${formatPrice(commissionOtp.contractPrice)}`,
            commissionRate: commissionOtp.showCommRate ? (commissionOtp.commissionRate>0 ? `${commissionOtp.commissionRate}% (incl. GST)` : `-`) : `-`,
            commissionCash: commissionOtp.showCommRate ? (commissionOtp.commissionAmount>0 ? `$${formatPrice(commissionOtp.commissionAmount)} (incl. GST)` : `-`) : `-`,
            commissionBonus: commissionOtp.bonus>0 ? `$${formatPrice(commissionOtp.bonus)} (incl. GST)` : `-`,
            customerRebateVendor: commissionOtp.customerRebateVendor>0? customerRebateVendorStr : `-`,// `$${formatPrice(commissionOtp.customerRebateVendor)}`,
            customerRebateAgent: commissionOtp.customerRebateAgent>0? customerRebateStr : `-`, //`$${formatPrice(commissionOtp.customerRebateAgent)}`,
            referralFee: commissionOtp.referralFee>0 ? referralCommStr : `-`, // `$${formatPrice(commissionOtp.referralFee)}`,
        },
    ];

    const [updateField, setUpdateField] = useState({
        id: commissionOtp.id,
        contractPrice: commissionOtp.contractPrice,
        commissionRate: commissionOtp.commissionRate,
        commissionAmount: commissionOtp.commissionAmount,
        commissionCapRate: commissionOtp.commissionCapRate,
        commissionCapAmount: commissionOtp.commissionCapAmount,
        bonus: commissionOtp.bonus,
        customerRebateAgent: commissionOtp.customerRebateAgent,
        customerRebateVendor: commissionOtp.customerRebateVendor,
        referralFee: commissionOtp.referralFee,
        totalCommissionInclGst: commissionOtp.totalCommissionInclGst,
        netCommissionInclGst: commissionOtp.netCommissionInclGst,
        commissionAllocationOtps: {},
    });


    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const handleCancel = () => {
        setIsEditModalOpen(false);
    }

    const [currentTimeMillis, setCurrentTimeMillis] = useState(new Date().getTime());
    const handleUpdateCommission = () => {
        updateField["sendEmail"] = resendEmail;

        // console.log(updateField);
        setUpdating(true);

        axios.post(`${process.env.REACT_APP_API_URL}/api/commission/otp/update`, updateField, {
            headers: {
                'Content-Type': 'application/json', // Specify JSON content type
            },
        })
            .then(response => {
                const data = response.data;
                // console.log(data);
                if (data.validationPass) {
                    //great job!
                    showToast("success", "Update success!");

                    // console.log(data.commissionOtp);
                    setCurrentTimeMillis(new Date().getTime());
                    // Update the state with the dynamic update object
                    setCommissionOtp(data.commissionOtp);
                    setCommissionAllocationOtps(data.commissionOtp.commissionAllocationOtps);

                    setIsEditModalOpen(false);
                } else {
                    //failed, pls double check setting
                    showToast("error", `${data.message}`);
                }

            }).catch(error => {
                console.log(error)
            }).finally(() => {
                setUpdating(false);

            });
    }


    const [isToastVisible, setIsToastVisible] = useState(false);
    const [toastType, setToastType] = useState("normal");
    const [toastMessage, setToastMessage] = useState("");

    const showToast = (type, message) => {
        setIsToastVisible(true);
        setToastType(type);
        setToastMessage(message);

        // Optionally, you can set a timeout to hide the toast after a certain duration
        setTimeout(() => {
            setIsToastVisible(false);
        }, 3000); // Hide the toast after 3 seconds (adjust as needed)
    };

    const [updating, setUpdating] = useState(false);

    const [resendEmail, setResendEmail] = useState(false);
    const handleResendEmailCheck = (e) => {
        setResendEmail(e.target.checked)
    }


    let columns_allocation = [
        {
            title: "Name",
            dataIndex: "labelName",
            key: "labelName",
        },
        {
            title: "Role",
            dataIndex: "name",
            key: "name",
            render: (_, record) => {
                if (record.role === "Company") {
                    return record.branchOffice;
                }

                return record.userRole
            }
        }
    ];

    if ( commissionOtp.workflowOtp.insertBy !== parseInt(localStorage.getItem("userId")) ) {
        //hide "rate" for sales
        columns_allocation.push(
            {
                title: "Rate",
                dataIndex: "level",
                key: "level",
            },
        )
    }
        
    columns_allocation = [
        ...columns_allocation,
        {
            title: "GST",
            dataIndex: "gst",
            key: "gst",
        },
        {
            title: "Amount (Excl. GST)",
            dataIndex: "amountExclGst",
            key: "amountExclGst",
        },
        {
            title: "Amount (Incl. GST)",
            dataIndex: "amount",
            key: "amount",
        },
    ];

    let data_allocation = [];
    let firstEle = true;

    commissionAllocationOtps.forEach((comm, ndx) => {
        if (!comm.viewable) {
            return "";
        }

        let thisCommData = {
            key: `comm-al-${ndx}`,
            firstName: comm.user?.firstName,
            middleName: comm.user?.middleName,
            lastName: comm.user?.lastName,
            entityName: comm.user?.entityName,
            role: comm.role,
            userRole: comm.user?.role,
            branchOffice: commissionOtp.workflowOtp.agentDetail.branchOffice,
            level: `${comm.rate}%`,
            gst: <Tag color={(comm.user === null || comm.user.gstStatus) ? "green" : "red"}>{(comm.user === null || comm.user.gstStatus) ? "Yes" : "No"}</Tag>,
            amountExclGst: <>
                {(comm.user === null || comm.user.gstStatus) ? (
                    <div>&mdash;</div>
                ) : (
                    (isLoginUserManager() || isLoginUserAdmin()) ? (
                        // show calculation
                        //if the commission setting is changed
                        //all parties commission will be showing "edited"
                        //if setting not changed, only changed some party, then only those changed party will display 'Edited'
                        //because once setting is changed, the formula will not make any sense (eg. if i change commission rate)
                        <Popover content={commissionOtp.lastUpdateBy === 0 && comm.lastUpdateBy === 0 ? ("("+ comm.formula + ") ÷ 1.1" ) : <Tag color="red">Edited</Tag>} title="Calculation:">
                            <Space>
                                ${formatPrice(comm.commissionExclGst)}
                                <QuestionCircleOutlined className="cursor-help" />
                            </Space>
                        </Popover>
                    ) : (
                        <Space>
                            ${formatPrice(comm.commissionExclGst)}
                        </Space>
                    )
                )}
            </>,
            amount: <>
                {(comm.user === null || comm.user.gstStatus) ? (
                    (isLoginUserManager() || isLoginUserAdmin()) ? (
                        // show calculation
                        //if the commission setting is changed
                        //all parties commission will be showing "edited"
                        //if setting not changed, only changed some party, then only those changed party will display 'Edited'
                        //because once setting is changed, the formula will not make any sense (eg. if i change commission rate)
                        <Popover content={commissionOtp.lastUpdateBy === 0 && comm.lastUpdateBy === 0 ? comm.formula : <Tag color="red">Edited</Tag>} title="Calculation:">
                            <Space>
                                ${formatPrice(comm.commissionInclGst)}
                                <QuestionCircleOutlined className="cursor-help" />
                            </Space>
                        </Popover>
                    ) : (
                        <Space>
                            ${formatPrice(comm.commissionInclGst)}
                        </Space>
                    )
                ) : (
                    <div>&mdash;</div>
                )}
                
            </>,
        }

        comm.paymentByStage.forEach((payment, s_ndx) => {
            if (firstEle) {
                columns_allocation.push({
                    title: `Stage ${s_ndx + 1}`,
                    dataIndex: `stage${s_ndx + 1}`,
                    key: `stage${s_ndx + 1}`,
                });
            }

            let calculatedPayment = new Decimal(payment);
            if (comm.user !== null && !comm.user.gstStatus ) {
                //if the user doesn't have GST
                calculatedPayment = calculatedPayment.dividedBy(gstRate).toDecimalPlaces(2)
            }
            thisCommData["stage" + (s_ndx + 1)] = `$${formatPrice(calculatedPayment)}`
        })
        firstEle = false; 

        let labelName = '';
        if (comm.role === "Company") {
            labelName = "PW Retainment";
        } else {
            if (comm.user.firstName === "" && comm.user.middleName === "" && comm.user.lastName === "") {
                labelName = comm.user.entityName;
            } else {
                labelName = comm.user.firstName + (comm.user.middleName === "" ? "" : (" " + comm.user.middleName)) + " " + comm.user.lastName;
            }
        }
        thisCommData["labelName"] = labelName;

        data_allocation.push(thisCommData);
    });

    return (
        <div>
            <Toast isVisible={isToastVisible} type={toastType} message={toastMessage} />

            <Modal title="Update Commission (Incl. GST)"
                width={800}
                open={isEditModalOpen}
                onCancel={handleCancel}
                footer={() => (
                    <>
                        <Checkbox className="text-md" checked={resendEmail} onChange={handleResendEmailCheck} ><Tag color="red">Email to Accountant</Tag></Checkbox>
                        <Button onClick={handleUpdateCommission} buttonText={"Confirm"} disabled={updating} />
                    </>
                )}>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    <div>
                        <label>Contract Price:</label>
                        <InputNumber
                            disabled={true}
                            id="contractPrice"
                            className="w-full"
                            value={commissionOtp.contractPrice}
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        />
                    </div>

                    <div>
                        <label>Commission Rate (%):</label>
                        <InputNumber
                            id="commissionRate"
                            className="w-full"
                            value={updateField.commissionRate}
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                            onChange={(val) => {
                                setUpdateField({
                                    ...updateField,
                                    commissionRate: val,
                                })
                            }}
                            min={0}
                            max={100}
                        />
                    </div>

                    <div>
                        <label>Commission Amount:</label>
                        <InputNumber
                            id="commissionAmount"
                            className="w-full"
                            value={updateField.commissionAmount}
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                            onChange={(val) => {
                                setUpdateField({
                                    ...updateField,
                                    commissionAmount: val,
                                })
                            }}
                            min={0}
                        />
                    </div>

                    <div>
                        <label>Cap Rate (%):</label>
                        <InputNumber
                            id="commissionCapRate"
                            className="w-full"
                            value={updateField.commissionCapRate}
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                            onChange={(val) => {
                                setUpdateField({
                                    ...updateField,
                                    commissionCapRate: val,
                                })
                            }}
                        />
                    </div>

                    <div>
                        <label>Cap Amount ($):</label>
                        <InputNumber
                            id="commissionCapAmount"
                            className="w-full"
                            value={updateField.commissionCapAmount}
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                            onChange={(val) => {
                                setUpdateField({
                                    ...updateField,
                                    commissionCapAmount: val,
                                })
                            }}
                        />
                    </div>

                    <div>
                        <label>Agent Bonus ($):</label>
                        <InputNumber
                            id="bonus"
                            className="w-full"
                            value={updateField.bonus}
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                            onChange={(val) => {
                                setUpdateField({
                                    ...updateField,
                                    bonus: val,
                                })
                            }}
                        />
                    </div>

                    <div>
                        <label>Customer Rebate (Vendor):</label>
                        <InputNumber
                            id="customerRebateVendor"
                            className="w-full"
                            value={updateField.customerRebateVendor}
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                            onChange={(val) => {
                                setUpdateField({
                                    ...updateField,
                                    customerRebateVendor: val,
                                })
                            }}
                        />
                    </div>

                    <div>
                        <label>Customer Rebate (Agent):</label>
                        <InputNumber
                            id="customerRebateAgent"
                            className="w-full"
                            value={updateField.customerRebateAgent}
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                            onChange={(val) => {
                                setUpdateField({
                                    ...updateField,
                                    customerRebateAgent: val,
                                })
                            }}
                        />
                    </div>

                    <div>
                        <label>Referral Fee ($):</label>
                        <InputNumber
                            id="referralFee"
                            className="w-full"
                            value={updateField.referralFee}
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                            onChange={(val) => {
                                setUpdateField({
                                    ...updateField,
                                    referralFee: val,
                                })
                            }}
                        />
                    </div>

                    <div>
                        <label>Total Comm ($):</label>
                        <InputNumber
                            id="totalCommissionInclGst"
                            className="w-full"
                            value={updateField.totalCommissionInclGst}
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                            onChange={(val) => {
                                setUpdateField({
                                    ...updateField,
                                    totalCommissionInclGst: val,
                                })
                            }}
                        />
                    </div>

                    <div className="mb-4">
                        <label>Net Comm ($):</label>
                        <InputNumber
                            id="netCommissionInclGst"
                            className="w-full"
                            value={updateField.netCommissionInclGst}
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                            onChange={(val) => {
                                setUpdateField({
                                    ...updateField,
                                    netCommissionInclGst: val,
                                })
                            }}
                        />
                    </div>
                </div>

                {commissionAllocationOtps.map((comm, ndx) => {
                    // const filteredKey = Object.keys(commissionAllocationOtps).filter(key => commissionAllocationOtps[key] === comm.id);
                    const inputVal = updateField.commissionAllocationOtps[comm.id] ? updateField.commissionAllocationOtps[comm.id] : comm.commissionInclGst;
                    return (
                        <div key={`${comm.id}-${currentTimeMillis}`} className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-2">
                            <label className="font-semibold">{comm.user == null ? "PW Retainment" : (`${comm.user.firstName} ${comm.user.lastName} ${comm.user.entityName}`)}</label>
                            <InputNumber
                                id={comm.id}
                                className="w-full"
                                value={inputVal}
                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                onChange={(val) => {
                                    setUpdateField({
                                        ...updateField,
                                        commissionAllocationOtps: {
                                            ...updateField.commissionAllocationOtps,
                                            [comm.id]: val + "",
                                        },
                                    })
                                }}
                            />
                        </div>
                    )
                })}

            </Modal>

            <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                <Card className="w-full">
                    <Table
                        columns={columns}
                        dataSource={data}
                        scroll={{ x: true }}
                        pagination={false}
                    />
                    {/* <p className="mt-2">* All figures above are incl. GST</p> */}
                </Card>

                {(isLoginUserManager() || isLoginUserAdmin()) && (
                    <Card className="w-full">
                        <Table
                            columns={[
                                {
                                    title: 'Total Comm. (Excl. Gst)',
                                    dataIndex: 'totalCommExclGst',
                                    key: 'totalCommExclGst',
                                },
                                {
                                    title: 'Total Comm. (Incl. Gst)',
                                    dataIndex: 'totalCommInclGst',
                                    key: 'totalCommInclGst',
                                },
                                {
                                    title: 'Net Comm. (Excl. GST)',
                                    dataIndex: 'netCommExclGst',
                                    key: 'netCommExclGst',
                                },
                                {
                                    title: 'Net Comm. (Incl. GST)',
                                    dataIndex: 'netCommInclGst',
                                    key: 'netCommInclGst',
                                },
                            ]}
                            dataSource={[
                                {
                                    key: 'comm',
                                    totalCommExclGst: `$${formatPrice(commissionOtp.totalCommissionExclGst)}`,
                                    totalCommInclGst: <>
                                        {isLoginUserManager() || isLoginUserAdmin() ? (
                                            // show calculation
                                            <Popover content={`${formatPrice(commissionOtp.contractPrice)} x ${formatPrice(commissionOtp.commissionRate)}% + ${formatPrice(commissionOtp.commissionAmount)} - ${formatPrice(commissionOtp.customerRebateVendor)}`} title="Calculation:">
                                                <Space>
                                                    ${formatPrice(commissionOtp.totalCommissionInclGst)}
                                                    <QuestionCircleOutlined className="cursor-help" />
                                                </Space>
                                            </Popover>
                                        ) : (
                                            <Space>
                                                ${formatPrice(commissionOtp.totalCommissionInclGst)}
                                            </Space>
                                        )}
                                    </>,
                                    netCommExclGst: `$${formatPrice(commissionOtp.netCommissionExclGst)}`,
                                    netCommInclGst: <>
                                        {isLoginUserManager() || isLoginUserAdmin() ? (
                                            // show calculation
                                            <Popover content={`${formatPrice(commissionOtp.contractPrice)} x ${commissionOtp.commissionRate}% + ${formatPrice(commissionOtp.commissionAmount)} - ${formatPrice(commissionOtp.customerRebateVendor)} - ${formatPrice(commissionOtp.customerRebateAgent)} - ${formatPrice(commissionOtp.referralFee)}`} title="Calculation:">
                                                <Space>
                                                    ${formatPrice(commissionOtp.netCommissionInclGst)}
                                                    <QuestionCircleOutlined className="cursor-help" />
                                                </Space>
                                            </Popover>
                                        ) : (
                                            <Space>
                                                ${formatPrice(commissionOtp.netCommissionInclGst)}
                                            </Space>
                                        )}
                                    </>,
                                }
                            ]}
                            scroll={{ x: true }}
                            pagination={false}
                        />
                    </Card>
                )}

                <Card className="w-full">
                    <Table
                        columns={columns_allocation}
                        dataSource={data_allocation}
                        scroll={{ x: true }}
                        pagination={false}
                    />
                </Card>


            </Space>
        </div>
    )
}